import React from 'react';
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Pager from '../../Common/Pager/Pager';

import ButtonBar from '../../Common/ButtonBar/ButtonBar';
import { API_HEADERS } from '../../../Constants';

class VulnerabilityBrowser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: 'high',
            highExists: true,
            medExists: true,
            lowExists: true
        }

        this.renderVulnerabilityItems = this.renderVulnerabilityItems.bind(this);
        this.onButtonBarClick = this.onButtonBarClick.bind(this);

        this.buttonBarRef = React.createRef();
    }

    async componentDidMount() {
        this.loadLow();
        this.loadMed();
        this.loadHigh();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.baseHref !== this.props.baseHref) {
            this.setState({
                selected: 'high',
                highExists: true,
                medExists: true,
                lowExists: true
            })
            this.loadLow();
            this.loadMed();
            this.loadHigh();
            this.buttonBarRef.current.setState({ selectedValue: 'high' });
        }
    }

    async loadLow() {
        fetch(`${this.props.baseHref}?risk=low&per_page=1`, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                lowExists: json.items.length !== 0
            });
        });
    }

    async loadMed() {
        fetch(`${this.props.baseHref}?risk=medium&per_page=1`, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                medExists: json.items.length !== 0
            });
        });
    }

    async loadHigh() {
        fetch(`${this.props.baseHref}?risk=high&per_page=1`, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                highExists: json.items.length !== 0
            });
        });
    }

    renderVulnerabilityItems(items) {
        const listItems = [];
        items.forEach(item => {
            const sourceHref = item._meta.links
                .find((l) => l.rel === 'source')
                .href;
            listItems.push(
                <div key={item.name} className='vulnerability-row'>
                    <a href={sourceHref} target='_blank' rel='noreferrer'>
                        {item.name}
                        <FontAwesomeIcon icon='arrow-up-right-from-square' />
                    </a>
                </div>
            )}
        );
        return listItems;
    }

    onButtonBarClick(value) {
        this.setState({
            selected: value
        })
    }

    render() {
        const {t} = this.props;

        let defaultSelection = 'high';
        if (!this.state.highExists) {
            defaultSelection = 'medium';
            if (!this.state.medExists) {
                defaultSelection = 'low';
                if (!this.state.lowExists) {
                    defaultSelection = 'none';
                }
            }
        }
        return (
            <div className='vulnerability-browser'>
                <div className='risk-category-filter'>
                    <ButtonBar ref={this.buttonBarRef} options={[
                            {label: t('results.inspector.vulns-high'), icon: 'fire', value: 'high', disabled: !this.state.highExists},
                            {label: t('results.inspector.vulns-med'), icon: 'circle-exclamation', value: 'medium', disabled: !this.state.medExists},
                            {label: t('results.inspector.vulns-low'), icon: 'circle-dot', value: 'low', disabled: !this.state.lowExists}
                        ]} onButtonBarClick={this.onButtonBarClick} defaultValue={defaultSelection} />
                </div>
                <Pager className='vulnerability-list'
                    initialPage={`${this.props.baseHref}?risk=${this.state.selected}&per_page=5`}
                    renderItems={this.renderVulnerabilityItems}
                    minimalPaginator={true}/>
            </div>
        )
    }

}

export default withTranslation()(VulnerabilityBrowser);
