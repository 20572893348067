import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';

import Dropdown from '../Common/Dropdown/Dropdown';
import Announcement from './Announcement';

import { API_HEADERS } from '../../Constants';
import './TopNavigation.css'

class AnnouncementsDropdown extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false,
            unread: false,
            announcements: null,
            pollId: null
        }
        this.loadAnnouncements = this.loadAnnouncements.bind(this);
        this.setDropdownState = this.setDropdownState.bind(this);
        this.renderAnnouncementsItems = this.renderAnnouncementsItems.bind(this);
    }

    componentDidMount() {
        this.loadAnnouncements();
        const pollId = setInterval(this.loadAnnouncements, 60000);
        this.setState({
            pollId
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.pollId);
    }

    async loadAnnouncements() {
        fetch(this.props.announcementsHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            if (json !== this.state.announcements) {
                // Only update state if announcements have changed
                this.setState({
                    announcements: json
                });
                let newUnreads = false;
                // See if we have any unread messages
                json.items.forEach(item => {
                    if (localStorage.getItem(item._meta.href) === null) {
                        // We have a new announcement that we haven't read yet
                        newUnreads = true;
                        if (item.priority === 'CRITICAL') {
                            // If any criticals, open the dropdown automatically
                            this.setDropdownState(true);
                        }
                    }
                });
                if (newUnreads) {
                    this.setState({
                        unread: true
                    })
                }
            }
        });
    }

    async setDropdownState(open) {
        if (open) {
            // re-load announcements when the user manually opens the dropdown
            await this.loadAnnouncements();
            // mark all messages as read since the dropdown has been opened
            this.state.announcements.items.forEach(item => {
                localStorage.setItem(item._meta.href, 'read');
            });
            this.setState({
                unread: false
            });
        }
        this.setState({
            open
        });
    }

    renderAnnouncementsItems(items) {
        const listItems = [];
        items.forEach(item => {
            listItems.push(<Announcement key={item._meta.href}
                    priority={item.priority}
                    message={item.message}
                    timestamp={item.dateCreated} />)
            }
        );
        return listItems;
    }

    render() {
        let count = null;
        let items = [];
        if (this.state.announcements) {
            count = this.state.announcements.position.totalElements;
            items = this.renderAnnouncementsItems(this.state.announcements.items);
        }

        return(
            count ?
                <div className={`top-nav-link announcements-link ${this.state.open ? 'open' : ''} ${this.state.unread ? 'unread' : ''}`} onClick={() => this.setDropdownState(!this.state.open)}>
                    <div className='top-nav-link-icon'>
                        <FontAwesomeIcon icon='bullhorn'/>
                    </div>
                    <div className='announcements-count'>{count}</div>
                    <Dropdown className='announcements-dropdown' open={this.state.open} handleOutsideClick={() => this.setDropdownState(false)}>
                        {items}
                    </Dropdown>
                </div>
            : null
        );
    }

}

export default withCookies(withTranslation()(AnnouncementsDropdown));
