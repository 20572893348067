import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import StdButton from '../../../Common/StdButton/StdButton';

import './CustomerAccounts.css'

class LinkedOwnerListItem extends React.Component {

    render() {
        const {t} = this.props;
        
        return (
            <div className='linked-owner'>
               <div className='owner-name'>{this.props.name}</div>
                {this.props.canUpdate ?
                    <div className='owner-buttons'>
                        <StdButton colorClass='danger' onClick={this.props.deleteOwner}>
                            <FontAwesomeIcon icon='link-slash' />
                            {t('admin.customer-accounts.update-modal.owner-unlink')}
                        </StdButton> 
                    </div>
                : null}
            </div>
        );
    }

}

export default withTranslation()(LinkedOwnerListItem);
