import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProjectListItem from './ProjectListItem';
import BulkAutoSetupModal from './BulkAutoSetupModal';

import Dropdown from '../../Common/Dropdown/Dropdown';
import ButtonBar from '../../Common/ButtonBar/ButtonBar';
import Pager from '../../Common/Pager/Pager';

import '../Results.css'

class ProjectGroup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            filterOrigin: 'all',
            filterDropdownOpen: false,
            settingsDropdownOpen: false,
            bulkAutoSetupModalOpen: false
        }

        this.setSettingsDropdownState = this.setSettingsDropdownState.bind(this);
        this.setFilterDropdownState = this.setFilterDropdownState.bind(this);
        this.setBulkSetupModalState = this.setBulkSetupModalState.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.onOriginFilterClick = this.onOriginFilterClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderProjectItems = this.renderProjectItems.bind(this);

        this.pager = React.createRef();
    }

    async setSettingsDropdownState(open) {
        this.setState({
            settingsDropdownOpen: open
        });
    }

    async setFilterDropdownState(open) {
        this.setState({
            filterDropdownOpen: open
        });
    }

    setBulkSetupModalState(open) {
        this.setState({
            bulkAutoSetupModalOpen: open
        });
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    clearSearch() {
        this.setState({
            searchTerm: ''
        });
    }

    clearFilters() {
        this.setState({
            filterOrigin: 'all',
            searchTerm: ''
        })
    }

    onOriginFilterClick(value) {
        this.setState({
            filterOrigin: value
        })
    }

    renderProjectItems(items) {
        const listItems = [];
        items.forEach(item => {
            const selected = this.props.selectedProject &&
                item._meta.href.split('/').slice(-2).join('/') === this.props.selectedProject.split('/').slice(-2).join('/');
            listItems.push(
                <ProjectListItem
                    key={item._meta.href}
                    name={item.name}
                    fullName={item.fullName}
                    fork={item.fork}
                    description={item.description}
                    href={item._meta.href}
                    searchTerm={this.state.searchTerm}
                    selected={selected}
                    onProjectClick={() => this.props.onProjectClick(item._meta.href)}
                    lastScan={item.lastScan}
                />
            )}
        );
        return listItems;
    }

    render() {
        const {t} = this.props;

        const caret = this.props.selected ? 'caret-down' : 'caret-right';

        const initialPage = `${this.props.projectsHref}?origin=${this.state.filterOrigin}${(this.state.searchTerm ? `&q=${encodeURI(this.state.searchTerm)}` : '')}`;

        // Add more OR conditions to this if we add more filters
        const filtersApplied = (this.state.filterOrigin !== 'all') || (this.state.searchTerm);
        let hiddenMessage = null;
        if (filtersApplied) {
            hiddenMessage = <div className='filter-hidden-message' onClick={this.clearFilters}>
                                {t('results.sidebar.filters.filters-hidden-message')}
                            </div>
        }

        let content = null;
        if (this.props.selected) {
            if (this.props.setupState.setupStage === 'ENABLED') {
                content =
                <div className='project-group-contents'>
                    <div className='project-group-filters'>
                        {
                        // Don't show the settings dropdown at all if there is no bulk setup link.
                        // In the future if there are multiple options in this dropdown we can move
                        // this conditional to the group-settings-row itself rather than the entire dropdown
                        }
                        {this.props.bulkSetupHref ?
                            <div className={`project-group-header-dropdown-link ${this.state.settingsDropdownOpen ? 'open' : ''}`} onClick={() => this.setSettingsDropdownState(!this.state.settingsDropdownOpen)}>
                                <FontAwesomeIcon icon='gear' />
                                <FontAwesomeIcon icon={this.state.settingsDropdownOpen ? 'caret-down' : 'caret-right'} />
                                <Dropdown className='project-group-header-dropdown settings' open={this.state.settingsDropdownOpen} handleOutsideClick={() => this.setSettingsDropdownState(false)}>
                                    <div className='group-settings-row' onClick={() => this.setBulkSetupModalState(true)}>
                                        <FontAwesomeIcon icon='wand-magic-sparkles' />
                                        <span>{t('results.sidebar.group-settings.auto-setup')}</span>
                                    </div>
                                </Dropdown>
                            </div>
                        : null}
                        <div className={`project-group-header-dropdown-link ${this.state.filterDropdownOpen ? 'open' : ''}`} onClick={() => this.setFilterDropdownState(!this.state.filterDropdownOpen)}>
                            <FontAwesomeIcon icon='filter' />
                            <FontAwesomeIcon icon={this.state.filterDropdownOpen ? 'caret-down' : 'caret-right'} />
                            <Dropdown className='project-group-header-dropdown' open={this.state.filterDropdownOpen} handleOutsideClick={() => this.setFilterDropdownState(false)}>
                                <div className='filter-row'>
                                    <span>{t('results.sidebar.filters.origin-label')}</span>
                                    <ButtonBar options={[
                                            {label: t('results.sidebar.filters.origin-all'), value: 'all'},
                                            {label: t('results.sidebar.filters.origin-sources'), value: 'sources'},
                                            {label: t('results.sidebar.filters.origin-forks'), value: 'forks'}
                                        ]} onButtonBarClick={this.onOriginFilterClick} defaultValue={this.state.filterOrigin} />
                                </div>
                            </Dropdown>
                        </div>
                        <div className='project-group-search'>
                            <FontAwesomeIcon icon='magnifying-glass' />
                            <input type='text' name='searchTerm' value={this.state.searchTerm} onChange={this.handleInputChange} />
                            {this.state.searchTerm ?
                                <div className='project-group-search-clear' onClick={this.clearSearch}>
                                    <FontAwesomeIcon icon='circle-xmark' />
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    {hiddenMessage}
                    <Pager ref={this.pager} className='project-group-projects'
                        initialPage={initialPage}
                        renderItems={this.renderProjectItems}
                        minimalPaginator={true}
                        handleError={this.props.notLoggedIn} />
                </div>
            } else if (this.props.setupState.setupStage  === 'MIGRATION_REQUIRED') {
                content =
                <div className='project-group-contents'>
                    <div className='group-fix'>
                        <div className='group-fix-message'>{t('results.sidebar.migration-needed')}</div>
                        <a href={this.props.setupState.configurationUrl} target='_blank' rel='noreferrer' className='std-button group-fix-link'>
                            <FontAwesomeIcon icon='screwdriver-wrench' /> {t('results.sidebar.migration-button')}
                        </a>
                    </div>
                </div>
            } else if (this.props.setupState.setupStage  === 'PRIVATE_PROJECTS_ONLY') {
                content =
                <div className='project-group-contents'>
                    <div className='group-fix'>
                        <div className='group-fix-message'>{t('results.sidebar.private-repos-only')}</div>
                        <a href={this.props.setupState.configurationUrl} target='_blank' rel='noreferrer' className='std-button group-fix-link'>
                            <FontAwesomeIcon icon='screwdriver-wrench' /> {t('results.sidebar.private-repos-button')}
                        </a>
                    </div>
                </div>
            }
        }

        return (
            <div className={`project-group ${this.props.selected ? 'open' : ''}`}>
                <BulkAutoSetupModal
                    open={this.state.bulkAutoSetupModalOpen}
                    onClose={() => this.setBulkSetupModalState(false)}
                    bulkSetupHref={this.props.bulkSetupHref}
                    groupName={this.props.name} />
                <div className='project-group-header' onClick={this.props.onGroupClick}>
                    <div className={`project-group-avatar ${this.props.selectedProjectWithin ? 'project-within' : ''}`} style={{backgroundImage: `url(${this.props.avatarHref})`}} />
                    <span className='project-group-name'>
                        {this.props.name}
                    </span>
                    <FontAwesomeIcon icon={caret} />
                </div>
                {content}
            </div>
        );
    }

}

export default withCookies(withTranslation()(ProjectGroup));
