import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import dayjs from 'dayjs';

import StdButton from '../../../Common/StdButton/StdButton';

import './Announcements.css'

class AnnouncementListItem extends React.Component {

    render() {
        const {t} = this.props;

        let icon;
        switch (this.props.priority) {
            case 'INFO': icon = 'circle-info'; break;
            case 'WARNING': icon = 'flag'; break;
            case 'CRITICAL': icon = 'fire'; break;
            default: icon = 'circle-question'; break;
        }

        return (
            <div className={`announcement ${!this.props.active ? 'archived' : ''}`}>
                <div className='announcement-header'>
                    <div className={`priority-icon ${this.props.priority}`}>
                        <FontAwesomeIcon icon={icon}/>
                    </div>
                    <span className='announcement-time'>{dayjs(this.props.timestamp).format('MMM DD, YYYY - h:mm:ss A')}</span>
                    {
                        (this.props.active && this.props.canArchive) ? <StdButton colorClass='primary' extraClass='announcement-archive-button'
                            onClick={this.props.archiveFunction}>
                            <FontAwesomeIcon icon='box-archive' /> {t('admin.announcements.button-archive')}
                        </StdButton> : null
                    }
                </div>

                <div className='announcement-message'>{parse(this.props.message)}</div>
            </div>
        );
    }

}

export default withTranslation()(AnnouncementListItem);
