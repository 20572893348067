import React from 'react';
import { withTranslation } from 'react-i18next';

import ProjectListItem from './ProjectListItem';

import '../Results.css'

class RecentlyViewed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recentHrefs: []
        }

        this.setRecents = this.setRecents.bind(this);
        this.renderProjectItems = this.renderProjectItems.bind(this);
    }

    componentDidMount() {
        this.setRecents();
        window.addEventListener('storage', () => {
            this.setRecents()
        })
    }

    setRecents() {
        const recents = JSON.parse(localStorage.getItem('cptGitHubRecents') || '[]');
        this.setState({
            recentHrefs: recents
        });
    }

    renderProjectItems(items) {
        const listItems = [];
        items.forEach(item => {
            const selected = this.props.selectedProject &&
                item.split('/').slice(-2).join('/') === this.props.selectedProject.split('/').slice(-2).join('/');
            const name = item.split('/').slice(-1);
            const fullName = item.split('/').slice(-2).join('/');
            listItems.push(
                <ProjectListItem
                    key={item}
                    name={name}
                    fullName={fullName}
                    href={item}
                    selected={selected}
                    onProjectClick={() => this.props.onProjectClick(item)}
                    recent={true}
                />
            )}
        );
        return listItems;
    }

    render() {
        const {t} = this.props;

        let list = [];
        if (this.state.recentHrefs) {
            list = this.renderProjectItems(this.state.recentHrefs.reverse());
        }

        return (
            this.state.recentHrefs.length ?
                <div className='recently-viewed project-group open'>
                    <div className='recently-viewed-title'>{t('results.sidebar.recent')}</div>
                    {list}
                </div>
            : null
        );
    }

}

export default withTranslation()(RecentlyViewed);
