import React from 'react';

import ChatBubble from './ChatBubble';
import ChoiceBubbles from './ChoiceBubbles';
import ChatExampleLink from './ChatExampleLink';

import './ChatInterface.css';

class ChatInterface extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            choices: []
        }

        this.pushChoice = this.pushChoice.bind(this);
        this.traverseConversation = this.traverseConversation.bind(this);
    }

    pushChoice(level, choiceId, selectFunction) {
        let choicesArray = this.state.choices;
        // Remove all choices past this level
        choicesArray.length = level;
        this.setState({
            choices: choicesArray
        }, () => {
            // Assign the new choice at this level
            choicesArray[level] = choiceId;
            this.setState({
                choices: choicesArray
            });
        })
    }

    render() {
        const chatBubbles = this.traverseConversation(this.props.conversationTree, [], 0);

        return(
            <div className='chat-interface'>
                {chatBubbles}
            </div>
        )
    }

    traverseConversation(tree, conversation, level) {
        let nextMessageText = tree.message;
        if (!nextMessageText && tree.messageFunction) {
            nextMessageText = tree.messageFunction(this.state.choices[level - 1]);
        }
        const nextMessage = <ChatBubble message={nextMessageText} key={tree.messageId} />
        let updatedConversation;
        if (tree.options) {
            let options = [];
            const treeOpts = tree.options || [];
            treeOpts.forEach(option => {
                if (option.optionType === 'branch') {
                    options.push({
                        message: option.message,
                        selected: this.state.choices.length >= level && this.state.choices[level] === option.choiceId,
                        onClick: () => { this.pushChoice(level, option.choiceId) },
                        response: option.response
                    });
                } else if (option.optionType === 'feedback') {
                    options.push({
                        message: option.message,
                        selected: false,
                        onClick: () => {this.props.feedback.current.setOpenState(true, option.feedbackTemplate)}
                    });
                } else if (option.optionType === 'dropdown') {
                    options.push({
                        message: option.message,
                        dropdownChoices: option.dropdownChoices,
                        selected: this.state.choices.length >= level && this.state.choices[level] === option.choiceId,
                        onItemClick: (choice) => {
                            option.dropdownSelectFunction(choice);
                            this.pushChoice(level, option.choiceId);
                        },
                        response: option.response
                    })
                }
            });
            const choices = <ChoiceBubbles options={options} />;
            updatedConversation = [...conversation, nextMessage, choices];

            const selectedChoice = options.find(o => o.selected);
            if (selectedChoice) {
                updatedConversation = this.traverseConversation(selectedChoice.response, updatedConversation, level + 1);
            }
        } else {
            if (tree.exampleLink) {
                const exampleLink = <ChatExampleLink exampleLink={tree.exampleLink.link} ciName={tree.exampleLink.ciName} />;
                updatedConversation = [...conversation, nextMessage, exampleLink]
            } else {
                updatedConversation = [...conversation, nextMessage];
            }
        }

        return updatedConversation;
    }

}

export default ChatInterface;
