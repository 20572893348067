import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';

import SetupConversation from './SetupConversation';

import { getTitlebarTitle } from '../../Constants';

import './Help.css';

class Help extends React.Component {

    render() {
        const { t } = this.props;
        return(
            <div id='help'>
                <Helmet>
                    <title>{getTitlebarTitle(t('help.page-title'), t)}</title>
                </Helmet>
                <h2>{t('help.configuration-title')}</h2>
                <p>{t('help.configuration-description')}</p>
                <SetupConversation feedback={this.props.feedback} urlDirectory={this.props.urlDirectory} />
            </div>
        );
    }

}

export default withTranslation()(Help);
