import React from 'react';

import './Dropdown.css';

class Dropdown extends React.Component {

    constructor(props) {
        super (props);
        this.ref = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleClickOutside(event) {
        if (this.props.open) {
            if (this.ref.current && !this.ref.current.contains(event.target)) {
                this.props.handleOutsideClick && this.props.handleOutsideClick();
            }
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside.bind(this), true);
    }

    render() {
        if (this.props.open) {
            return(
                <div ref={this.ref} className={`dropdown ${this.props.className}`}>
                    {this.props.children}
                </div>
            );
        } else {
            return null;
        }
    }

}

export default Dropdown;
