import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailValidator from 'email-validator';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';

import { API_HEADERS, apiHeadersContentXsrf } from '../../Constants';
import ToggleSwitch from '../Common/ToggleSwitch/ToggleSwitch';
import StdButton from '../Common/StdButton/StdButton';
import Dropdown from '../Common/Dropdown/Dropdown';
import UserContext, { LoadUser } from '../Common/Users/Users';

import { getTitlebarTitle } from '../../Constants';

import './UserProfile.css'

class UserProfile extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: null,
            notifications: false,
            email: '',
            avatar: '',
            emailValid: true,
            emailOnLoad: false,
            selectedTheme: 'default',
            themeDropdownOpen: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.getThemeSelection = this.getThemeSelection.bind(this);
        this.setThemeSelection = this.setThemeSelection.bind(this);
        this.setThemeDropdownState = this.setThemeDropdownState.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            let emailValid = true;
            if (this.state.notifications && this.state.email) {
                emailValid = EmailValidator.validate(this.state.email);
            }
            this.setState({
                emailValid: emailValid
            });
        });
    }

    async componentDidMount() {
        this.loadUser();
        this.getThemeSelection();
    }

    getThemeSelection() {
        this.setState({
            selectedTheme: localStorage.getItem('cptTheme') || 'default'
        });
    }

    setThemeSelection(newTheme) {
        localStorage.setItem('cptTheme', newTheme);
        // Manually trigger the storage event so our theme can update
        window.dispatchEvent(new Event('storage'));
        this.setState({
            selectedTheme: newTheme
        });
    }

    async setThemeDropdownState(open) {
        this.setState({
            themeDropdownOpen: open
        });
    }

    async loadUser() {
        // Have to fetch this ourselves rather than use the provider,
        // since we want to be able to update it manually
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            const avatarUrl = json._meta.links
                            .find((l) => l.rel === 'avatar')
                            .href;
            this.setState({
                loading: false,
                user: json,
                notifications: json.emailEnabled,
                email: json.email,
                avatar: avatarUrl,
                emailOnLoad: Boolean(json.email)
            });
        })
        .catch((error) => {
            // Error loading user data means we're not logged in
            // Log us out of the SPA
            LoadUser(this.props.baseHref, this.context.setUser);
            this.props.history.push('/');
        });
    }

    async updateUser() {
        const {t} = this.props;
        const userRequest = {
            emailEnabled: this.state.notifications,
            email: this.state.email
        }

        fetch(this.props.baseHref, {
            method: 'PATCH',
            body: JSON.stringify(userRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('user.update-success'));
            this.loadUser();
        });
    }

    render() {
        const {t} = this.props;

        let content = <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/>;
        let updateEnabled = true;
        if (this.state.notifications) {
            if (!this.state.email || !this.state.emailValid) {
                updateEnabled = false;
            }
        }
        if (!this.state.loading && this.state.user) {
            const user = this.state.user
            content =
            <React.Fragment>
                <div className='user-info'>
                    <div className='user-avatar' style={{backgroundImage: `url(${this.state.avatar})`}} />
                    <div className='user-info-text'>
                        <div>{t('user.signed-in', {username: user.login})}</div>
                        <div>{t('user.provider', {provider: t(`general.login-providers.${user.loginProvider}`)})}</div>
                    </div>
                </div>
                <div className='user-setting-block'>
                    <div className='user-setting-block-header'>
                        <div className='user-setting-name'>{t('user.email-settings')}</div>
                        <div className='user-setting-description'>{t('user.email-description')}</div>
                    </div>
                    <ToggleSwitch
                        toggleOnFunction={() => this.setState({notifications: true})}
                        toggleOffFunction={() => this.setState({notifications: false})}
                        on={this.state.notifications}
                        />
                </div>
                {this.state.notifications ?
                    <div className='user-setting-block'>
                        <div className='user-setting-block-header'>
                            <div className='user-setting-name'>{t('user.email-address')}</div>
                            <div className='user-setting-description'>{t('user.email-address-description')}</div>
                        </div>
                        <input className='user-setting-input-text' name='email'
                            value={this.state.email}
                            onChange={this.handleInputChange} />
                        { !this.state.emailValid ?
                            <div className='user-email-validation'>
                                {t('feedback.email-invalid')}
                            </div>
                        : null }
                    </div>
                : null }
                <div className='disclaimer'>
                    {t('user.email-retention')}
                </div>
                <StdButton colorClass='positive' extraClass='btn-settings-submit'
                    onClick={this.updateUser} disabled={!updateEnabled}>
                    {t('user.button-update')}
                </StdButton>

                <div className='user-setting-block'>
                    <div className='user-setting-block-header'>
                        <div className='user-setting-name'>{t('user.themes.theme')}</div>
                        <div className='user-setting-description'>{t('user.themes.theme-description')}</div>
                    </div>
                    <div className={`theme-dropdown-container ${this.state.themeDropdownOpen ? 'open' : ''}`} onClick={() => this.setThemeDropdownState(!this.state.themeDropdownOpen)}>
                        <FontAwesomeIcon icon={this.state.themeDropdownOpen ? 'caret-down' : 'caret-right'}/>
                        {t('user.themes.' + this.state.selectedTheme)}
                        <Dropdown className='theme-dropdown' open={this.state.themeDropdownOpen} handleOutsideClick={() => this.setThemeDropdownState(false)}>
                            <div className={`theme-row ${this.state.selectedTheme === 'default' ? 'selected' : ''}`} onClick={() => this.setThemeSelection('default')}>
                                {t('user.themes.default')}
                            </div>
                            <div className={`theme-row ${this.state.selectedTheme === 'classic' ? 'selected' : ''}`} onClick={() => this.setThemeSelection('classic')}>
                                {t('user.themes.classic')}
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </React.Fragment>
        }
        return(
            <div id='user-profile'>
                <Helmet>
                    <title>{getTitlebarTitle(t('user.page-title'), t)}</title>
                </Helmet>
                <h2>{t('user.header')}</h2>
                {content}
            </div>
        )
    }

}

export default withCookies(withRouter(withTranslation()(UserProfile)));
