export function getTitlebarTitle(pageTitle, t) {
    return `${pageTitle} | ${t('general.product-name')}`;
}

export const API_HEADERS = new Headers({
    'Accept': 'application/vnd.blackducksoftware.duckbuild.v1+json',
    'User-Agent': `CoPilotFrontend/${process.env.REACT_APP_VERSION}`,
    'X-Custom-User-Agent': `CoPilotFrontend/${process.env.REACT_APP_VERSION}`
});

export const API_HEADERS_FORM_DATA = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
    'User-Agent': `CoPilotFrontend/${process.env.REACT_APP_VERSION}`,
    'X-Custom-User-Agent': `CoPilotFrontend/${process.env.REACT_APP_VERSION}`
});

export function apiHeadersContentXsrf(cookies) {
    return new Headers({
        'Accept': 'application/vnd.blackducksoftware.duckbuild.v1+json',
        'Content-Type': 'application/vnd.blackducksoftware.duckbuild.v1+json',
        'User-Agent': `CoPilotFrontend/${process.env.REACT_APP_VERSION}`,
        'X-Custom-User-Agent': `CoPilotFrontend/${process.env.REACT_APP_VERSION}`,
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN')
    });
}
