import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation, Trans } from 'react-i18next';
import EmailValidator from 'email-validator';

import { apiHeadersContentXsrf } from '../../Constants';

import Modal from '../Common/Modal/Modal';
import StdButton from '../Common/StdButton/StdButton';
import ButtonBar from '../Common/ButtonBar/ButtonBar';

import './TopNavigation.css';

class Feedback extends React.Component {

    constructor() {
        super();
        this.state = {
            modalOpen: false,
            feedbackComment: '',
            npsScore: null,
            contactOptIn: false,
            contactEmail: '',
            emailValid: true,
            formEdited: false
        }
        this.setOpenState = this.setOpenState.bind(this);
        this.sendFeedback = this.sendFeedback.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.onButtonBarClick = this.onButtonBarClick.bind(this);

        this.buttonbarRef = React.createRef();
    }

    componentDidMount() {
        this.props.refSetter({current: this});
    }

    setOpenState(open, preFillComment) {
        this.setState({
            modalOpen: open,
            feedbackComment: preFillComment || '',
            npsScore: null,
            contactOptIn: false,
            contactEmail: '',
            emailValid: true,
            formEdited: false
        });
        this.buttonbarRef.current.setState({
            selectedValue: null
        })
    }

    async sendFeedback() {
        const feedbackRequest = {
            comment: this.state.feedbackComment,
            score: this.state.npsScore,
            contactEmail: this.state.contactEmail
        }

        fetch(this.props.feedbackHref, {
            method: 'POST',
            body: JSON.stringify(feedbackRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            this.setOpenState(false);
        });

    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            let emailValid = true;
            if (this.state.contactOptIn) {
                emailValid = EmailValidator.validate(this.state.contactEmail);
            }
            this.setState({
                emailValid: emailValid,
                formEdited: true
            });
        });
    }

    handleCheckboxChange(event) {
        this.setState({
            [event.target.name]: event.target.checked
        }, () => {
            if (!this.state.contactOptIn) {
                this.setState({
                    emailValid: true,
                    contactEmail: ''
                })
            }
        })
    }

    onButtonBarClick(newValue) {
        this.setState({
            npsScore: newValue,
            formEdited: true
        })
    }

    render() {
        const {t} = this.props;

        return (
            <Modal className='modal-feedback'
                open={this.state.modalOpen}
                onClose={() => this.setOpenState(false)}
                title={t('feedback.title')}
                content={
                    <React.Fragment>
                        <div className='modal-text'><Trans i18nKey='feedback.description'><br/></Trans></div>
                        <div className='feedback-form'>
                            <div className='feedback-form-label'>{t('feedback.comment')}</div>
                            <textarea rows='5' cols='60'
                                    name='feedbackComment'
                                    value={this.state.feedbackComment}
                                    onChange={this.handleInputChange} />

                            <div className='feedback-form-label'>{t('feedback.nps-title')}</div>
                            <ButtonBar ref={this.buttonbarRef} options={[
                                    {label: 0, value: 0},
                                    {label: 1, value: 1},
                                    {label: 2, value: 2},
                                    {label: 3, value: 3},
                                    {label: 4, value: 4},
                                    {label: 5, value: 5},
                                    {label: 6, value: 6},
                                    {label: 7, value: 7},
                                    {label: 8, value: 8},
                                    {label: 9, value: 9},
                                    {label: 10, value: 10},
                                ]} onButtonBarClick={this.onButtonBarClick} />
                            <div className='nps-labels'>
                                <span>{t('feedback.nps-not-likely')}</span>
                                <span>{t('feedback.nps-likely')}</span>
                            </div>
                            <div className='feedback-contact'>
                                <input id='feedback-contact-checkbox' type='checkbox' name='contactOptIn' checked={this.state.contactOptIn} onChange={this.handleCheckboxChange} />
                                <label htmlFor='feedback-contact-checkbox'>{t('feedback.contact-opt-in')}</label>
                            </div>
                            { this.state.contactOptIn ?
                                <div className='feedback-contact-email'>
                                    <label htmlFor='feedback-email'>{t('feedback.contact-email')}</label>
                                    <input id='feedback-email' type='text' name='contactEmail' value={this.state.contactEmail} onChange={this.handleInputChange} />
                                    { !this.state.emailValid ?
                                        <div className='feedback-email-validation'>
                                            {t('feedback.email-invalid')}
                                        </div>
                                    : null }
                                </div>
                            : null }
                        </div>
                    </React.Fragment>
                }
                buttons={[
                    <StdButton key='send-button' colorClass='positive' extraClassName='button' onClick={this.sendFeedback} disabled={!this.state.formEdited || !this.state.emailValid}>{t('feedback.button-submit')}</StdButton>,
                    <StdButton key='close-button' extraClassName='button' onClick={() => this.setOpenState(false)}>{t('general.close')}</StdButton>
                ]} />
        );
    }

}

export default withCookies(withTranslation()(Feedback));
