import React from 'react';
import { withCookies } from 'react-cookie';
import { Switch, Route, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { API_HEADERS } from '../../Constants';
import AdminLanding from './AdminPages/AdminLanding';

import PrivatePage from '../Common/PrivatePage/PrivatePage';
import Sidebar from '../Common/TwoPane/Sidebar';
import ConfigurationListItem from './ConfigurationListItem';

import Announcements from './AdminPages/Announcements/Announcements';
import ChangeLogs from './AdminPages/ChangeLogs/ChangeLogs';
import Feedback from './AdminPages/Feedback/Feedback';

import AnalysisErrors from './AdminPages/AnalysisErrors/AnalysisErrors';
import AutoContribution from './AdminPages/AutoContribution/AutoContribution';
import Statistics from './AdminPages/Statistics/Statistics';
import Performance from './AdminPages/Performance/Performance';

import AnalysisTool from './AdminPages/AnalysisTool/AnalysisTool';
import CustomerAccounts from './AdminPages/CustomerAccounts/CustomerAccounts';
import KnowledgeBase from './AdminPages/KnowledgeBase/KnowledgeBase';
import Notifications from './AdminPages/Notifications/Notifications';
import PrivateMode from './AdminPages/PrivateMode/PrivateMode';

import './Admin.css'

/**
 * The Admin component displays a two-panel view, with the available configuration pages on the left,
 * and the selected configuration page on the right.
 */
class Admin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            authenticated: false,
            allConfigPaths: null,
            communicationConfigs: null,
            dataReviewConfigs: null,
            setupConfigs: null
        }
    }

    componentDidMount() {
        fetch(`${this.props.urlDirectory.administration.configurationsUrl}?per_page=20`, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            const communicationConfigs = json.items.filter(item => item.category === 'COMMUNICATION');
            const dataReviewConfigs = json.items.filter(item => item.category === 'DATA_REVIEW');
            const setupConfigs = json.items.filter(item => item.category === 'SETUP');
            this.setState({
                loading: false,
                authenticated: true,
                allConfigPaths: json.items,
                communicationConfigs,
                dataReviewConfigs,
                setupConfigs
            });
        })
        .catch(err => {
            this.setState({
                loading: false,
                authenticated: false
            })
        });
    }

    configsToListItems(configList, currentRoute) {
        let configListItems = [];
        if (configList !== null) {
            configList.forEach(item => {
                const location = new URL(item._meta.href).pathname;
                configListItems.push(<ConfigurationListItem key={item._meta.href}
                    name={item.name}
                    icon={item._graphic.fontAwesomeIcon}
                    linkLocation={location}
                    selected={currentRoute.endsWith(location)} />);
            })
        }
        return configListItems;
    }

    render() {
        const { t } = this.props;
        const currentRoute = this.props.location.pathname;

        const baseHrefs = {};
        if (this.state.allConfigPaths) {
            // Commmunication
            baseHrefs.announcements = this.state.allConfigPaths
                .find((c) => c.name === 'Announcements')
                ._meta.href;
            baseHrefs.changeLogs = this.state.allConfigPaths
                .find((c) => c.name === 'Change Logs')
                ._meta.href;
            baseHrefs.feedback = this.state.allConfigPaths
                .find((c) => c.name === 'Feedback')
                ._meta.href;
            // Data Review
            baseHrefs.analysisErrors = this.state.allConfigPaths
                .find((c) => c.name === 'Analysis Errors')
                ._meta.href;
            baseHrefs.autoContribution = this.state.allConfigPaths
                .find((c) => c.name === 'Automated Contribution')
                ._meta.href;
            baseHrefs.performance = this.state.allConfigPaths
                .find((c) => c.name === 'Performance')
                ._meta.href;
            baseHrefs.statistics = this.state.allConfigPaths
                .find((c) => c.name === 'Statistics')
                ._meta.href;
            // Setup
            baseHrefs.analysisTool = this.state.allConfigPaths
                .find((c) => c.name === 'Analysis Tool')
                ._meta.href;
            baseHrefs.customerAccounts = this.state.allConfigPaths
                .find((c) => c.name === 'Customer Accounts')
                ._meta.href;
            baseHrefs.knowledgeBase = this.state.allConfigPaths
                .find((c) => c.name === 'KnowledgeBase')
                ._meta.href;
            baseHrefs.notifications = this.state.allConfigPaths
                .find((c) => c.name === 'Notifications')
                ._meta.href;
            baseHrefs.privateMode = this.state.allConfigPaths
                .find((c) => c.name === 'Private Mode')
                ._meta.href;
        }

        return (
            <PrivatePage loading={this.state.loading} authenticated={this.state.authenticated} redirectLocation={this.props.urlDirectory.administration.loginUrl} reloadPage={true}>
                <div id='twopane'>
                    <Sidebar>
                        <div className='config-category'>
                            <h2>{t("admin.category.communication")}</h2>
                            {
                                this.configsToListItems(this.state.communicationConfigs, currentRoute)
                            }
                        </div>
                        <div className='config-category'>
                            <h2>{t("admin.category.data-review")}</h2>
                            {
                                this.configsToListItems(this.state.dataReviewConfigs, currentRoute)
                            }
                        </div>
                        <div className='config-category'>
                            <h2>{t("admin.category.setup")}</h2>
                            {
                                this.configsToListItems(this.state.setupConfigs, currentRoute)
                            }
                        </div>
                    </Sidebar>
                    <Switch>
                        <Route path='/blackduck/configuration/announcements'>
                            <Announcements baseHref={baseHrefs.announcements} />
                        </Route>
                        <Route path='/blackduck/configuration/pending-change-logs'>
                            <ChangeLogs baseHref={baseHrefs.changeLogs} />
                        </Route>
                        <Route path='/blackduck/configuration/feedback'>
                            <Feedback baseHref={baseHrefs.feedback} />
                        </Route>

                        <Route path='/blackduck/configuration/errors'>
                            <AnalysisErrors baseHref={baseHrefs.analysisErrors}/>
                        </Route>
                        <Route path='/blackduck/configuration/automated-contributions'>
                            <AutoContribution baseHref={baseHrefs.autoContribution}/>
                        </Route>
                        <Route path='/blackduck/configuration/statistics'>
                            <Statistics baseHref={baseHrefs.statistics}/>
                        </Route>
                        <Route path='/blackduck/configuration/performance'>
                            <Performance baseHref={baseHrefs.performance}/>
                        </Route>

                        <Route path='/blackduck/configuration/tool'>
                            <AnalysisTool baseHref={baseHrefs.analysisTool}/>
                        </Route>
                        <Route path='/blackduck/configuration/customer-accounts'>
                            <CustomerAccounts baseHref={baseHrefs.customerAccounts}/>
                        </Route>
                        <Route path='/blackduck/configuration/knowledgebase'>
                            <KnowledgeBase baseHref={baseHrefs.knowledgeBase}/>
                        </Route>
                        <Route path='/blackduck/configuration/notifications'>
                            <Notifications baseHref={baseHrefs.notifications}/>
                        </Route>
                        <Route path='/blackduck/configuration/private-mode'>
                            <PrivateMode baseHref={baseHrefs.privateMode}/>
                        </Route>

                        <Route path='/blackduck/' exact={true} component={AdminLanding} />
                    </Switch>
                </div>
            </PrivatePage>
        );
    }
}

export default withCookies(withRouter(withTranslation()(Admin)));
