import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import AdminPageHeader from '../AdminPageHeader';
import ToggleSwitch from '../../../Common/ToggleSwitch/ToggleSwitch';
import StdButton from '../../../Common/StdButton/StdButton';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './Notifications.css';

class Notifications extends React.Component {

    constructor() {
        super();
        this.state = {
            slackUrl: '',
            analysisErrors: false,
            feedback: false,
            installations: false,
            canPut: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.loadNotificationSettings = this.loadNotificationSettings.bind(this);
        this.updateNotificationSettings = this.updateNotificationSettings.bind(this);
    }

    async componentDidMount() {
        this.loadNotificationSettings();
    }

    async loadNotificationSettings() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                slackUrl: json.slackUrl || '',
                analysisErrors: json.analysisErrorsEnabled,
                feedback: json.feedbackEnabled,
                installations: json.installationsEnabled,
                canPut: json._meta.allow.includes('PUT')
            });
        });
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyPress(event) {
        if (this.state.canPut && event.key === 'Enter') {
            this.updateNotificationSettings();
        }
    }

    async updateNotificationSettings() {
        const {t} = this.props;

        const notificationRequest = {
            slackUrl: this.state.slackUrl,
            analysisErrorsEnabled: this.state.analysisErrors,
            feedbackEnabled: this.state.feedback,
            installationsEnabled: this.state.installations
        }

        fetch(this.props.baseHref, {
            method: 'PUT',
            body: JSON.stringify(notificationRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.notifications.updated-success'));
            this.loadNotificationSettings();
        });
    }

    render() {
        const {t} = this.props;
        return (
            <div id='twopane-content' className='admin-page'>
                <AdminPageHeader
                    name={t('admin.notifications.page-title')}
                    description={t('admin.notifications.description')}
                    icon='bell' />

                <div className='admin-page-content'>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.notifications.slack-url-title')}</div>
                            <div className='setting-description'>{t('admin.notifications.slack-url-description')}</div>
                        </div>
                        <input className='setting-input-text' name='slackUrl'
                            value={this.state.slackUrl}
                            onChange={this.handleInputChange}
                            disabled={!this.state.canPut} />
                    </div>
                    <div className='setting-block notification-types'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.notifications.types-title')}</div>
                            <div className='setting-description'>{t('admin.notifications.types-description')}</div>
                        </div>
                        <div className='sub-setting'>
                            <div className='sub-setting-name'>{t('admin.notifications.type-errors')}</div>
                            <ToggleSwitch
                                toggleOnFunction={() => this.setState({analysisErrors: true})}
                                toggleOffFunction={() => this.setState({analysisErrors: false})}
                                on={this.state.analysisErrors}
                                disabled={!this.state.canPut}
                                />
                        </div>
                        <div className='sub-setting'>
                            <div className='sub-setting-name'>{t('admin.notifications.type-feedback')}</div>
                            <ToggleSwitch
                                toggleOnFunction={() => this.setState({feedback: true})}
                                toggleOffFunction={() => this.setState({feedback: false})}
                                on={this.state.feedback}
                                disabled={!this.state.canPut}
                                />
                        </div>
                        <div className='sub-setting'>
                            <div className='sub-setting-name'>{t('admin.notifications.type-installs')}</div>
                            <ToggleSwitch
                                toggleOnFunction={() => this.setState({installations: true})}
                                toggleOffFunction={() => this.setState({installations: false})}
                                on={this.state.installations}
                                disabled={!this.state.canPut}
                                />
                        </div>
                    </div>

                    {this.state.canPut ?
                        <StdButton colorClass='positive' extraClass='update-notifications-button' onClick={this.updateNotificationSettings}>{t('general.button-update')}</StdButton>
                    : null}
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(Notifications));
