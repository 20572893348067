import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import { getTitlebarTitle } from '../../Constants';
import { API_HEADERS } from '../../Constants';

import './Health.css';

class Health extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            healthView: null,
            loading: true
        }
    }
    
    async componentDidMount() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                healthView: json,
                loading: false
            });
        });
    }

    render() {
        const { t } = this.props;
        const allHealthy = this.state.loading ? false : 
            (this.state.healthView.persistenceAdminHealthy &&
             this.state.healthView.persistenceCustomerHealthy &&
             this.state.healthView.persistenceStatisticsHealthy);
        return(
            <div id='help'>
                <Helmet>
                    <title>{getTitlebarTitle(t('health.title'), t)}</title>
                </Helmet>
                <h2>{t(`health.health-all-${allHealthy ? 'yes' : 'no'}`)}</h2>
                {this.state.loading ?
                    <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                    <div className='health-body'>
                        <div className='checks'>
                            <div className={`check ${this.state.healthView.persistenceAdminHealthy ? 'healthy' : 'unhealthy'}`}>
                                <FontAwesomeIcon icon='database' />
                                {t('health.health-persistence-admin')}
                            </div>
                            <div className={`check ${this.state.healthView.persistenceCustomerHealthy ? 'healthy' : 'unhealthy'}`}>
                                <FontAwesomeIcon icon='database' />
                                {t('health.health-persistence-customer')}
                            </div>
                            <div className={`check ${this.state.healthView.persistenceStatisticsHealthy ? 'healthy' : 'unhealthy'}`}>
                                <FontAwesomeIcon icon='database' />
                                {t('health.health-persistence-statistics')}
                            </div>
                        </div>
                        <div className='last-checked-time'>
                            {t('health.last-checked', {timestamp: dayjs(this.state.healthView.lastCheckedDate).format('YYYY-MM-DD HH:mm:ss')})}
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default withTranslation()(Health);
