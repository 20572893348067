import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProjectInfoPopover from './ProjectInfoPopover';

import '../Results.css'

class ProjectListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovering: false
        }
    }

    render() {
        let label = this.props.name;
        if (this.props.searchTerm) {
            let stringParts = this.props.name.split(new RegExp(`(${this.props.searchTerm})`, 'gi'));
            for (let i = 0; i < stringParts.length; i += 1) {
                if (stringParts[i].toLowerCase() === this.props.searchTerm.toLowerCase()) {
                    stringParts[i] = <span className='search-highlight' key={i}>{stringParts[i]}</span>;
                }
            }
            label = <span>{stringParts}</span>;
        }

        const lastScanIcon = this.props.lastScan ? 'circle-check' : 'circle-question';

        return (
            <Link to={`/github/repos/${this.props.fullName}`} className={`project-item ${this.props.selected ? 'selected' : ''}`}
                 onClick={this.props.onProjectClick}>
                <span className='project-label'>{label}</span>
                {!this.props.recent ?
                    <div className='icon-holder' onMouseEnter={() => this.setState({hovering: true})} onMouseLeave={() => this.setState({hovering: false})}>
                        <FontAwesomeIcon icon={lastScanIcon} className={this.props.lastScan ? 'scanned' : ''} />
                    </div>
                : null }
                {this.state.hovering ?
                    <ProjectInfoPopover projectName={this.props.name} fork={this.props.fork} description={this.props.description} lastScan={this.props.lastScan}/>
                : null}
            </Link>
        );
    }

}

export default ProjectListItem;
