import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next'

import Sidebar from '../../Common/TwoPane/Sidebar';
import ProjectGroup from './ProjectGroup';
import RecentlyViewed from './RecentlyViewed';

import { API_HEADERS } from '../../../Constants';

import '../Results.css'

class ResultsSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            loading: true,
            setupUrl: ''
        }
    }

    async componentDidMount() {
        fetch(this.props.urlDirectory.scm.gitHubLandingUrl, {
            headers: API_HEADERS
        })
        .then(result => {
            if (result.status === 401) {
                throw new Error('Unauthorized');
            } else {
                return result.json();
            }
        })
        .then(json => {
            const setupHref = json._meta.links
                .find((l) => l.rel === 'setup')
                .href;
            if (!json.items.length) {
                this.props.needFirstTimeSetup(setupHref);
            }
            this.setState({
                groups: json.items,
                loading: false,
                setupUrl: setupHref
            }, () => {
                if (this.props.location.pathname) {
                    const pathParts = this.props.location.pathname.split('/');
                    if (pathParts.length <= 4 && this.state.groups.length > 0) {
                        // If path does not contain a selected group/project, expand the first group
                        this.props.selectGroup(this.state.groups[0]._meta.href);
                    }
                }
            });
        })
        .catch((error) => {
            this.props.notLoggedIn();
        });
    }

    render() {
        const {t} = this.props;

        let groups = [];
        this.state.groups.forEach(g => {
            const avatarHref = g._meta.links
                .find((l) => l.rel === 'avatar')
                .href;
            const setupLink = g._meta.links
                .find((l) => l.rel === 'analysis-setup');
            groups.push(<ProjectGroup
                key={g._meta.href}
                projectsHref={g.sourceLocations}
                name={g.name}
                avatarHref={avatarHref}
                selected={this.props.selectedGroup && g._meta.href.endsWith(this.props.selectedGroup)}
                selectedProject={this.props.selectedProject}
                selectedProjectWithin={this.props.selectedProject && this.props.selectedProject.split('/').slice(-2)[0] === g.name}
                onGroupClick={() => this.props.selectGroup(g._meta.href)}
                onProjectClick={this.props.selectProject}
                bulkSetupHref={setupLink?.href}
                setupState={g.setupState}
                notLoggedIn={this.props.notLoggedIn}
            />);
        });

        return (
            <Sidebar open={this.props.open}>
                {this.state.loading ?
                    <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                    <React.Fragment>
                        {groups}
                        <a className='setup-link' href={this.state.setupUrl}>
                            {t('results.sidebar.add-orgs', {sourceProvider: 'GitHub'})}
                        </a>
                        <RecentlyViewed selectedProject={this.props.selectedProject} onProjectClick={this.props.selectProject} />
                    </React.Fragment>
                }
            </Sidebar>
        );
    }

}

export default withCookies(withRouter(withTranslation()(ResultsSidebar)));
