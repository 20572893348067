import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ComponentListItem extends React.Component {

    constructor(props) {
        super(props)
        this.ref  = React.createRef();
        this.clickFunction = this.clickFunction.bind(this);
    }

    clickFunction() {
        const scrollAmount = this.props.containerRef.current.scrollTop;
        const containerBottom = this.props.containerRef.current.getBoundingClientRect().bottom + scrollAmount;
        const yPos = this.ref.current.getBoundingClientRect().y + scrollAmount;
        this.props.clickFunction(this.props.href, yPos, containerBottom);
    }

    render() {
        let riskIcon;
        switch (this.props.component.risk) {
            case 'HIGH': riskIcon = 'fire'; break;
            case 'MEDIUM': riskIcon = 'circle-exclamation'; break;
            case 'LOW': riskIcon = 'circle-dot'; break;
            case 'NEGLIGIBLE': riskIcon = 'circle-check'; break;
            default: riskIcon = 'circle-question'; break;
        }

        let relativeIndicator = null;
        switch (this.props.relativeState) {
            case 'ADDED': relativeIndicator = <FontAwesomeIcon icon='plus' />; break;
            case 'REMOVED': relativeIndicator = <FontAwesomeIcon icon='minus' />; break;
            case 'UPDATED': relativeIndicator = <FontAwesomeIcon icon='asterisk' />; break;
            default: relativeIndicator = null;
        }

        return (
            <div ref={this.ref} className={`component-row ${this.props.component.risk} ${this.props.selected ? 'selected' : ''} ${this.props.relativeState === 'REMOVED' ? 'removed' : ''}`} onClick={this.clickFunction}>
                <div className={`component-risk-icon ${this.props.component.risk}`}>
                    <FontAwesomeIcon icon={riskIcon}/>
                </div>
                <div className='component-name'>{this.props.component.name}</div>
                { relativeIndicator ? <div className='component-relative-indicator'>{relativeIndicator}</div> : null }
                <div className='component-version'>{this.props.component.version}</div>
            </div>
        );
    }

}

export default ComponentListItem;
