import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './Footer.css';

class Footer extends React.Component {

    render() {
        const { t } = this.props;
        return(
            <div id='footer'>
                <div id='footer-left'>
                    <div className='footer-copyright'>
                        {t('footer.copyright', {year: new Date().getFullYear()})}
                    </div>
                    <Link to={'/legal'} className='footer-link'>{t('footer.link-terms')}</Link>
                    <Link to={'/help'} className='footer-link'>{t('footer.link-help')}</Link>
                </div>
                <div id='footer-right'>
                    <div className='footer-version'>
                        {t('footer.version', {version: process.env.REACT_APP_VERSION})}
                    </div>
                    <Link to={'/whats-new'} className='footer-link'>{t('footer.link-changelog')}</Link>
                </div>
            </div>
        );
    }

}

export default withRouter(withTranslation()(Footer));
