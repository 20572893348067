import React from 'react';

import './TwoPane.css'

class Sidebar extends React.Component {

    render() {
        return (
            <div id='twopane-sidebar' className={`${this.props.className} ${this.props.open ? 'open' : ''}`}>
                {this.props.children}
            </div>
        );
    }

}

export default Sidebar;
