import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { API_HEADERS } from '../../Constants';
import PrivatePage from '../Common/PrivatePage/PrivatePage';

import ResultsSidebar from './ResultsSidebar/ResultsSidebar';
import ResultsMainPanel from './ResultsMainPanel/ResultsMainPanel';
import ResultsFirstTimeSetup from './ResultsFirstTimeSetup';

import './Results.css'

class Results extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            loginRedirectUrl: '',
            selectedGroup: '',
            selectedProject: '',
            setupUrl: '',
            authenticated: true
        }

        this.selectGroup = this.selectGroup.bind(this);
        this.selectProject = this.selectProject.bind(this);
        this.setGroupAndProject = this.setGroupAndProject.bind(this);
        this.needFirstTimeSetup = this.needFirstTimeSetup.bind(this);
        this.notLoggedIn = this.notLoggedIn.bind(this);

        this.mainPanelRef = React.createRef();
    }
    
    componentDidMount() {
        fetch(this.props.urlDirectory.loginMethodsUrl, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            const loginUrl = json.items
                .find((l) => l.loginProvider === 'GITHUB')
                .login;
            this.setState({
                loading: false,
                loginRedirectUrl: loginUrl
            });
        })
        .catch(err => {
            this.setState({
                loading: false
            })
        });
    }

    selectGroup(href) {
        if (href !== this.state.selectedGroup) {
            // Expanding a group
            this.setState({
                selectedGroup: href
            });
        } else {
            // Collapsing the selected group without expanding a new one
            this.setState({
                selectedGroup: ''
            })
        }
    }

    selectProject(href) {
        this.setState({
            selectedProject: href
        });
    }

    // To be called by the sidebar when no groups/repos are present, to put the page into a
    // first-time setup mode
    needFirstTimeSetup(setupUrl) {
        this.setState({
            setupUrl
        })
    }

    // To be called by any sub-page API calls if they return 401 unauthorized at any point
    notLoggedIn() {
        this.setState({
            authenticated: false
        })
    }

    // To be called on page load if coming from a direct link
    // Sets the selected group and project so the sidebar will properly untoggle them
    setGroupAndProject(groupHref, projectHref) {
        this.setState({
            selectedGroup: groupHref,
            selectedProject: projectHref
        });
    }

    render() {
        const {t} = this.props;
        
        if (!this.state.loading && this.state.loginRedirectUrl !== '') {
            const open = this.state.selectedProject !== '';
            let contents = <ResultsFirstTimeSetup setupHref={this.state.setupUrl} urlDirectory={this.props.urlDirectory} />;
            if ((this.state.selectedGroup && this.state.selectedProject) || !this.state.setupUrl) {
                contents =
                    <div id='twopane'>
                        <ResultsSidebar selectGroup={this.selectGroup} selectProject={this.selectProject}
                                selectedGroup={this.state.selectedGroup} selectedProject={this.state.selectedProject}
                                needFirstTimeSetup={this.needFirstTimeSetup} notLoggedIn={this.notLoggedIn} open={open}
                                urlDirectory={this.props.urlDirectory}/>
                        <div id='twopane-content' ref={this.mainPanelRef} className={open ? 'open' : ''}>
                            <ResultsMainPanel selectedProjectHref={this.state.selectedProject} sidebarStateSetter={this.setGroupAndProject}
                                containerRef={this.mainPanelRef} unselectProject={() => this.selectProject('')} notLoggedIn={this.notLoggedIn}
                                urlDirectory={this.props.urlDirectory}
                                feedback={this.props.feedback}/>
                        </div>
                    </div>
            }
            return (
                <PrivatePage loading={false} authenticated={this.state.authenticated} redirectLocation={this.state.loginRedirectUrl} reloadPage={true}>
                    {contents}
                </PrivatePage>
            );
        } else if (!this.state.loading && this.state.loginRedirectUrl === '') {
            return(
                <div id='unexpected-error'>
                    <FontAwesomeIcon icon='volcano' />
                    <span>{t('results.url-load-error')}</span>
                </div>
            )
        } else {
            return (
               <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/>
            );
        }
    }

}

export default withCookies(withTranslation()(Results));
