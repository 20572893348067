import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { getTitlebarTitle } from '../../../Constants';

import './AdminPage.css'

class AdminPageHeader extends React.Component {

    render() {
        const {t} = this.props;
        return (
            <div className='admin-page-header'>
                <Helmet>
                    <title>{getTitlebarTitle(this.props.name, t)}</title>
                </Helmet>
                <FontAwesomeIcon icon={this.props.icon} />
                <div className='page-name-and-description'>
                    <div className='admin-page-name'>
                        <span>{this.props.name}</span>
                    </div>
                    <div className='admin-page-description'>
                        {this.props.description}
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation()(AdminPageHeader);
