import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import AdminPageHeader from '../AdminPageHeader';
import ButtonBar from '../../../Common/ButtonBar/ButtonBar';
import StdButton from '../../../Common/StdButton/StdButton';
import Pager from '../../../Common/Pager/Pager';
import AnnouncementListItem from './AnnouncementListItem';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './Announcements.css';

class Announcements extends React.Component {

    constructor() {
        super();
        this.state = {
            canPost: false,
            newAnnouncementMessage: '',
            newAnnouncementPriority: 'INFO'
        }

        this.createAnnouncement = this.createAnnouncement.bind(this);
        this.archiveAnnoucement = this.archiveAnnoucement.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onButtonBarClick = this.onButtonBarClick.bind(this);

        // Needed only for active announcement renderer, to make the archive button work
        this.renderAnnouncementItems = this.renderAnnouncementItems.bind(this);

        this.activePager = React.createRef();
        this.archivedPager = React.createRef();
    }

    async componentDidMount() {
        // Although the lists are handled by the child Pager components, we still
        // need to make a call at this level to find out if we have POST permissions
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                canPost: json._meta.allow.includes('POST')
            });
        });
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onButtonBarClick(newValue) {
        this.setState({
            newAnnouncementPriority: newValue
        })
    }

    async createAnnouncement() {
        const {t} = this.props;

        const announcementRequest = {
            priority: this.state.newAnnouncementPriority,
            message: this.state.newAnnouncementMessage,
            active: true
        }

        this.setState({
            newAnnouncementPriority: 'INFO',
            newAnnouncementMessage: ''
        });

        fetch(this.props.baseHref, {
            method: 'POST',
            body: JSON.stringify(announcementRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.announcements.posted-success'));
            // Reset the active pager to first page
            this.activePager.current.toFirstPage();
        });
    }

    async archiveAnnoucement(href) {
        const {t} = this.props;

        const announcementPatch = {
            active: false
        }

        fetch(href, {
            method: 'PATCH',
            body: JSON.stringify(announcementPatch),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.announcements.archived-success'));
            // Reset the pagers to first page
            this.activePager.current.toFirstPage();
            this.archivedPager.current.toFirstPage();
        });
    }

    renderAnnouncementItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<AnnouncementListItem
                key={i._meta.href}
                priority={i.priority}
                message={i.message}
                active={i.active}
                timestamp={i.dateCreated}
                canArchive={i._meta.allow.includes('PATCH')}
                archiveFunction={() => this.archiveAnnoucement(i._meta.href)} />)
            });
        return listItems;
    }

    renderArchivedItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<AnnouncementListItem
                key={i._meta.href}
                priority={i.priority}
                message={i.message}
                active={i.active}
                timestamp={i.dateCreated}
                canArchive={false} />)
            }
        );
        return listItems;
    }

    render() {
        const {t} = this.props;

        const newAnnouncementForm = (
            <div className='setting-block new-announcement'>
                <div className='setting-block-header'>
                    <div className='setting-name'>{t('admin.announcements.form-new-title')}</div>
                    <div className='setting-description'>{t('admin.announcements.form-new-description')}</div>
                </div>
                <textarea className='setting-input-textarea' rows='5' cols='80'
                            name='newAnnouncementMessage'
                            value={this.state.newAnnouncementMessage}
                            onChange={this.handleInputChange} />
                <div className='setting-input-row'>
                    <ButtonBar options={[
                            {label: t('admin.announcements.level-info'), value: 'INFO'},
                            {label: t('admin.announcements.level-warn'), value: 'WARNING'},
                            {label: t('admin.announcements.level-critical'), value: 'CRITICAL'}
                        ]} onButtonBarClick={this.onButtonBarClick} />
                    <StdButton colorClass='positive' extraClass='announcement-post-button' onClick={this.createAnnouncement}>{t('admin.announcements.button-post')}</StdButton>
                </div>
            </div>
        );

        return (
            <div id='twopane-content' className='admin-page'>
                <AdminPageHeader
                    name={t('admin.announcements.page-title')}
                    description={t('admin.announcements.description')}
                    icon='bullhorn' />

                <div className='admin-page-content'>
                    {this.state.canPost ? newAnnouncementForm : null }
                    <div className='side-by-side-settings'>
                        <div className='setting-block'>
                            <div className='setting-block-header'>
                                <div className='setting-name'>{t('admin.announcements.table-active-title')}</div>
                                <div className='setting-description'>{t('admin.announcements.table-active-description')}</div>
                            </div>
                            <Pager className='announcement-list'
                                ref={this.activePager}
                                initialPage={`${this.props.baseHref}?active=true&per_page=5`}
                                renderItems={this.renderAnnouncementItems} />
                        </div>

                        <div className='setting-block'>
                            <div className='setting-block-header'>
                                <div className='setting-name'>{t('admin.announcements.table-archived-title')}</div>
                                <div className='setting-description'>{t('admin.announcements.table-archived-description')}</div>
                            </div>
                            <Pager className='announcement-list'
                                ref={this.archivedPager}
                                initialPage={`${this.props.baseHref}?active=false&per_page=5`}
                                renderItems={this.renderArchivedItems} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(Announcements));
