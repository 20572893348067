import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import Pager from '../../../Common/Pager/Pager';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import dayjs from 'dayjs';

import AdminPageHeader from '../AdminPageHeader';
import RecentProjectListItem from './RecentProjectListItem';
import UnmigratedOwnerListItem from './UnmigratedOwnerListItem';

import { API_HEADERS } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './Statistics.css';

class Statistics extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            statistics: null,
            netProjectsChartOptions: null,
            totalAnalysesChartOptions: null,
            percentAnalyzedChartOptions: null,
            ciStats: null,
            recentlyEnabledPage: null,
            unmigratedPage: null
        }

        this.loadStats = this.loadStats.bind(this);
        this.loadCiStats = this.loadCiStats.bind(this);
        this.updateChartData = this.updateChartData.bind(this);
        
        this.renderRecentlyEnabledItems = this.renderRecentlyEnabledItems.bind(this);
        this.renderUnmigratedItems = this.renderUnmigratedItems.bind(this);
    }

    componentDidMount() {
        this.loadStats();
    }

    async loadStats() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            const recentlyEnabledPage = json._meta.links
                .find((l) => l.rel === 'recent-projects')
                .href;
            const unmigratedPage = json._meta.links
                .find((l) => l.rel === 'unmigrated-owners')
                .href;
            const ciStatsHref = json._meta.links
                .find((l) => l.rel === 'ci-statistics')
                .href;
            this.setState({
                statistics: json,
                loading: false,
                recentlyEnabledPage,
                unmigratedPage
            }, () => {
                this.loadCiStats(ciStatsHref);
                this.updateChartData();
                this.loadUnmigrated();
            });
        });
    }

    async loadCiStats(ciStatsHref) {
        fetch(ciStatsHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                ciStats: json
            });
        });
    }

    async loadUnmigrated() {
        fetch(this.state.unmigratedPage, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                unmigratedItems: json.items,
                unmigratedPosition: json.position
            });
        });
    }
    
    renderRecentlyEnabledItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<RecentProjectListItem
                key={i._meta.href}
                provider={i.provider}
                ownerName={i.ownerName}
                projectName={i.projectName} 
                enabledDate={i.dateEnabled}
                lastScanDate={i.lastScanDate}
                primaryLanguage={i.primaryLanguage}
                />)
            }
        );
        return listItems;
    }
    
    renderUnmigratedItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<UnmigratedOwnerListItem
                key={i._meta.href}
                provider={i.provider}
                name={i.name}
                lastScanDate={i.lastScanDate}
                />)
            }
        );
        return listItems;
    }

    updateChartData() {
        const {t} = this.props;

        const netProjectsEnabled = this.state.statistics.netProjectsEnabled;
        const scansPerformed = this.state.statistics.scansPerformed;
        const percentageScanned = this.state.statistics.percentageScanned;

        const netProjectsDataset = [];
		for (let i = 0; i < netProjectsEnabled.length; i++) {
			netProjectsDataset[i] = netProjectsEnabled[i].value;
		}

		const scansPerformedDataset = [];
		for (let i = 0; i < scansPerformed.length; i++) {
			scansPerformedDataset[i] = scansPerformed[i].value;
		}

		const percentageScannedDataset = [];
        for (let i = 0; i < percentageScanned.length; i++) {
            percentageScannedDataset[i] = percentageScanned[i].value;
        }

        const netProjectsChartOptions = {
            credits: false,
            legend: false,
            chart: {
                height: 256,
                type: 'areaspline',
                style: {
                    fontFamily: 'Barlow, sans-serif'
                }
            },
            title: {
                text: null
            },
            yAxis: {
                title: {
                    text: t('admin.statistics.chart-net-enabled-y-label')
                },
                allowDecimals: false
            },
            xAxis: {
                title: {
                    text: t('admin.statistics.chart-weeks-x-label')
                },
                reversed: true,
                allowDecimals: false,
                tickInterval: 1
            },
            tooltip: {
                headerFormat: '',
                pointFormatter() {
                    const verb = '$t(admin.statistics.chart-net-enabled-tooltip-verb)';
                    if (this.x === 0) {
                        return t('admin.statistics.chart-weeks-tooltip-this', {count: this.y, verb});
                    } else if (this.x === 1) {
                        return t('admin.statistics.chart-weeks-tooltip-last', {count: this.y, verb});
                    }
                    return t('admin.statistics.chart-weeks-tooltip-ago', {count: this.y, verb, weeks: this.x});
                }
            },
            series: [{
                color: '#917898',
                fillOpacity: 0.3,
                lineWidth: 3,
                data: netProjectsDataset
            }]
        }

        const totalAnalysesChartOptions = {
            credits: false,
            legend: false,
            chart: {
                height: 256,
                type: 'areaspline',
                style: {
                    fontFamily: 'Barlow, sans-serif'
                }
            },
            title: {
                text: null
            },
            yAxis: {
                title: {
                    text: t('admin.statistics.chart-analyses-y-label')
                },
                allowDecimals: false
            },
            xAxis: {
                title: {
                    text: t('admin.statistics.chart-weeks-x-label')
                },
                reversed: true,
                allowDecimals: false,
                tickInterval: 1
            },
            tooltip: {
                headerFormat: '',
                pointFormatter() {
                    const verb = '$t(admin.statistics.chart-analyses-tooltip-verb)';
                    if (this.x === 0) {
                        return t('admin.statistics.chart-weeks-tooltip-this', {count: this.y, verb});
                    } else if (this.x === 1) {
                        return t('admin.statistics.chart-weeks-tooltip-last', {count: this.y, verb});
                    }
                    return t('admin.statistics.chart-weeks-tooltip-ago', {count: this.y, verb, weeks: this.x});
                }
            },
            series: [{
                color: '#917898',
                fillOpacity: 0.3,
                lineWidth: 3,
                data: scansPerformedDataset
            }]
        }

        const percentAnalyzedChartOptions = {
            credits: false,
            legend: false,
            chart: {
                height: 256,
                type: 'areaspline',
                style: {
                    fontFamily: 'Barlow, sans-serif'
                }
            },
            title: {
                text: null
            },
            yAxis: {
                title: {
                    text: t('admin.statistics.chart-percent-analyzed-y-label')
                },
                allowDecimals: false,
                min: 0,
                max: 100
            },
            xAxis: {
                title: {
                    text: t('admin.statistics.chart-weeks-x-label')
                },
                reversed: true,
                allowDecimals: false,
                tickInterval: 1
            },
            tooltip: {
                headerFormat: '',
                pointFormatter() {
                    const verb = '$t(admin.statistics.chart-percent-analyzed-tooltip-verb)';
                    if (this.x === 0) {
                        return t('admin.statistics.chart-weeks-tooltip-this', {count: this.y, verb});
                    } else if (this.x === 1) {
                        return t('admin.statistics.chart-weeks-tooltip-last', {count: this.y, verb});
                    }
                    return t('admin.statistics.chart-weeks-tooltip-ago', {count: this.y, verb, weeks: this.x});
                }
            },
            series: [{
                color: '#917898',
                fillOpacity: 0.3,
                lineWidth: 3,
                data: percentageScannedDataset
            }]
        }

        this.setState({
            netProjectsChartOptions,
            totalAnalysesChartOptions,
            percentAnalyzedChartOptions
        });
    }

    render() {
        const {t} = this.props;

        const ciSystemStats = [];
        if (this.state.ciStats) {
            this.state.ciStats.systemCounts.forEach(item => {
                ciSystemStats.push(
                    <div className='sub-setting' key={item.name}>
                        <div className='sub-setting-name'>{item.name}</div>
                        <div className='nps-field-value'>{item.count}</div>
                    </div>
                )
            })
        }

        let ciSystemStatsDuration = 0;
        if (this.state.ciStats) {
            const ciEndDate = dayjs(this.state.ciStats.endDate);
            ciSystemStatsDuration = ciEndDate.diff(this.state.ciStats.startDate, 'day');
        }

        return(
            this.state.loading ?
            <div id='twopane-content' className='twopane-loading'>
                <FontAwesomeIcon icon='spinner' className='fa-spin'/>
            </div>
            :
            <div id='twopane-content' className='admin-page feedback'>
                <AdminPageHeader
                    name={t('admin.statistics.page-title')}
                    description={t('admin.statistics.description')}
                    icon='bar-chart' />
                <div className='admin-page-content'>
                    { this.state.statistics ?
                        <React.Fragment>
                            <div className='setting-block'>
                                <div className='setting-block-header'>
                                    <div className='setting-name'>{t('admin.statistics.totals-title')}</div>
                                    <div className='setting-description'>{t('admin.statistics.totals-description')}</div>
                                </div>
                                <div className='row-fields total-counts'>
                                    <div className='sub-setting'>
                                        <div className='sub-setting-name'>{t('admin.statistics.totals-users')}</div>
                                        <div className='nps-field-value'>{this.state.statistics.userCount}</div>
                                    </div>
                                    <div className='sub-setting'>
                                        <div className='sub-setting-name'>{t('admin.statistics.totals-owners')}</div>
                                        <div className='nps-field-value'>{this.state.statistics.projectOwnerCount}</div>
                                    </div>
                                    <div className='sub-setting'>
                                        <div className='sub-setting-name'>{t('admin.statistics.totals-projects')}</div>
                                        <div className='nps-field-value'>{this.state.statistics.projectCount}</div>
                                    </div>
                                    <div className='sub-setting'>
                                        <div className='sub-setting-name'>{t('admin.statistics.totals-scans')}</div>
                                        <div className='nps-field-value'>{this.state.statistics.scanCount}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='setting-block'>
                                <div className='setting-block-header'>
                                    <div className='setting-name'>{t('admin.statistics.ci-usage-title')}</div>
                                    <div className='setting-description'>{t('admin.statistics.ci-usage-description', {count: ciSystemStatsDuration})}</div>
                                </div>
                                <div className='row-fields ci-systems'>
                                    {ciSystemStats}
                                </div>
                            </div>
                            <div className='stats-charts'>
                                <div className='setting-block'>
                                    <div className='setting-block-header'>
                                        <div className='setting-name'>{t('admin.statistics.chart-net-enabled-title')}</div>
                                        <div className='setting-description'>{t('admin.statistics.chart-net-enabled-description')}</div>
                                    </div>
                                    {this.state.netProjectsChartOptions ?
                                        <HighchartsReact highcharts={Highcharts} options={this.state.netProjectsChartOptions} />
                                        : null }
                                </div>
                                <div className='setting-block'>
                                    <div className='setting-block-header'>
                                        <div className='setting-name'>{t('admin.statistics.chart-analyses-title')}</div>
                                        <div className='setting-description'>{t('admin.statistics.chart-analyses-description')}</div>
                                    </div>
                                    {this.state.totalAnalysesChartOptions ?
                                        <HighchartsReact highcharts={Highcharts} options={this.state.totalAnalysesChartOptions} />
                                        : null }
                                </div>
                                <div className='setting-block'>
                                    <div className='setting-block-header'>
                                        <div className='setting-name'>{t('admin.statistics.chart-percent-analyzed-title')}</div>
                                        <div className='setting-description'>{t('admin.statistics.chart-percent-analyzed-description')}</div>
                                    </div>
                                    {this.state.percentAnalyzedChartOptions ?
                                        <HighchartsReact highcharts={Highcharts} options={this.state.percentAnalyzedChartOptions} />
                                        : null }
                                </div>
                            </div>

                            <div className='side-by-side-settings'>
                                <div className='setting-block'>
                                    <div className='setting-block-header'>
                                        <div className='setting-name'>{t('admin.statistics.table-recent-enabled-title')}</div>
                                        <div className='setting-description'>{t('admin.statistics.table-recent-enabled-description')}</div>
                                    </div>
                                    {this.state.recentlyEnabledPage ? 
                                        <Pager className='recently-enabled-list'
                                            initialPage={this.state.recentlyEnabledPage}
                                            renderItems={this.renderRecentlyEnabledItems} />
                                        : null }
                                </div>

                                <div className='setting-block'>
                                    <div className='setting-block-header'>
                                        <div className='setting-name'>{t('admin.statistics.table-unmigrated-title')}</div>
                                        <div className='setting-description'>{t('admin.statistics.table-unmigrated-description')}</div>
                                    </div>
                                    {this.state.unmigratedPage ? 
                                        <Pager className='unmigrated-list'
                                            initialPage={this.state.unmigratedPage}
                                            renderItems={this.renderUnmigratedItems} />
                                        : null }
                                </div>
                            </div>
                        </React.Fragment>
                    : null }
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(Statistics));
