import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StdButton from '../Common/StdButton/StdButton';
import { API_HEADERS } from '../../Constants';

import { apiHeadersContentXsrf } from '../../Constants';
import SupportedCIPlatforms from '../Common/Info/SupportedCIPlatforms';
import SupportedLanguages from '../Common/Info/SupportedLanguages';
import UserContext, { LoadUser, UserConsumer } from '../Common/Users/Users';

import sky from '../../images/landing/sky.svg';
import plane from '../../images/landing/plane.svg';
import bug from '../../images/landing/bug.svg';
import blackduck from '../../images/landing/blackduck.svg';
import gears from '../../images/landing/gears.svg';
import hexagons from '../../images/landing/hexagons.svg';
import iconList from '../../images/landing/icon_list.png';
import iconRisk from '../../images/landing/icon_risk.png';
import iconVuln from '../../images/landing/icon_vuln.png';

import './Landing.css'

class Landing extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingLoginMethods: true,
            loginMethods: [],
            siteInformation: null
        }
        this.loadLoginMethods = this.loadLoginMethods.bind(this);
        this.loadSiteInformation = this.loadSiteInformation.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
    }

    async componentDidMount() {        
        this.loadSiteInformation();
        this.loadLoginMethods();
    }

    async loadLoginMethods() {
        fetch(this.props.urlDirectory.loginMethodsUrl, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                loadingLoginMethods: false,
                loginMethods: json.items
            });
        });
    }

    async loadSiteInformation() {
        fetch(this.props.urlDirectory.serverInformation.infoUrl, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                siteInformation: json
            });
        });
    }

    async logoutUser(logoutUrl) {
        fetch(logoutUrl, {
            headers: apiHeadersContentXsrf(this.props.cookies),
            method: 'POST'
        })
        .then(() => {
            LoadUser(this.props.urlDirectory.userInfoUrl, this.context.setUser);
            this.props.history.push('/');
        });
    }

    render() {
        const {t} = this.props;

        let banners = [];
        if (this.state.siteInformation) {
            if (this.state.siteInformation.privateMode) {
                banners.push(
                    <a key='private-banner' href={this.state.siteInformation.productionUrl}>
                        <div className='banner private'>
                            <FontAwesomeIcon icon='info-circle' />
                            {t('landing.banners.private')}
                        </div>
                    </a>
                );
            }
            if (this.state.siteInformation.adminConfigurationRequired) {
                banners.push(
                    <div key='config-banner' className='banner config-required'>
                        <FontAwesomeIcon icon='triangle-exclamation' />
                        {t('landing.banners.config-required')}
                    </div>
                );
            }
        }

        return (
            <UserConsumer>{(context) => {
            const user = context.user;
            let loginMethods = null;
            if (user) {
                const homeUrl = user._meta.links
                                    .find((l) => l.rel === 'home')
                                    .href;
                const logoutUrl = user._meta.links
                                    .find((l) => l.rel === 'logout')
                                    .href;
                loginMethods =
                <div id='login-methods'>
                    <div className='login-cta'>
                        {t('landing.hero.logged-in', {provider: t(`general.login-providers.${user.loginProvider}`), username: user.login})}
                    </div>
                    <div className='login-buttons'>
                        <StdButton colorClass='primary' extraClass='login-method-button'
                            onClick={() => this.props.history.push(new URL(homeUrl).pathname)}>
                            <FontAwesomeIcon icon='laptop-code' />
                            <span>{t('landing.hero.app-btn')}</span>
                        </StdButton>
                        <StdButton colorClass='primary' extraClass='login-method-button'
                            onClick={() => this.logoutUser(logoutUrl)}>
                            <FontAwesomeIcon icon='door-open' />
                            <span>{t('landing.hero.log-out-btn')}</span>
                        </StdButton>
                    </div>
                </div>
            } else if (!this.state.loadingLoginMethods) {
                loginMethods =
                <div id='login-methods'>
                    <div className='login-cta'>{t('landing.hero.login-cta')}</div>
                    <div className='login-buttons'>
                        { this.state.loginMethods.map(item => {
                            return (
                                <StdButton key={item.loginProvider} colorClass={item.loginProvider} extraClass='login-method-button'
                                    onClick={(event) => window.location.href = item.login}>
                                    <FontAwesomeIcon icon={['fab', item._graphic.fontAwesomeIcon]} />
                                    <span>{t('landing.hero.login-btn', {loginMethod: item.name})}</span>
                                </StdButton>);
                          })
                        }
                    </div>
                </div>
            }

            return(
            <div id='landing'>
                <div id='landing-hero' style={{backgroundImage: `url(${sky})`}}>
                    <img id='hero-plane' alt='plane' src={plane} />
                    <div className='hero-header'>{t('landing.hero.header')}</div>
                    <div className='hero-description'>{t('landing.hero.description')}</div>

                    {loginMethods}

                    <div className='banners'>
                        {banners}
                    </div>
                </div>
                <div className='landing-section img-float-right'>
                    <div className='copy'>
                        <Trans i18nKey='landing.marketing-copy.p1'>
                            <strong></strong>
                        </Trans>
                    </div>
                    <img alt='bug' src={bug}/>
                </div>
                <div className='feature-highlight' style={{backgroundImage: `url(${hexagons})`}}>
                    <div className='feature-images'>
                        <img alt='list' src={iconList} />
                        <img alt='list' src={iconRisk} />
                        <img alt='list' src={iconVuln} />
                    </div>
                    <div className='feature-text'>
                        <div>{t('landing.marketing-copy.feature1')}</div>
                        <div>{t('landing.marketing-copy.feature2')}</div>
                        <div>{t('landing.marketing-copy.feature3')}</div>
                    </div>
                </div>
                <div className='landing-section img-float-left'>
                    <img alt='blackduck' src={blackduck}/>
                    <div className='copy'>
                        <Trans i18nKey='landing.marketing-copy.p2'>
                            <strong></strong>
                            <a target='_blank'
                                rel='noreferrer'
                                href='https://www.synopsys.com/software-integrity/security-testing/software-composition-analysis.html'>-</a>
                            <a target='_blank'
                                rel='noreferrer'
                                href='https://www.synopsys.com/software-integrity.html'>-</a>
                        </Trans>
                    </div>
                    <iframe title='CoPilot overview' width="560" height="315" src="https://www.youtube.com/embed/3nOYiaDszeo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                </div>
                <div className='landing-section img-float-right'>
                    <div className='copy'>
                        <Trans i18nKey='landing.marketing-copy.platforms'>
                            <strong />
                            <br/>
                        </Trans>
                        <div className='supported-platforms'>
                            {this.props.urlDirectory ?
                                <React.Fragment>
                                    <SupportedCIPlatforms baseHref={this.props.urlDirectory.setupInformation.integratedSystemsUrl} />
                                    <SupportedLanguages baseHref={this.props.urlDirectory.setupInformation.languagesUrl} />
                                </React.Fragment>
                            : null}
                        </div>
                    </div>
                    <img alt='gears' src={gears}/>
                </div>

            </div>
            )}}</UserConsumer>
        );
    }

}

export default withCookies(withRouter(withTranslation()(Landing)));
