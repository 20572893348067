import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';

import PrivateModeListItem from './PrivateModeListItem';
import AdminPageHeader from '../AdminPageHeader';
import Modal from '../../../Common/Modal/Modal';
import StdButton from '../../../Common/StdButton/StdButton';
import Pager from '../../../Common/Pager/Pager';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './PrivateMode.css';

class PrivateMode extends React.Component {

    constructor() {
        super();
        this.state = {
            warningModalOpen: false,
            privateMode: false,
            privateModeUserCount: 0,
            usersListHref: null,
            canPatch: false,
            newUsername: '',
            canAddUser: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.loadPrivateModeData = this.loadPrivateModeData.bind(this);
        this.setEnabledState = this.setEnabledState.bind(this);
        this.setWarningModalState = this.setWarningModalState.bind(this);
        this.addPrivateModeUser = this.addPrivateModeUser.bind(this);
        this.deletePrivateModeUser = this.deletePrivateModeUser.bind(this);

        this.renderPrivateModeListItems = this.renderPrivateModeListItems.bind(this);

        this.pager = React.createRef();
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyPress(event) {
        // if (this.state.canPat && event.key === 'Enter') {
        //     //this.updateKbSettings();
        // }
    }

    async componentDidMount() {
        this.loadPrivateModeData();
    }

    async loadPrivateModeData() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            const usersListHref = json._meta.links.find((l) => l.rel === 'privateModeUsers')
                .href;
            this.setState({
                privateMode: json.privateMode,
                privateModeUserCount: json.privateModeUserCount,
                canPatch: json._meta.allow.includes('PATCH'),
                usersListHref
            }, () => {
                fetch(this.state.usersListHref, {
                    headers: API_HEADERS
                })
                .then(result => result.json())
                .then(json => {
                    this.setState({
                        canAddUser: json._meta.allow.includes('POST')
                    });
                });
            });
        });
    }

    async setEnabledState(enabled) {
        const privateModeRequest = {
            privateMode: enabled
        }

        fetch(this.props.baseHref, {
            method: 'PATCH',
            body: JSON.stringify(privateModeRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            this.loadPrivateModeData();
            this.setWarningModalState(false);
        });
    }

    setWarningModalState(modalOpen) {
        this.setState({
            warningModalOpen: modalOpen
        });
    }

    async deletePrivateModeUser(href) {
        const {t} = this.props;

        fetch(href, {
            method: 'DELETE',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            const username = href.substring(href.lastIndexOf('/') + 1);
            toast.success(t('admin.private.revoked-success', {username}));
            this.pager.current.reloadCurrentPage()
        })
    }

    async addPrivateModeUser() {
        const {t} = this.props;

        const addUserRequest = {
            loginProvider: 'GITHUB',
            login: this.state.newUsername
        }

        fetch(this.state.usersListHref, {
            method: 'POST',
            body: JSON.stringify(addUserRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(result => {
            if (result.status !== 201) {
                throw new Error();
            }
        })
        .then(() => {
            toast.success(t('admin.private.granted-success', {username: this.state.newUsername}));
            this.setState({
                newUsername: ''
            });
            this.pager.current.toFirstPage();
        })
        .catch(err => {
            toast.error(t('admin.private.granted-failure'));
        });
    }

    renderPrivateModeListItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<PrivateModeListItem
                key={i._meta.href}
                loginProvider={i.loginProvider}
                login={i.login}
                canDelete={i._meta.allow.includes('DELETE')}
                onDeleteFunction={() => this.deletePrivateModeUser(i._meta.href)} />)
            }
        );
        return listItems;
    }

    render() {
        const {t} = this.props;
        const enabled = this.state.privateMode;

        const enableBlock = (
            <div className='setting-block'>
                <div className='setting-block-header'>
                    <div className='setting-name'>{t('admin.private.enable-title')}</div>
                    <div className='setting-description'>{t('admin.private.enable-description')}</div>
                </div>
                <StdButton extraClass='enable-disable-button' colorClass='positive' onClick={() => this.setWarningModalState(true)}>{t('admin.private.button-enable')}</StdButton>
            </div>);
        const disableBlock = (
            <div className='setting-block'>
                <div className='setting-block-header'>
                    <div className='setting-name'>{t('admin.private.disable-title')}</div>
                    <div className='setting-description'>{t('admin.private.disable-description')}</div>
                </div>
                <StdButton extraClass='enable-disable-button' colorClass='danger' onClick={() => this.setWarningModalState(true)}>{t('admin.private.button-disable')}</StdButton>
            </div>);

        const enableButton = (
            <StdButton key='enable-button' colorClass='positive' onClick={() => this.setEnabledState(true)}>{t('admin.private.enable-modal-button')}</StdButton>
        );
        const disableButton = (
            <StdButton key='disable-button' colorClass='danger' onClick={() => this.setEnabledState(false)}>{t('admin.private.disable-modal-button')}</StdButton>
        );

        return (
            <div id='twopane-content' className='admin-page'>
                <AdminPageHeader
                    name={t('admin.private.page-title')}
                    description={t('admin.private.description')}
                    icon={(enabled ? 'lock' : 'lock-open')} />

                {this.state.canPatch ?
                    <Modal open={this.state.warningModalOpen}
                        onClose={() => this.setWarningModalState(false)}
                        title={enabled ? t('admin.private.disable-title') : t('admin.private.enable-title')}
                        content={
                            enabled ?
                                <React.Fragment>
                                    <p>{t('admin.private.disable-modal-p1')}</p>
                                    <p>{t('admin.private.disable-modal-p2')}</p>
                                </React.Fragment> :
                                <React.Fragment>
                                    <p>{t('admin.private.enable-modal-p1')}</p>
                                    <p>{t('admin.private.enable-modal-p2')}</p>
                                </React.Fragment>
                        }
                        buttons={[
                            (enabled ? disableButton : enableButton),
                            <StdButton key='close-button' extraClassName='button' onClick={() => this.setWarningModalState(false)}>{t('general.cancel')}</StdButton>,
                        ]} />
                    : null}

                <div className='admin-page-content'>
                    {this.state.canPatch ?
                        (this.state.privateMode ? disableBlock : enableBlock)
                    : null}
                    {this.state.privateMode ?
                        <React.Fragment>
                            {this.state.canAddUser ?
                                <div className='setting-block'>
                                    <div className='setting-block-header'>
                                        <div className='setting-name'>{t('admin.private.add-user-title')}</div>
                                        <div className='setting-description'>{t('admin.private.add-user-description')}</div>
                                    </div>
                                    <div className='add-user-section'>
                                        <input className='setting-input-text' name='newUsername'
                                            value={this.state.newUsername}
                                            onChange={this.handleInputChange} />
                                        <StdButton colorClass='positive' onClick={this.addPrivateModeUser} disabled={!this.state.newUsername}>
                                            <FontAwesomeIcon icon='plus-circle' />
                                            {t('admin.private.button-add-user')}
                                        </StdButton>
                                    </div>
                                </div>
                            : null}
                                <div className='setting-block'>
                                    <div className='setting-block-header'>
                                        <div className='setting-name'>{t('admin.private.access-list-title')}</div>
                                        <div className='setting-description'>{t('admin.private.access-list-description')}</div>
                                    </div>
                                    <Pager className='private-mode-user-list'
                                        ref={this.pager}
                                        initialPage={`${this.state.usersListHref}?per_page=10`}
                                        renderItems={this.renderPrivateModeListItems} />
                                </div>
                            </React.Fragment>
                    : null}
                </div>
            </div>
        )
    }

}

export default withCookies(withTranslation()(PrivateMode));
