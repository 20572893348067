import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import AdminPageHeader from '../AdminPageHeader';

import AnalysisErrorListItem from './AnalysisErrorListItem';
import Pager from '../../../Common/Pager/Pager';

import { apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './AnalysisErrors.css';

class AnalysisErrors extends React.Component {

    constructor() {
        super();

        this.renderListItems = this.renderListItems.bind(this);
        this.deleteError = this.deleteError.bind(this);
        this.pager = React.createRef();
    }

    async deleteError(href) {
        const {t} = this.props;

        fetch(href, {
            method: 'DELETE',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.analysis-errors.deleted-success'));
            this.pager.current.reloadCurrentPage();
        });
    }

    renderListItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<AnalysisErrorListItem
                key={i._meta.href}
                timestamp={i.dateCreated}
                error={i.errorMessage}
                canDelete={i._meta.allow.includes('DELETE')}
                onDeleteFunction={() => this.deleteError(i._meta.href)} />);
            });
        return listItems;
    }

    render() {
        const {t} = this.props;

        return(
            <div id='twopane-content' className='admin-page analysis-errors'>
                <AdminPageHeader
                    name={t('admin.analysis-errors.page-title')}
                    description={t('admin.analysis-errors.description')}
                    icon='fire-extinguisher' />
                <div className='admin-page-content'>
                    <div className='setting-block'>
                        <Pager className='error-list'
                            ref={this.pager}
                            initialPage={`${this.props.baseHref}?per_page=10`}
                            renderItems={this.renderListItems}
                            handleError={() => {this.pager.current.toFirstPage()}} />
                    </div>
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(AnalysisErrors));
