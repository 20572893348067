import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';

import { API_HEADERS } from '../../Constants';
import Dropdown from '../Common/Dropdown/Dropdown';
import DropdownListItem from '../Common/Dropdown/DropdownListItem';

import { apiHeadersContentXsrf } from '../../Constants';
import UserContext, { LoadUser, UserConsumer } from '../Common/Users/Users';
import './TopNavigation.css'

class UserDropdown extends React.Component {

    static contextType = UserContext;

    constructor() {
        super();
        this.state = {
            open: false,
            loadingLoginMethods: true,
            loginMethods: [],
        }
        this.loadLoginMethods = this.loadLoginMethods.bind(this);
        this.setDropdownState = this.setDropdownState.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.consumerRender = this.consumerRender.bind(this);
    }

    componentDidMount() {
        // Load the currently logged in user as the page loads
        LoadUser(this.props.urlDirectory.userInfoUrl, this.context.setUser);
        this.loadLoginMethods();
    }

    async logoutUser(logoutUrl) {
        fetch(logoutUrl, {
            headers: apiHeadersContentXsrf(this.props.cookies),
            method: 'POST'
        })
        .then(() => {
            LoadUser(this.props.urlDirectory.userInfoUrl, this.context.setUser);
            this.props.history.push('/');
        });
    }

    async loadLoginMethods() {
        fetch(this.props.urlDirectory.loginMethodsUrl, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                loadingLoginMethods: false,
                loginMethods: json.items
            });
        });
    }

    setDropdownState(open) {
        this.setState({
            open
        });
    }

    consumerRender(context) {
        const {t} = this.props;

        const user = context.user;
        if (user) {
            const avatarUrl = user._meta.links
                                .find((l) => l.rel === 'avatar')
                                .href;
            const logoutUrl = user._meta.links
                                .find((l) => l.rel === 'logout')
                                .href;
            const homeUrl = user._meta.links
                                .find((l) => l.rel === 'home')
                                .href;
            const gitHubDropdownItems = [
                <DropdownListItem key='results' icon='cubes' label={t('top-nav.user-dropdown.results')} onClick={() => this.props.history.push(new URL(homeUrl).pathname)}/>,
                <DropdownListItem key='profile' icon='user-gear' label={t('top-nav.user-dropdown.settings')} onClick={() => this.props.history.push('/profile')}/>
            ]
            const blackduckDropdownItems = [
                <DropdownListItem key='admin' icon='hat-wizard' label={t('top-nav.user-dropdown.admin')} onClick={() => this.props.history.push(new URL(homeUrl).pathname)}/>,
            ]
            let dropdownItems;
            let loginSourceIcon;
            switch (user.loginProvider) {
            case 'GITHUB': loginSourceIcon = ['fab', 'github']; dropdownItems = gitHubDropdownItems; break;
            case 'BLACKDUCK': loginSourceIcon = 'feather'; dropdownItems = blackduckDropdownItems; break;
            default: loginSourceIcon = 'circle-question'; break;
            }

            return <div className={`top-nav-link user-dropdown-link ${this.state.open ? 'open' : ''}`} onClick={() => this.setDropdownState(!this.state.open)}>
                        <div className='top-nav-user-avatar' style={{backgroundImage: `url(${avatarUrl})`}}>
                            <FontAwesomeIcon className='top-nav-user-login-source' icon={loginSourceIcon} />
                        </div>
                        <div className='top-nav-link-name'>{user.login}</div>
                        <FontAwesomeIcon className='top-nav-link-caret' icon='caret-down' />
                        <Dropdown className='user-dropdown' open={this.state.open} handleOutsideClick={() => this.setDropdownState(false)}>
                            <div className='user-dropdown-header'>
                                {t('top-nav.user-dropdown.header', {provider: t(`general.login-providers.${user.loginProvider}`)})}
                            </div>
                            {[...dropdownItems,
                            <DropdownListItem key='logout' icon='door-open' label={t('top-nav.user-dropdown.logout')} onClick={() => this.logoutUser(logoutUrl)} />]}
                        </Dropdown>
                    </div>
        } else {
            const loginMethods = this.state.loginMethods.map(item => {
                return (
                    <DropdownListItem key={item.loginProvider} icon={['fab', item._graphic.fontAwesomeIcon]}
                        onClick={(event) => window.location.href = item.login} label={t('top-nav.login-dropdown-item', {loginMethod: item.name})} />);
                });

            return <div className={`top-nav-link user-dropdown-link ${this.state.open ? 'open' : ''}`} onClick={() => this.setDropdownState(!this.state.open)}>
                        <div className='top-nav-link-name'>{t('top-nav.login-dropdown')}</div>
                        <FontAwesomeIcon className='top-nav-link-caret' icon='caret-down' />
                        <Dropdown className='user-dropdown login-dropdown' open={this.state.open} handleOutsideClick={() => this.setDropdownState(false)}>
                            {loginMethods}
                        </Dropdown>
                   </div>
        }
    }

    render() {
        return(
            <UserConsumer>{
                this.consumerRender
            }</UserConsumer>
        );
    }

}

export default withCookies(withRouter(withTranslation()(UserDropdown)));
