import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import '../Results.css'

dayjs.extend(relativeTime);

class ResultContainerListItem extends React.Component {

    render() {
        return (
            <Link to={`${this.props.baseRoute}/${this.props.name}`} className={`result-container-item ${this.props.selected ? 'selected' : ''}`}
                 onClick={this.props.onItemClick}>
                    <div className='result-container-header'>
                        <span className='result-container-name'>{this.props.name}</span>
                        {this.props.analysisTimestamp ?
                            <span className='analysis-time'>{dayjs(this.props.analysisTimestamp).fromNow()}</span> :
                            null
                        }
                    </div>
                    {this.props.sourceBranchName && this.props.targetBranchName ?
                        <span className='pr-targeting'>{this.props.sourceBranchName} <FontAwesomeIcon icon='arrow-right-long' /> {this.props.targetBranchName}</span> :
                        null
                    }
            </Link>
        );
    }

}

export default ResultContainerListItem;
