import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import RejectedPRListItem from './RejectedPRListItem';
import AdminPageHeader from '../AdminPageHeader';
import Pager from '../../../Common/Pager/Pager';

import { API_HEADERS } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './AutoContribution.css';

class AutoContribution extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            loadingChart: true,
            setupStats: null,
            chartOptions: null,
            rejectionsBaseHref: ''
        }

        this.loadPageBackend = this.loadPageBackend.bind(this);
        this.loadStats = this.loadStats.bind(this);
        this.updateChartData = this.updateChartData.bind(this);
    }

    componentDidMount() {
        this.loadPageBackend();
    }

    async loadPageBackend() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            const rejectionsHref = json._meta.links
                .find((l) => l.rel === 'analysis-setup-rejections')
                .href;
            this.setState({
                loading: false,
                loadingChart: true,
                rejectionsBaseHref: rejectionsHref
            }, () => {
                const statsHref = json._meta.links
                    .find((l) => l.rel === 'analysis-setup-statistics')
                    .href;
                this.loadStats(statsHref);
            });
        });
    }

    async loadStats(statsHref) {
        fetch(statsHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                setupStats: json
            }, () => {
                this.updateChartData();
            });
        });
    }

    findCountByReaction(counts, reactionToFind) {
        const countStatus = counts.find((({reaction}) => reaction === reactionToFind));
        if (countStatus) {
            return countStatus.count;
        } else {
            return 0;
        }
    }

    updateChartData() {
        const {t} = this.props;
        const oneMonthCounts = this.state.setupStats.oneMonthCounts;
        const threeMonthCounts = this.state.setupStats.threeMonthCounts;
        const sixMonthCounts = this.state.setupStats.sixMonthCounts;

        const accepted = [];
        accepted[0] = this.findCountByReaction(oneMonthCounts, 'ACCEPTED');
        accepted[1] = this.findCountByReaction(threeMonthCounts, 'ACCEPTED');
        accepted[2] = this.findCountByReaction(sixMonthCounts, 'ACCEPTED');

        const rejected = [];
        rejected[0] = this.findCountByReaction(oneMonthCounts, 'REJECTED');
        rejected[1] = this.findCountByReaction(threeMonthCounts, 'REJECTED');
        rejected[2] = this.findCountByReaction(sixMonthCounts, 'REJECTED');

        const chartOptions = {
            credits: false,
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Barlow, sans-serif'
                }
            },
            xAxis: {
                categories: [
                    t('admin.auto-contribution.chart-group-1m'),
                    t('admin.auto-contribution.chart-group-3m'),
                    t('admin.auto-contribution.chart-group-6m')
                ]
            },
            yAxis: {
                min: 0,
                allowDecimals: false,
                title: {
                    text: t('admin.auto-contribution.chart-y-axis')
                }
            },
            title: {
                text: null
            },
            colors: ['var(--risk-clean)', 'var(--risk-high)'],
            series: [{
                name: t('admin.auto-contribution.chart-series-accepted'),
                data: accepted
            }, {
                name: t('admin.auto-contribution.chart-series-rejected'),
                data: rejected
            }]
        };

        this.setState({
            loadingChart: false,
            chartOptions
        });
    }

    renderRejectedItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<RejectedPRListItem
                key={i.pullRequest}
                responseTimestamp={i.responseTimestamp}
                pullRequest={i.pullRequest} />)
            }
        );
        return listItems;
    }

    render() {
        const {t} = this.props;
        return(
            this.state.loading ?
            <div id='twopane-content' className='twopane-loading'>
                <FontAwesomeIcon icon='spinner' className='fa-spin'/>
            </div>
            :
            <div id='twopane-content' className='admin-page auto-contribution'>
                <AdminPageHeader
                    name={t('admin.auto-contribution.page-title')}
                    description={t('admin.auto-contribution.description')}
                    icon='wand-magic-sparkles' />
                <div className='admin-page-content'>
                    <div className='setting-block acceptance-chart'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.auto-contribution.chart-title')}</div>
                            <div className='setting-description'>{t('admin.auto-contribution.chart-description')}</div>
                        </div>
                        {!this.state.loadingChart ?
                            <HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} />
                            : <FontAwesomeIcon icon='spinner' className='fa-spin'/> }
                    </div>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.auto-contribution.rejected-title')}</div>
                            <div className='setting-description'>{t('admin.auto-contribution.rejected-description')}</div>
                        </div>
                        {this.state.rejectionsBaseHref ?
                            <Pager className='rejections-list'
                                initialPage={this.state.rejectionsBaseHref}
                                renderItems={this.renderRejectedItems} />
                            : <FontAwesomeIcon icon='spinner' className='fa-spin'/> }
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(withCookies(withTranslation()(AutoContribution)));
