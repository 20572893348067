import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

class Announcement extends React.Component {

    render() {
        let icon;
        switch (this.props.priority) {
            case 'INFO': icon = 'circle-info'; break;
            case 'WARNING': icon = 'flag'; break;
            case 'CRITICAL': icon = 'circle-exclamation'; break;
            default: icon = 'circle-question'; break;
        }

        return (
            <div className='dropdown-announcement'>
                <div className='dropdown-announcement-header'>
                    <div className='announcement-icon'>
                        <FontAwesomeIcon icon={icon}/>
                    </div>
                    <span className='dropdown-announcement-time'>{dayjs(this.props.timestamp).format('MMM DD, YYYY - h:mm:ss A')}</span>
                </div>

                <div className='dropdown-announcement-message'>{parse(this.props.message)}</div>
            </div>
        );
    }

}

export default Announcement;
