import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ToggleSwitch.css';

class ToggleSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            working: false
        }
        this.onToggleClick = this.onToggleClick.bind(this);
    }

    async onToggleClick() {
        if (!this.props.disabled) {
            this.setState({
                working: true
            }, async () => {
                await (this.props.on ? this.props.toggleOffFunction() : this.props.toggleOnFunction());
                this.setState({
                    working: false
                });
            });
        }
    }

    render() {
        let icon = <FontAwesomeIcon icon='times' />;
        if (this.state.working) {
            icon = <FontAwesomeIcon icon='spinner' className='fa-spin'/>
        } else if (this.props.on) {
            icon = <FontAwesomeIcon icon='check' />;
        }

        return (
            <div className={`toggle-switch ${this.props.class ? this.props.class : ''} ${this.props.on ? 'on' : ''} ${this.props.disabled ? 'disabled' : ''}`}
                onClick={this.onToggleClick}>
                <div className='toggle-slider'>
                    {icon}
                </div>
            </div>
        );
    }

}

export default ToggleSwitch;
