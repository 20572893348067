import { API_HEADERS } from '../../../Constants';

import React, { useState } from 'react';

const UserContext = React.createContext({
    user: null,
    setUser: () => {}
});

async function loadUser(userHref, setUser) {
    // Fetch the currently active user
    const response = await fetch(userHref, {
        headers: API_HEADERS
    });
    if (!response.ok) {
        setUser(null);
    } else {
        const json = await response.json();
        setUser(json);
    }
}

export const UserProvider = (props) => {
    const [ user, setUser ] = useState();
    return (
        <UserContext.Provider value={{ user, setUser }}>
            {props.children}
        </UserContext.Provider>
    )
}

export const UserConsumer = (props) => {
    return (
        <UserContext.Consumer>
            {props.children}
        </UserContext.Consumer>
    )
}

export const LoadUser = loadUser;
export default UserContext;
