import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserContext, { UserConsumer } from '../../Common/Users/Users';
import Dropdown from '../../Common/Dropdown/Dropdown';
import DropdownListItem from '../../Common/Dropdown/DropdownListItem';

import './ChatInterface.css';

import genericAvatar from '../../../images/help/user-avatar.svg';

class ChoiceBubbles extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            alpha: 0,
            size: 0.9,
            dropdownOpen: false,
            dropdownChoice: null
        }
        this.setDropdownState = this.setDropdownState.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.chooseFromDropdown = this.chooseFromDropdown.bind(this);
    }

    componentDidMount() {
        setTimeout(() => this.setState({alpha: 1, size: 1}), 700);
    }


    setDropdownState(open) {
        this.setState({
            dropdownOpen: open
        });
    }

    openDropdown(e) {
        if (e.target === e.currentTarget) {
            this.setDropdownState(true);
        }
    }

    chooseFromDropdown(choice, pushChoiceFunction) {
        pushChoiceFunction(choice);
        this.setState({
            dropdownChoice: choice,
            dropdownOpen: false
        })
    }

    render() {
        const choices = [];
        this.props.options.forEach(option => {
            if (!option.dropdownChoices) {
                choices.push(
                    <div key={option.message} className={`chat-choice ${option.selected ? 'selected' : ''}`} onClick={option.onClick}>
                        {option.message}
                    </div>
                )
            } else {
                const dropdownChoices = [];
                option.dropdownChoices.forEach(choice => {
                    dropdownChoices.push(
                        <DropdownListItem key={choice._meta.href} label={choice.name} onClick={() => this.chooseFromDropdown(choice, option.onItemClick)}/>
                    )
                })
                choices.push(
                    <div key={'dropdown'} className={`chat-choice dropdown-choice ${option.selected ? 'selected' : ''}`} onClick={this.openDropdown}>
                        {this.state.dropdownChoice ? this.state.dropdownChoice.name : option.message}
                        <FontAwesomeIcon icon='caret-down' />
                        <Dropdown open={this.state.dropdownOpen} handleOutsideClick={() => this.setDropdownState(false)}>
                            {dropdownChoices}
                        </Dropdown>
                    </div>
                )
            }
        });
        return(<UserConsumer>{(context) => {
            let avatarHref = null;
            if (context.user) {
                avatarHref = context.user._meta.links
                            .find((l) => l.rel === 'avatar')
                            .href;
            }
            return(
                <div className='chat-message user' style={{opacity: this.state.alpha, transform: `scale(${this.state.size})`}}>
                    {choices}
                    <div className='chat-avatar' style={{backgroundImage: `url(${avatarHref ? avatarHref : genericAvatar})`}}></div>
                </div>
            );
        }}</UserConsumer>)
    }

}

export default ChoiceBubbles;
