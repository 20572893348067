import React from 'react';

import Pager from '../../Common/Pager/Pager';
import ComponentListItem from './ComponentListItem';

class ComponentList extends React.Component {

    constructor(props) {
        super(props);

        this.renderComponentItems = this.renderComponentItems.bind(this);
        this.componentPager = React.createRef();
    }

    renderComponentItems(items) {
        const listItems = [];
        items.forEach(item => {
            listItems.push(<ComponentListItem key={item._meta.href}
                    component={item}
                    selected={this.props.selectedComponentHref === item._meta.href}
                    href={item._meta.href}
                    clickFunction={this.props.onComponentClick}
                    containerRef={this.props.containerRef}
                    relativeState={item.relativeState ?? 'BASE'} />)
            }
        );
        return listItems;
    }

    render() {
        const hasParams = this.props.componentsHref.indexOf('?') !== -1;
        const initialPage = this.props.componentsHref + (hasParams ? '&' : '?') + 'per_page=15';

        return (
            <Pager className='component-list'
                ref={this.componentPager}
                initialPage={initialPage}
                renderItems={this.renderComponentItems} />
        );
    }

}

export default ComponentList;
