import React from 'react';
import { API_HEADERS } from '../../../Constants';

import { withTranslation } from 'react-i18next';

import './Info.css'

class SupportedCIPlatforms extends React.Component {

    constructor() {
        super();
        this.state = {
          items: [],
        };
        this.renderCiPlatformChips = this.renderCiPlatformChips.bind(this);
    }

    async componentDidMount() {
      fetch(this.props.baseHref, {
        headers: API_HEADERS
      })
        .then(result => result.json())
        .then(json => {
            this.setState({items: json.items});
        });
    }

    renderCiPlatformChips(items) {
        const listItems = [];
        items.forEach(item => {
            listItems.push(
                <div className='chip ci'
                    key={item._meta.href}>
                    {item.name}
                </div>
            )}
        );
        return listItems;
    }

    render() {
        const {t} = this.props;
        const chips = this.renderCiPlatformChips(this.state.items);

        return (
            <div className='info-ci-platforms'>
                <div className='info-header'>{t('info.ci-platforms')}</div>
                <div className='info-chips'>
                    { chips }
                </div>
            </div>
        );
    }

}

export default withTranslation()(SupportedCIPlatforms);
