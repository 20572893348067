import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../images/logo.svg';
import logoSmall from '../../images/logo-small.svg';


import Feedback from './Feedback';
import UserContext, { UserConsumer } from '../Common/Users/Users';
import AnnouncementsDropdown from './AnnouncementsDropdown';
import UserDropdown from './UserDropdown';
import './TopNavigation.css';

class TopNavigation extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            selection: this.props.location.pathname,
        };

        this.changeSelection = this.changeSelection.bind(this);
      }

    changeSelection(selection) {
        this.setState({selection});
    }

    render() {
        const {t} = this.props;
        return(
            <UserConsumer>{(context) => {
                let homepageLink = '/';
                let feedbackHref = '';
                if (context.user) {
                    const homepageHref = context.user._meta.links
                                        .find((l) => l.rel === 'home')
                                        .href;
                    homepageLink = new URL(homepageHref).pathname;
                    const feedbackLink = context.user._meta.links
                                        .find((l) => l.rel === 'feedback');
                    if (feedbackLink) {
                        feedbackHref = feedbackLink.href;
                    }
                }
                return(
                <React.Fragment>
                    <Feedback refSetter={this.props.refSetter} feedbackHref={feedbackHref}/>
                    <div id='top-navigation'>
                        <Link to={homepageLink}
                            className={`top-nav-logo ${'/' === this.state.selection ? 'selected' : ''}`}
                            onClick={() => this.changeSelection('/')}>
                            <img alt='Home' id='logo-large' src={logo} />
                            <img alt='Home' id='logo-small' src={logoSmall} />
                        </Link>
                        <AnnouncementsDropdown announcementsHref={'/announcements'} />
                        <div className='top-nav-right'>
                            {feedbackHref !== '' ?
                                <div className='top-nav-link feedback-link' onClick={() => this.props.feedback.current.setOpenState(true)}>
                                    <div className='top-nav-link-icon'>
                                        <FontAwesomeIcon icon='lightbulb'/>
                                    </div>
                                    <div className='top-nav-link-name'>{t('top-nav.feedback')}</div>
                                </div>
                            : null}
                            <UserDropdown urlDirectory={this.props.urlDirectory}/>
                        </div>
                    </div>
                </React.Fragment>
            )}}</UserConsumer>
        );
    }

}

export default withRouter(withTranslation()(TopNavigation));
