import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

class ConfigurationListItem extends React.Component {

    render() {
        return (
            <Link className={`configuration-list-item ${this.props.selected ? 'selected' : ''}`}
                  to={this.props.linkLocation}>
                <FontAwesomeIcon icon={this.props.icon}/>
                <span>{this.props.name}</span>
            </Link>
        );
    }

}

export default ConfigurationListItem;
