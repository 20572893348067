import React from 'react';
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { API_HEADERS } from '../../../Constants';

class UpgradeGuidance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        }

        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.href !== this.props.href) {
            this.setState({
                loading: true,
                data: null
            })
            this.loadData();
        }
    }

    async loadData() {
        fetch(this.props.href, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                loading: false,
                data: json
            });
        });
    }

    recRiskString(risk) {
        const {t} = this.props;
        switch (risk) {
        case 'HIGH': return t('results.inspector.risk-high');
        case 'MEDIUM': return t('results.inspector.risk-med');
        case 'LOW': return t('results.inspector.risk-low');
        case 'NEGLIGIBLE': return t('results.inspector.upgrade-guidance-clean');
        default: return t('results.inspector.risk-unknown');
        }
    }

    render() {
        const {t} = this.props;

        return (
            <div className='upgrade-guidance'>
                { this.state.loading ?
                    <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                    <React.Fragment>
                        { this.state.data.shortTerm ?
                            <div className={`upgrade-recommendation ${this.state.data.shortTerm.risk}`}>
                                <div className='recommendation-type'>
                                    {t('results.inspector.upgrade-guidance-short')}
                                </div>
                                <div className='recommendation-name'>
                                    <div className='recommendation-name-text'>
                                        {this.state.data.shortTerm.versionName}
                                    </div>
                                    <CopyToClipboard text={this.state.data.shortTerm.versionName}>
                                        <FontAwesomeIcon icon='clipboard' />
                                    </CopyToClipboard>
                                </div>
                                <div className='recommendation-risk'>
                                    {this.recRiskString(this.state.data.shortTerm.risk)}
                                </div>
                            </div> : null }
                        <div className={`upgrade-recommendation ${this.state.data.longTerm.risk}`}>
                            <div className='recommendation-type'>
                                {t('results.inspector.upgrade-guidance-long')}
                            </div>
                            <div className='recommendation-name'>
                                <div className='recommendation-name-text'>
                                    {this.state.data.longTerm.versionName}
                                </div>
                                <CopyToClipboard text={this.state.data.longTerm.versionName}>
                                    <FontAwesomeIcon icon='clipboard' />
                                </CopyToClipboard>
                            </div>
                            <div className='recommendation-risk'>
                                {this.recRiskString(this.state.data.longTerm.risk)}
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }

}

export default withTranslation()(UpgradeGuidance);
