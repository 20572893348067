import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import AdminPageHeader from '../AdminPageHeader';
import StdButton from '../../../Common/StdButton/StdButton';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './KnowledgeBase.css';

class KnowledgeBase extends React.Component {

    constructor() {
        super();
        this.state = {
            licenseKey: '',
            url: '',
            canPut: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.loadKbSettings = this.loadKbSettings.bind(this);
        this.updateKbSettings = this.updateKbSettings.bind(this);
    }

    async componentDidMount() {
        this.loadKbSettings();
    }

    async loadKbSettings() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                licenseKey: json.licenseKey,
                url: json.url,
                canPut: json._meta.allow.includes('PUT')
            });
        });
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyPress(event) {
        if (this.state.canPut && event.key === 'Enter') {
            this.updateKbSettings();
        }
    }

    async updateKbSettings() {
        const {t} = this.props;

        const kbRequest = {
            licenseKey: this.state.licenseKey,
            url: this.state.url
        }

        fetch(this.props.baseHref, {
            method: 'PUT',
            body: JSON.stringify(kbRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.kb.updated-success'));
            this.loadKbSettings();
        });
    }

    render() {
        const {t} = this.props;
        return (
            <div id='twopane-content' className='admin-page'>
                <AdminPageHeader
                    name={t('admin.kb.page-title')}
                    description={t('admin.kb.description')}
                    icon='book-atlas' />

                <div className='admin-page-content'>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.kb.server-url-title')}</div>
                            <div className='setting-description'>{t('admin.kb.server-url-description')}</div>
                        </div>
                        <input className='setting-input-text' name='url'
                            value={this.state.url}
                            onChange={this.handleInputChange}
                            disabled={!this.state.canPut} />
                    </div>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.kb.license-key-title')}</div>
                            <div className='setting-description'>{t('admin.kb.license-key-description')}</div>
                        </div>
                        <input className='setting-input-text' name='licenseKey'
                            value={this.state.licenseKey}
                            onChange={this.handleInputChange}
                            disabled={!this.state.canPut} />
                    </div>

                    {this.state.canPut ?
                        <StdButton colorClass='positive' extraClass='update-kb-button' onClick={this.updateKbSettings}>{t('general.button-update')}</StdButton>
                    : null}
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(KnowledgeBase));
