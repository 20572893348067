import React from 'react';
import { API_HEADERS } from '../../../Constants';

import { withTranslation } from 'react-i18next';

import './Info.css'

class SupportedLanguages extends React.Component {

    constructor() {
        super();
        this.state = {
          items: [],
        };
        this.renderLanguageChips = this.renderLanguageChips.bind(this);
    }

    async componentDidMount() {
      fetch(this.props.baseHref, {
        headers: API_HEADERS
      })
        .then(result => result.json())
        .then(json => {
            this.setState({items: json.items});
        });
    }

    renderLanguageChips(items) {
        const listItems = [];
        items.forEach(item => {
            listItems.push(
                <div className='chip language'
                    key={item._meta.href}>
                    {item.name}
                </div>
            )}
        );
        return listItems;
    }

    render() {
        const {t} = this.props;
        const chips = this.renderLanguageChips(this.state.items);

        return (
            <div className='info-languages'>
                <div className='info-header'>{t('info.languages')}</div>
                <div className='info-chips'>
                    { chips }
                </div>
            </div>
        );
    }

}

export default withTranslation()(SupportedLanguages);
