import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import AdminPageHeader from '../AdminPageHeader';
import PendingChangelogListItem from './PendingChangelogListItem';
import StdButton from '../../../Common/StdButton/StdButton';
import Pager from '../../../Common/Pager/Pager';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './ChangeLogs.css';

class ChangeLogs extends React.Component {

    constructor() {
        super();
        this.state = {
            canPost: false,
            newChangelogVersion: '',
            newChangelogHtml: ''
        }

        this.renderChangelogItems = this.renderChangelogItems.bind(this);

        this.stageChangelog = this.stageChangelog.bind(this);
        this.deleteChangelog = this.deleteChangelog.bind(this);
        this.releaseChangelog = this.releaseChangelog.bind(this);
        this.updateChangelog = this.updateChangelog.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);

        this.pager = React.createRef();
    }

    async componentDidMount() {
        // Although the lists are handled by the child Pager component, we still
        // need to make a call at this level to find out if we have POST permissions
        fetch(`${this.props.baseHref}?per_page=5`, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                canPost: json._meta.allow.includes('POST')
            });
        });
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async stageChangelog() {
        const {t} = this.props;

        const changelogRequest = {
            version: this.state.newChangelogVersion,
            changes: this.state.newChangelogHtml
        }

        fetch(this.props.baseHref, {
            method: 'POST',
            body: JSON.stringify(changelogRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.change-logs.staged-success'));
            this.setState({
                newChangelogVersion: '',
                newChangelogHtml: ''
            });
            this.pager.current.toFirstPage();
        });
    }

    async deleteChangelog(changelogHref) {
        const {t} = this.props;

        fetch(changelogHref, {
            method: 'DELETE',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.change-logs.deleted-success'));
            this.pager.current.reloadCurrentPage();
        });
    }

    async releaseChangelog(href) {
        const {t} = this.props;

        fetch(href, {
            method: 'PATCH',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.change-logs.released-success'));
            this.pager.current.toFirstPage();
        });
    }

    async updateChangelog(href, version, changes) {
        const changelogRequest = {
            version,
            changes
        }

        fetch(href, {
            method: 'PUT',
            body: JSON.stringify(changelogRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            this.pager.current.reloadCurrentPage();
        });
    }

    renderChangelogItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<PendingChangelogListItem
                key={i._meta.href}
                href={i._meta.href}
                version={i.version}
                changes={i.changes}
                canEdit={i._meta.allow.includes('PUT')}
                canDelete={i._meta.allow.includes('DELETE')}
                canRelease={i._meta.allow.includes('PATCH')}
                onDeleteFunction={() => this.deleteChangelog(i._meta.href)}
                onReleaseFunction={() => this.releaseChangelog(i._meta.href)}
                onUpdateFunction={this.updateChangelog} />)
            });
        return listItems;
    }

    render() {
        const {t} = this.props;

        const newChangelogForm = (
            <div className='setting-block new-changelog'>
                <div className='setting-block-header'>
                    <div className='setting-name'>{t('admin.change-logs.form-new-title')}</div>
                    <div className='setting-description'>{t('admin.change-logs.form-new-description')}</div>
                </div>
                <div className='sub-setting'>
                    <div className='sub-setting-name'>{t('admin.change-logs.form-new-field-version')}</div>
                    <input className='setting-input-text' name='newChangelogVersion'
                        value={this.state.newChangelogVersion}
                        onChange={this.handleInputChange} />
                </div>
                <div className='sub-setting'>
                    <div className='sub-setting-name'>{t('admin.change-logs.form-new-field-changelog')}</div>
                    <textarea className='setting-input-textarea' rows='5' cols='80'
                                name='newChangelogHtml'
                                value={this.state.newChangelogHtml}
                                onChange={this.handleInputChange} />
                </div>

                <StdButton colorClass='primary' extraClass='stage-changelog-button' onClick={this.stageChangelog}>{t('admin.change-logs.button-stage')}</StdButton>
            </div>
        );

        return (
            <div id='twopane-content' className='admin-page changelogs'>
                <AdminPageHeader
                    name='Change logs'
                    description='Manage outgoing change logs that will be visible to users on the Whats New page'
                    icon='ship' />
                <div className='admin-page-content'>
                    {this.state.canPost ? newChangelogForm : null }

                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.change-logs.table-pending-title')}</div>
                            <div className='setting-description'>{t('admin.change-logs.table-pending-description')}</div>
                        </div>
                        <Pager className='changelog-list'
                            ref={this.pager}
                            initialPage={`${this.props.baseHref}?per_page=5`}
                            renderItems={this.renderChangelogItems} />
                    </div>
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(ChangeLogs));
