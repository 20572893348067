import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Modal from '../../../Common/Modal/Modal';
import StdButton from '../../../Common/StdButton/StdButton';

import './AnalysisErrors.css'

dayjs.extend(relativeTime);

class AnalysisErrorListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }

        this.setOpenState = this.setOpenState.bind(this);
    }

    setOpenState(open) {
        this.setState({
            modalOpen: open
        });
    }

    render() {
        const {t} = this.props;

        return (
            <div className='analysis-error'>
                <Modal className='error-modal'
                    open={this.state.modalOpen}
                    onClose={() => this.setOpenState(false)}
                    title={t('admin.analysis-errors.full-error')}
                    content={
                        <div className='error-text'>
                            {this.props.error}
                        </div>
                    }
                    buttons={[
                        <StdButton key='close-button' extraClassName='button' onClick={() => this.setOpenState(false)}>{t('general.close')}</StdButton>,
                    ]} />
                <div className='analysis-error-header'>
                    <span className='timestamp'>{dayjs(this.props.timestamp).fromNow()}</span>
                    <StdButton colorClass='primary' extraClass='see-more-button' onClick={() => this.setOpenState(true)}>
                        <FontAwesomeIcon icon='search' /> {t('admin.analysis-errors.button-full-error')}
                    </StdButton>
                    {
                        this.props.canDelete ? <StdButton colorClass='danger' extraClass='changelog-delete-button'
                            onClick={this.props.onDeleteFunction}>
                            <FontAwesomeIcon icon='trash-alt' /> {t('admin.analysis-errors.button-delete')}
                        </StdButton> : null
                    }
                </div>
                <div className='error-text preview'>
                    {this.props.error}
                </div>
            </div>
        );
    }

}

export default withTranslation()(AnalysisErrorListItem);
