import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';

import AdminPageHeader from '../AdminPageHeader';
import NpsMeter from './NpsMeter';
import FeedbackListItem from './FeedbackListItem';
import Pager from '../../../Common/Pager/Pager';

import { API_HEADERS } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './Feedback.css';

class Feedback extends React.Component {

    constructor() {
        super();
        this.state = {
            netPromoterScore: 0,
            npsFields: null
        }

        this.renderFeedbackItems = this.renderFeedbackItems.bind(this);
        this.loadNetPromoterScore = this.loadNetPromoterScore.bind(this);
    }

    async componentDidMount() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            const npsHref = json._meta.links
                .find((l) => l.rel === 'nps')
                .href;
            this.loadNetPromoterScore(npsHref);
        });
    }

    async loadNetPromoterScore(npsHref) {
        fetch(npsHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                netPromoterScore: json.netPromoterScore,
                npsFields: json
            });
        });
    }

    renderFeedbackItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<FeedbackListItem
                key={i._meta.href}
                score={i.score}
                platform={i.loginPlatform}
                old={i.old}
                timestamp={i.dateCreated}
                email={i.contactEmail}
                message={i.comment} />)
            });
        return listItems;
    }

    render() {
        const {t} = this.props;

        return(
            <div id='twopane-content' className='admin-page feedback'>
                <AdminPageHeader
                    name={t('admin.feedback.page-title')}
                    description={t('admin.feedback.description')}
                    icon='comment' />
                <div className='admin-page-content'>
                    <div className='setting-block net-promoter-score'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.feedback.nps-title')}</div>
                            <div className='setting-description'>{t('admin.feedback.nps-description')}</div>
                        </div>
                        <div className='nps-all'>
                            <NpsMeter score={this.state.netPromoterScore} />
                            {this.state.npsFields ?
                                <div className='column-fields'>
                                    <div className='sub-setting'>
                                        <div className='sub-setting-name'>{t('admin.feedback.nps-detractors')}</div>
                                        <div className='nps-field-value'>{this.state.npsFields.detractors}</div>
                                    </div>
                                    <div className='sub-setting'>
                                        <div className='sub-setting-name'>{t('admin.feedback.nps-passives')}</div>
                                        <div className='nps-field-value'>{this.state.npsFields.passives}</div>
                                    </div>
                                    <div className='sub-setting'>
                                        <div className='sub-setting-name'>{t('admin.feedback.nps-promoters')}</div>
                                        <div className='nps-field-value'>{this.state.npsFields.promoters}</div>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.feedback.feedback-title')}</div>
                            <div className='setting-description'>{t('admin.feedback.feedback-description')}</div>
                        </div>
                        <Pager className='feedback-list'
                            ref={null}
                            initialPage='/blackduck/configuration/feedback/comments?per_page=5'
                            renderItems={this.renderFeedbackItems} />
                    </div>
                </div>
            </div>
        )
    }

}

export default withCookies(withTranslation()(Feedback));
