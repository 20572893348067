import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Legal.css';
import '../Common/StdButton/StdButton.css';

import { getTitlebarTitle } from '../../Constants';

class Legal extends React.Component {

    downloadDocument(document) {

    }

    render() {
        const { t } = this.props;
        return(
            <div id='legal'>
                <Helmet>
                    <title>{getTitlebarTitle(t('legal.page-title'), t)}</title>
                </Helmet>
                <a className='std-button primary download-button' download href='/documents/synopsys-copilot-terms-of-use.pdf'>
                    <FontAwesomeIcon icon='file-arrow-down' />
                    {t('legal.terms')}
                </a>
                <a className='std-button primary download-button' download href='/documents/copilot-notices.pdf'>
                    <FontAwesomeIcon icon='file-arrow-down' />
                    {t('legal.notices')}
                </a>
            </div>
        );
    }

}

export default withTranslation()(Legal);
