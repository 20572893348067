import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ButtonBar.css';

class ButtonBar extends React.Component {

    constructor(props) {
        super (props);
        this.state = {
            selectedValue: this.props.options[0].value
        }
        this.onButtonClick = this.onButtonClick.bind(this);
        this.setDefaultSelection = this.setDefaultSelection.bind(this);
    }

    componentDidMount() {
        this.setDefaultSelection();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setDefaultSelection();
        }
    }

    setDefaultSelection() {
        if (this.props.defaultValue) {
            this.onButtonClick(this.props.defaultValue);
        }
    }

    onButtonClick(value) {
        this.setState({ selectedValue: value });
        this.props.onButtonBarClick(value);
    }

    render() {
        let buttons = [];
        for (const [i, option] of this.props.options.entries()) {
            const optionSelected = (option.value === this.state.selectedValue);
            buttons.push(
                <div key={i} className={`button-bar-option ${optionSelected ? 'selected' : ''} ${option.disabled ? 'disabled' : ''}`}
                    onClick={() => {
                        if (!option.disabled) this.onButtonClick(option.value)
                    }}>
                    {option.icon ?
                        <FontAwesomeIcon icon={option.icon} /> : null
                    }
                    {option.label}
                </div>
            )
        }

        return(
            <div className={`button-bar ${this.props.className}`}>
                {buttons}
            </div>
        )
    }

}

export default ButtonBar;
