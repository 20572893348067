import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next'

import EmptyListHint from '../../Common/EmptyListHint/EmptyListHint';

import { getTitlebarTitle } from '../../../Constants';

import '../Admin.css'

class AdminLanding extends React.Component {

    render() {
        const {t} = this.props;

        return (
            <div id='twopane-content' className='admin-page'>
                <Helmet>
                    <title>{getTitlebarTitle(t('admin.landing.page-title'), t)}</title>
                </Helmet>

                <EmptyListHint icon='arrow-pointer' title={t('admin.landing.title')} text={t('admin.landing.text')}/>
            </div>
        );
    }

}

export default withTranslation()(AdminLanding);
