import React from 'react';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import './AutoContribution.css'

class RejectedPRListItem extends React.Component {

    render() {
        const {t} = this.props;

        const rejectedAt = dayjs(this.props.responseTimestamp).format('MMM DD, YYYY - h:mm:ss A');

        return (
            <div className='rejected-pr-item'>
                <a href={this.props.pullRequest} rel='noreferrer' target='_blank' className='std-button primary'>
                    {t('admin.auto-contribution.rejected-view')}
                </a>
                <span className='rejected-pr-time'>{t('admin.auto-contribution.rejected-at', {timestamp: rejectedAt})}</span>
            </div>
        );
    }

}

export default withTranslation()(RejectedPRListItem);
