import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';

import StdButton from '../../../Common/StdButton/StdButton';

import './ChangeLogs.css'

class PendingChangelogListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            editVersion: this.props.version,
            editHtml: this.props.changes
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const {t} = this.props;

        return (
            <div className='pending-changelog'>
                { !this.state.editing ?
                    // The non-edit-mode version of the list item
                    <React.Fragment>
                        <div className='changelog-header'>
                            {
                                this.props.canEdit ? <StdButton colorClass='primary' extraClass='changelog-edit-button'
                                    onClick={() => this.setState({ editing: true })}>
                                    <FontAwesomeIcon icon='pen-to-square' />
                                </StdButton> : null
                            }
                            <span className='changelog-version'>{this.props.version}</span>
                            {
                                this.props.canDelete ? <StdButton colorClass='danger' extraClass='changelog-delete-button'
                                    onClick={this.props.onDeleteFunction}>
                                    <FontAwesomeIcon icon='trash-alt' /> {t('admin.change-logs.button-delete')}
                                </StdButton> : null
                            }
                            {
                                this.props.canRelease ? <StdButton colorClass='positive' extraClass='changelog-release-button'
                                    onClick={this.props.onReleaseFunction}>
                                    <FontAwesomeIcon icon='paper-plane' /> {t('admin.change-logs.button-release')}
                                </StdButton> : null
                            }
                        </div>
                        <div className='changelog-changes'>{parse(this.props.changes)}</div>
                    </React.Fragment> :
                    // The edit-mode version of the list item
                    <React.Fragment>
                        <div className='changelog-header'>
                            {
                                <StdButton colorClass='primary' extraClass='changelog-edit-button'
                                    onClick={() => this.setState({
                                            editing: false,
                                            editVersion: this.props.version,
                                            editHtml: this.props.changes
                                        })}>
                                    <FontAwesomeIcon icon='rotate-left' />
                                </StdButton>
                            }
                            <input className='setting-input-text changelog-version changelog-edit-version'
                                    name='editVersion'
                                    value={this.state.editVersion}
                                    onChange={this.handleInputChange} />
                            {
                                <StdButton colorClass='positive' extraClass='changelog-save-button'
                                    onClick={() => {
                                        this.props.onUpdateFunction(this.props.href, this.state.editVersion, this.state.editHtml);
                                        this.setState({
                                            editing: false
                                        })
                                    }}>
                                    <FontAwesomeIcon icon='save' /> {t('admin.change-logs.button-save')}
                                </StdButton>
                            }
                        </div>
                        <textarea className='setting-input-textarea changelog-changes changelog-edit-changes' rows='5' cols='60'
                                    name='editHtml'
                                    value={this.state.editHtml}
                                    onChange={this.handleInputChange} />
                    </React.Fragment>
                }
            </div>
        );
    }

}

export default withTranslation()(PendingChangelogListItem);
