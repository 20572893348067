import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import './Feedback.css'

class FeedbackListItem extends React.Component {

    render() {
        let icon;
        switch (this.props.platform) {
            case 'GITHUB': icon = <FontAwesomeIcon icon={['fab', 'github']} />; break;
            default: icon = <FontAwesomeIcon icon='circle-question' />; break;
        }

        let scoreRange = 'neutral';
        if (this.props.score <= 6) {
            scoreRange = 'detractor';
        } else if (this.props.score >= 9) {
            scoreRange = 'promoter';
        }

        return (
            <div className={`feedback-item ${this.props.old ? 'old' : ''}`}>
                <div className='feedback-header'>
                    <div className={`score-bubble ${scoreRange}`}>{this.props.score}</div>
                    <div className='platform-icon'>{icon}</div>
                    <span className='feedback-user'>{this.props.email ? this.props.email : 'Anonymous'}</span>
                    <span className='feedback-time'>{dayjs(this.props.timestamp).format('MMM DD, YYYY')}</span>
                </div>

                <div className='feedback-message'>{this.props.message}</div>
            </div>
        );
    }

}

export default FeedbackListItem;
