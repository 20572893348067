import React from 'react';
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router';

import background from '../../images/results/first-time-setup.svg';

import { API_HEADERS } from '../../Constants';

import './Results.css'

class ResultsFirstTimeSetup extends React.Component {

    constructor(props) {
        super(props);
        this.pendingRepos = false;
        this.checkForGroups = this.checkForGroups.bind(this);
        
        if (this.props.location.search && this.props.location.search.includes('setup_action')) {
            // We have likely been redirected back here from GitHub. Refresh until we see repos.
            setInterval(this.checkForGroups, 5000);
            this.pendingRepos = true;
        }
    }
    
    async checkForGroups() {
        fetch(this.props.urlDirectory.scm.gitHubLandingUrl, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            if (json.items.length) {
                const noParamsLocation = window.location.href.split('?')[0];
                window.location = noParamsLocation;
            }
        })
    }

    render() {
        const {t} = this.props;
        return(
            <div id='results-first-time-setup' style={{backgroundImage: `url(${background})`}}>
                {this.pendingRepos ? 
                   <div className='first-time-box'>
                       <div className='first-time-title'>{t('results.first-time.pending-title')}</div>
                       <div className='first-time-text'>{t('results.first-time.pending-text')}</div>
                       <div className='first-time-pending-button-explain'>{t('results.first-time.pending-button-explain')}</div>
                       <a href={this.props.setupHref} className='std-button primary first-time-button'>{t('results.first-time.button')}</a>
                   </div> 
                :
                    <div className='first-time-box'>
                        <div className='first-time-title'>{t('results.first-time.title')}</div>
                        <div className='first-time-text'>{t('results.first-time.text')}</div>
                        <a href={this.props.setupHref} className='std-button primary first-time-button'>{t('results.first-time.button')}</a>
                    </div>
                }
            </div>
        );
    }

}

export default withRouter(withTranslation()(ResultsFirstTimeSetup));
