import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom';

import EmptyListHint from '../../Common/EmptyListHint/EmptyListHint';
import Dropdown from '../../Common/Dropdown/Dropdown';
import ResultContainerListItem from './ResultContainerListItem';

import AnalysisResults from '../AnalysisResults/AnalysisResults';

import { API_HEADERS } from '../../../Constants';
import { getTitlebarTitle } from '../../../Constants';

import '../Results.css'

class ResultsMainPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedProject: null,
            loadingBranches: true,
            loadingPrs: true,
            branchDropdownOpen: false,
            prDropdownOpen: false,
            selectedProjectBranches: null,
            selectedProjectPrs: null,
            selectedBranchName: '',
            selectedPrName: '',
            resultsHref: '',
            configuredProject: null
        }
        this.parseUrlPath = this.parseUrlPath.bind(this);
        this.selectLatest = this.selectLatest.bind(this);
        this.fetchProjectDetails = this.fetchProjectDetails.bind(this);
        this.fetchProjectBranchesAndPrs = this.fetchProjectBranchesAndPrs.bind(this);
        this.setBranchDropdownState = this.setBranchDropdownState.bind(this);
        this.setPrDropdownState = this.setPrDropdownState.bind(this);
        this.onSelectBranch = this.onSelectBranch.bind(this);
        this.onSelectPr = this.onSelectPr.bind(this);
        this.getBaseRoute = this.getBaseRoute.bind(this);
        this.mobileGoBack = this.mobileGoBack.bind(this);
    }

    selectLatest() {
        this.setState({
            selectedBranchName: '',
            selectedPrName: '',
            resultsHref: this.state.selectedProject.latestBranch
        });
    }

    setBranchDropdownState(branchDropdownOpen) {
        this.setState({
            branchDropdownOpen
        });
    }

    setPrDropdownState(prDropdownOpen) {
        this.setState({
            prDropdownOpen
        });
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.selectedProjectHref !== this.props.selectedProjectHref) {
            this.parseUrlPath();
        }
        window.onpopstate = (e) => {
            this.parseUrlPath();
        }
    }

    async componentDidMount() {
        this.parseUrlPath();
    }

    async parseUrlPath() {
        let newState = {
            loading: false,
            loadingBranches: true,
            loadingPrs: true,
            selectedProject: null,
            selectedProjectBranches: null,
            selectedProjectPrs: null,
            selectedBranchName: '',
            selectedPrName: '',
            resultsHref: ''
        };
        if (this.props.location.pathname) {
            const pathParts = this.props.location.pathname.split('/');
            if (pathParts.length >= 5) {
                const configuredProjectHref = `/github/repos/${pathParts[3]}/${pathParts[4]}`;
                this.fetchProjectDetails(configuredProjectHref);
                newState.loading = true;
            }
            if (pathParts.length >= 7) {
                if (pathParts[5] === 'branches') {
                    newState.selectedBranchName = pathParts[6];
                    newState.loading = true;
                } else if (pathParts[5] === 'pulls') {
                    newState.selectedPrName = pathParts[6];
                    newState.loading = true;
                }
            }
            this.setState(newState);
        }
    }

    async fetchProjectDetails(projectHref) {
        fetch(`${projectHref}/results`, {
            headers: API_HEADERS
        })
        .then(result => {
            if (result.status === 401) {
                throw new Error('Unauthorized');
            } else if (result.status === 302) {
                throw new Error('Redirect');
            } else {
                return result.json();
            }
        })
        .then(json => {
            const groupHref = json._meta.links
                .find((l) => l.rel === 'group')
                .href;
            this.props.sidebarStateSetter(groupHref, projectHref);
            let recentlyViewed = JSON.parse(localStorage.getItem('cptGitHubRecents') || '[]');
            // If we are already in the recently viewed list, delete before pushing to the end
            if (recentlyViewed.includes(projectHref)) {
                recentlyViewed.splice(recentlyViewed.indexOf(projectHref), 1);
            }
            recentlyViewed.push(projectHref);
            // truncate the list to the final 5 entries
            recentlyViewed.splice(0, recentlyViewed.length - 5);
            localStorage.setItem('cptGitHubRecents', JSON.stringify(recentlyViewed));
            // Manually trigger the storage event so our sidebar can update
            window.dispatchEvent(new Event('storage'));
            this.setState({
                loading: false,
                selectedProject: json
            }, () => {
                this.fetchProjectBranchesAndPrs();
                if (!(this.state.selectedBranchName || this.state.selectedPrName)) {
                    this.selectLatest();
                }
            });
        })
        .catch((error) => {
            if (error.message === 'Unauthorized' || error.message === 'Redirect') {
                this.props.notLoggedIn();
            } else {
                this.setState({
                    loading: false,
                    selectedProject: null
                });
            }
        });

        fetch(projectHref, {
            headers: API_HEADERS
        })
        .then(result => {
            if (result.status === 401) {
                throw new Error('Unauthorized');
            } else {
                return result.json();
            }
        })
        .then(json => {
            this.setState({
                configuredProject: json
            });
        })
        .catch((error) => {
            if (error.message === 'Unauthorized') {
                this.props.notLoggedIn();
            } else {
                this.setState({
                    configuredProject: null
                });
            }
        });
    }

    async fetchProjectBranchesAndPrs() {
        if (this.state.selectedProject !== null) {
            const branchesHref = this.state.selectedProject.branches;
            const prsHref = this.state.selectedProject.pullRequests;
            // Fetch the branch list for this project
            fetch(branchesHref, {
                headers: API_HEADERS
            })
            .then(result => {
                if (result.status === 401) {
                    throw new Error('Unauthorized');
                } else {
                    return result.json();
                }
            })
            .then(json => {
                this.setState({
                    loadingBranches: false,
                    selectedProjectBranches: json
                });
                if (this.state.selectedBranchName) {
                    json.items.forEach(b => {
                        if (b.name === this.state.selectedBranchName) {
                            this.onSelectBranch(b);
                        }
                    });
                }
            })
            .catch((error) => {
                this.props.notLoggedIn();
            });
            // Fetch the PR list for this project
            fetch(prsHref, {
                headers: API_HEADERS
            })
            .then(result => {
                if (result.status === 401) {
                    throw new Error('Unauthorized');
                } else {
                    return result.json();
                }
            })
            .then(json => {
                this.setState({
                    loadingPrs: false,
                    selectedProjectPrs: json
                });
                if (this.state.selectedPrName) {
                    json.items.forEach(p => {
                        if (p.name === this.state.selectedPrName) {
                            this.onSelectPr(p);
                        }
                    });
                }
            })
            .catch((error) => {
                this.props.notLoggedIn();
            });
        }
    }

    onSelectBranch(branch) {
        this.setState({
            selectedPrName: '',
            selectedBranchName: branch.name,
            resultsHref: branch._meta.href
        })
    }

    onSelectPr(pr) {
        this.setState({
            selectedPrName: pr.name,
            selectedBranchName: '',
            resultsHref: pr._meta.href
        })
    }

    getBaseRoute() {
        const pathParts = this.props.location.pathname.split('/');
        let basePath = '';
        if (pathParts.length >= 5) {
            basePath = `/github/repos/${pathParts[3]}/${pathParts[4]}`;
        }
        return basePath;
    }

    mobileGoBack() {
        this.setState({
            selectedProject: null
        }, this.props.unselectProject);
    }

    render() {
        const {t} = this.props;

        const noneSelected = 
            <React.Fragment>
                <EmptyListHint icon='arrow-pointer' title={t('results.no-project-selected-title')} text={t('results.no-project-selected-text')}/>
                <div className='bulk-setup-hint'>
                    <Trans i18nKey='results.no-project-bulk-setup-hint'>
                        <br></br>
                        <FontAwesomeIcon icon='gear' />
                        <FontAwesomeIcon icon='wand-magic-sparkles' />
                    </Trans>
                </div>
            </React.Fragment>;
        let content = noneSelected;
        const basePath = this.getBaseRoute();
        if (this.state.selectedProject !== null) {
            let branchList = <EmptyListHint title={t('results.no-branches-title')} text={t('results.no-branches-text')}/>;
            if (this.state.selectedProjectBranches !== null && this.state.selectedProjectBranches.position.totalElements !== 0) {
                branchList = [];
                this.state.selectedProjectBranches.items.forEach(b => {
                    branchList.push(<ResultContainerListItem
                        key={b._meta.href}
                        baseRoute={`${basePath}/branches`}
                        name={b.name}
                        analysisTimestamp={b.analysisLinkTimestamp}
                        onItemClick={() => this.onSelectBranch(b)}
                    />);
                });
            }
            let prList = <EmptyListHint title={t('results.no-prs-title')} text={t('results.no-prs-text')}/>;
            if (this.state.selectedProjectPrs !== null && this.state.selectedProjectPrs.position.totalElements !== 0) {
                prList = [];
                this.state.selectedProjectPrs.items.forEach(b => {
                    prList.push(<ResultContainerListItem
                        key={b._meta.href}
                        baseRoute={`${basePath}/pulls`}
                        name={b.name}
                        analysisTimestamp={b.analysisLinkTimestamp}
                        sourceBranchName={b.sourceBranchName}
                        targetBranchName={b.targetBranchName}
                        onItemClick={() => this.onSelectPr(b)}
                    />);
                });
            }

            const githubUrl = this.state.selectedProject._meta.links
                .find((l) => l.rel === 'github')
                .href;
            const latestSelected = !this.state.selectedBranchName && !this.state.selectedPrName;

            let pageTitle;
            if (this.state.selectedBranchName) {
                pageTitle = `${this.state.selectedProject.name} ${this.state.selectedBranchName}`;
            } else if (this.state.selectedPrName) {
                pageTitle = `${this.state.selectedProject.name} ${this.state.selectedPrName}`;
            } else {
                pageTitle = `${this.state.selectedProject.name} ${t('results.tab-latest')}`;
            }

            let setupHref = '';
            let pendingSetupHref = '';
            if (this.state.configuredProject) {
                const setupLink = this.state.configuredProject._meta.links.find((l) => l.rel === 'analysis-setup');
                const pendingSetupLink = this.state.configuredProject._meta.links.find((l) => l.rel === 'pending-analysis-setup');
                setupHref = setupLink ? setupLink.href : '';
                pendingSetupHref = pendingSetupLink ? pendingSetupLink.href : '';
            }

            const githubLink = <a className='github-link' target='_blank' rel='noreferrer' href={githubUrl}>
                                    {t('results.project-external-link', {sourceProvider: 'GitHub'})}
                                    <FontAwesomeIcon icon='arrow-up-right-from-square' />
                                </a>

            content =
            <div id='project-details'>
                <Helmet>
                    <title>{getTitlebarTitle(pageTitle, t)}</title>
                </Helmet>
                <div id='project-header'>
                    <div className='project-header-row'>
                        <div className='twopane-back-button' onClick={this.mobileGoBack}>
                            <FontAwesomeIcon icon='arrow-left' />
                        </div>
                        <h2>{this.state.selectedProject.name}</h2>
                        { this.state.selectedProject.fork ?
                            <span className='project-meta-bubble'><FontAwesomeIcon icon='code-fork'/> <span className='meta-bubble-text'>Fork</span></span> :
                            null
                        }
                        <div className='github-link-holder'>{githubLink}</div>
                    </div>
                    <div className='project-header-row mobile'>
                        <div className='github-link'>{githubLink}</div>
                    </div>
                    <div className='project-header-row'>
                        <span className='project-description'>{this.state.selectedProject.description}</span>
                    </div>
                </div>

                <div id='project-result-navigation'>
                    <Link to={basePath} className={`result-navigation-section ${latestSelected ? 'selected' : ''}`}
                         onClick={this.selectLatest}>
                        <FontAwesomeIcon icon='bolt'/>
                        <span className='section-label'>{t('results.tab-latest')}</span>
                    </Link>
                    <div className={`result-navigation-section ${this.state.branchDropdownOpen ? 'open' : ''} ${this.state.selectedBranchName ? 'selected' : ''}`}
                         onClick={() => this.setBranchDropdownState(!this.state.branchDropdownOpen)}>
                        <FontAwesomeIcon icon='code-branch'/>
                        <span className='section-label'>{this.state.selectedBranchName || t('results.tab-branch')}</span>
                        <FontAwesomeIcon icon={this.state.branchDropdownOpen ? 'caret-down' : 'caret-right'}/>
                        <Dropdown className='result-dropdown' open={this.state.branchDropdownOpen} handleOutsideClick={() => this.setBranchDropdownState(false)}>
                            {this.state.loadingBranches ?
                                <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                                branchList
                            }
                        </Dropdown>
                    </div>
                    <div className={`result-navigation-section ${this.state.prDropdownOpen ? 'open' : ''} ${this.state.selectedPrName ? 'selected' : ''}`}
                         onClick={() => this.setPrDropdownState(!this.state.prDropdownOpen)}>
                        <FontAwesomeIcon icon='code-pull-request'/>
                        <span className='section-label'>{this.state.selectedPrName || t('results.tab-pr')}</span>
                        <FontAwesomeIcon icon={this.state.prDropdownOpen ? 'caret-down' : 'caret-right'}/>
                        <Dropdown className='result-dropdown' open={this.state.prDropdownOpen} handleOutsideClick={() => this.setPrDropdownState(false)}>
                            {this.state.loadingBranches ?
                                <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                                prList
                            }
                        </Dropdown>
                    </div>
                </div>

                <AnalysisResults resultsHref={this.state.resultsHref}
                    containerRef={this.props.containerRef}
                    projectName={this.state.selectedProject.name}
                    setupHref={setupHref} pendingSetupHref={pendingSetupHref}
                    reloadProject={() => this.fetchProjectDetails(this.props.selectedProjectHref)}
                    notLoggedIn={this.props.notLoggedIn}
                    urlDirectory={this.props.urlDirectory}
                    feedback={this.props.feedback}/>
            </div>
        } else if (basePath) {
            content =
            <div className='project-not-found'>
                <h2>{t('results.project-not-found-title')}</h2>
                <div className='not-found-text'>{t('results.project-not-found-text')}</div>
            </div>
        }
        return (
            <React.Fragment>
                {this.state.loading ?
                    <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                    content
                }
            </React.Fragment>
        );
    }

}

export default withCookies(withRouter(withTranslation()(ResultsMainPanel)));
