import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import './Statistics.css'

class UnmigratedOwnerListItem extends React.Component {

    render() {
        const {t} = this.props;

        let icon;
        switch (this.props.provider) {
            case 'GITHUB': icon = ['fab', 'github']; break;
            default: icon = 'circle-question'; break;
        }

        return (
            <div className='recent-project'>
                <div className='recent-project-header'>
                    <FontAwesomeIcon icon={icon} />
                    {this.props.name}
                </div>
                <div className='recent-project-dates'>
                    {this.props.scanDate ?
                        <span>{t('admin.statistics.recent-project-analyzed', {date: dayjs(this.props.scanDate).format('MMM DD, YYYY')})}</span> :
                        <span>{t('admin.statistics.recent-project-not-analyzed')}</span>
                    }
                </div>
            </div>
        );
    }

}

export default withTranslation()(UnmigratedOwnerListItem);
