import React from 'react';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import AdminPageHeader from '../AdminPageHeader';
import StdButton from '../../../Common/StdButton/StdButton';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './AnalysisTool.css';

class AnalysisTool extends React.Component {

    constructor() {
        super();
        this.state = {
            scriptUrl: '',
            version: '',
            arguments: '',
            canPut: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.loadAnalysisToolSettings = this.loadAnalysisToolSettings.bind(this);
        this.updateAnalysisToolSettings = this.updateAnalysisToolSettings.bind(this);
    }

    async componentDidMount() {
        this.loadAnalysisToolSettings();
    }

    async loadAnalysisToolSettings() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                scriptUrl: json.script,
                version: json.version,
                arguments: json.arguments,
                canPut: json._meta.allow.includes('PUT')
            });
        });
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyPress(event) {
        if (this.state.canPut && event.key === 'Enter') {
            this.updateAnalysisToolSettings();
        }
    }

    async updateAnalysisToolSettings() {
        const {t} = this.props;

        const toolRequest = {
            script: this.state.scriptUrl,
            version: this.state.version,
            arguments: this.state.arguments
        }

        fetch(this.props.baseHref, {
            method: 'PUT',
            body: JSON.stringify(toolRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.analysis-tool.updated-success'));
            this.loadAnalysisToolSettings();
        });
    }

    render() {
        const {t} = this.props;
        return (
            <div id='twopane-content' className='admin-page'>
                <AdminPageHeader
                    name={t('admin.analysis-tool.page-title')}
                    description={t('admin.analysis-tool.description')}
                    icon='robot' />

                <div className='admin-page-content'>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.analysis-tool.script-url-title')}</div>
                            <div className='setting-description'>{t('admin.analysis-tool.script-url-description')}</div>
                        </div>
                        <input className='setting-input-text' name='scriptUrl'
                            value={this.state.scriptUrl}
                            onChange={this.handleInputChange}
                            disabled={!this.state.canPut} />
                    </div>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.analysis-tool.version-title')}</div>
                            <div className='setting-description'>{t('admin.analysis-tool.version-description')}</div>
                        </div>
                        <input className='setting-input-text' name='version'
                            value={this.state.version}
                            onChange={this.handleInputChange}
                            disabled={!this.state.canPut} />
                    </div>
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.analysis-tool.args-title')}</div>
                            <div className='setting-description'>{t('admin.analysis-tool.args-description')}</div>
                        </div>
                        <input className='setting-input-text' name='arguments'
                            value={this.state.arguments}
                            onChange={this.handleInputChange}
                            disabled={!this.state.canPut} />
                    </div>

                    {this.state.canPut ?
                        <StdButton colorClass='positive' extraClass='update-tool-button' onClick={this.updateAnalysisToolSettings}>{t('general.button-update')}</StdButton>
                    : null }
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(AnalysisTool));
