import React from 'react';
import { withTranslation } from 'react-i18next';

import Modal from '../../Common/Modal/Modal';
import StdButton from '../../Common/StdButton/StdButton';

import '../Results.css';

class RiskFactorsModal extends React.Component {

    getFactorText(enumValue) {
        switch(enumValue) {
            case 'HIGH_RISK_COMPONENT': return 'results.risk-factors.factor-high';
            case 'MEDIUM_RISK_COMPONENT': return 'results.risk-factors.factor-medium';
            case 'LOW_RISK_COMPONENT': return 'results.risk-factors.factor-low';
            case 'MULTIPLE_VULNERABILITIES': return 'results.risk-factors.factor-multi-vuln';
            case 'ANY_VULNERABILITIES': return 'results.risk-factors.factor-one-vuln';
            default: return 'unknown';
        }
    }

    render() {
        const {t} = this.props;

        let factors = [
            <div className='factor' key='INITIAL'>
                <div className='factor-bubble clean'>10</div>
                <div className='factor-text'>{t('results.risk-factors.score-initial')}</div>
            </div>
        ];
        for (let factor of this.props.securityResults.scoreMetrics) {
            factors.push(
                <div className='factor' key={factor.metric}>
                    <div className='factor-bubble high'>{factor.score}</div>
                    <div className='factor-text'>{t(this.getFactorText(factor.metric))}</div>
                </div>
            );
        }

        const content =
        <React.Fragment>
            <div className='modal-text'>{t('results.risk-factors.description')}</div>
            {factors}
            <div className='final-score'>
                <div className='final-score-numbers'>{this.props.securityResults.overallScore}/10</div>
                <div className='modal-text'>{t('results.risk-factors.score-final')}</div>
            </div>
            <div className='modal-text'>{t('results.risk-factors.address-risk')}</div>
        </React.Fragment>;

        return(
            <Modal className='modal-risk-factors'
                open={this.props.open}
                onClose={this.props.onClose}
                title={t('results.risk-factors.title')}
                content={content}
                buttons={[
                    <StdButton key='ok-button' extraClassName='button' onClick={this.props.onClose}>{t('general.ok')}</StdButton>
                ]} />
        );
    }

}

export default withTranslation()(RiskFactorsModal);
