import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import './EmptyListHint.css'

function EmptyListHint(props) {

    const {t} = useTranslation();

    return (
        <div className='empty-list-hint'>
            { !props.noIcon ?
                <FontAwesomeIcon icon={props.icon || 'box-open'} />
            : null }
            <div className='hint-text'>
                <div className='hint-title'>{props.title || t('general.empty-hint.title')}</div>
                <div className='hint-text'>{props.text || t('general.empty-hint.description')}</div>
            </div>
        </div>
    );

}

export default EmptyListHint;
