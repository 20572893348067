import React from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ChatInterface.css';

class ChatExampleLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alpha: 0,
            size: 0.9
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({alpha: 1, size: 1}), 400);
    }

    render() {
        const { t } = this.props;
        return (
            <a className='chat-message example-link' href={this.props.exampleLink} target='_blank' rel='noreferrer' style={{opacity: this.state.alpha, transform: `scale(${this.state.size})`}}>
                <div className='bubble'>
                    {t('help.chat.example-link', {scm: 'GitHub', 'ciSystem': this.props.ciName})}
                    <FontAwesomeIcon icon='arrow-up-right-from-square' />
                </div>
            </a>
        )
    }

}

export default withTranslation()(ChatExampleLink);
