import React from 'react';
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VulnerabilityBrowser from './VulnerabilityBrowser';
import UpgradeGuidance from './UpgradeGuidance';

import { API_HEADERS } from '../../../Constants';

class ComponentInspector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            componentDetails: null,
            yPos: '100px'
        }
        this.ref = React.createRef();
        this.calculateYPos = this.calculateYPos.bind(this);
    }

    async componentDidUpdate(prevProps) {
        if (this.props.componentHref && prevProps.componentHref !== this.props.componentHref) {
            fetch(this.props.componentHref, {
                headers: API_HEADERS
            })
            .then(result => result.json())
            .then(json => {
                this.setState({
                    componentDetails: json
                }, () => this.calculateYPos());
            });
        }
    }

    calculateYPos() {
        let maxY = this.props.maxYPos - 250;
        if (this.ref.current) {
            maxY -= this.ref.current.getBoundingClientRect().height;
        }
        let inspectorYPos = (this.props.yPos - 200);
        inspectorYPos = Math.max(100, inspectorYPos);
        inspectorYPos = Math.min(maxY, inspectorYPos);

        this.setState({
            yPos: `${inspectorYPos}px`
        });
    }

    render() {
        const {t} = this.props;

        let inspectorContent = <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/>;
        let inspectorRiskClass = 'UNKNOWN';
        if (this.state.componentDetails) {
            inspectorRiskClass = this.state.componentDetails.risk;
            let riskIcon;
            let riskText;
            switch (this.state.componentDetails.risk) {
                case 'HIGH': riskIcon = 'fire'; riskText = t('results.inspector.risk-high'); break;
                case 'MEDIUM': riskIcon = 'circle-exclamation'; riskText = t('results.inspector.risk-med'); break;
                case 'LOW': riskIcon = 'circle-dot'; riskText = t('results.inspector.risk-low'); break;
                case 'NEGLIGIBLE': riskIcon = 'circle-check'; riskText = t('results.inspector.risk-negligible'); break;
                default: riskIcon = 'circle-question-mark'; riskText = t('results.inspector.risk-unknown'); break;
            }
            let inspectorBody =
                <React.Fragment>
                    <div className='inspector-section-header'>{t('results.inspector.clean-title')}</div>
                    <div className='inspector-clean-message'>{t('results.inspector.clean-description')}</div>
                </React.Fragment>
            if (this.state.componentDetails.risk !== 'NEGLIGIBLE') {
                const vulnHref = this.state.componentDetails._meta.links
                            .find(l => l.rel === 'vulnerabilities')
                            .href;
                const upgradeGuidanceHref = this.state.componentDetails._meta.links
                            .find(l => l.rel === 'upgradeGuidance')
                            .href;
                inspectorBody =
                    <React.Fragment>
                        <div className='inspector-section-header'>{t('results.inspector.vulns-title')}</div>
                        <VulnerabilityBrowser baseHref={vulnHref} />
                        <div className='inspector-section-header'>{t('results.inspector.upgrade-guidance-title')}</div>
                        <UpgradeGuidance href={upgradeGuidanceHref} />
                    </React.Fragment>;
            }
            inspectorContent =
                <React.Fragment>
                    <div className={`inspector-header ${this.state.componentDetails.risk}`}>
                        <div className='component-logo' style={{backgroundImage: `url(${this.state.componentDetails.logo})`}}>
                            {!this.state.componentDetails.logo ?
                                <FontAwesomeIcon icon='puzzle-piece' /> : null
                            }
                        </div>
                        <div className='inspector-header-col'>
                            <div className='inspector-header-row'>
                                <div className='component-name'>{this.state.componentDetails.name}</div>
                                <div className='component-version'>{this.state.componentDetails.version}</div>
                            </div>
                            <div className='inspector-header-row'>
                                <div className='component-external-id'>{this.state.componentDetails.externalId}</div>
                            </div>
                            <div className='inspector-header-row component-risk'>
                                <div className='component-risk-icon'>
                                    <FontAwesomeIcon icon={riskIcon}/>
                                </div>
                                <span className='component-risk-text'>{riskText}</span>
                            </div>
                        </div>
                    </div>
                    <div className='inspector-body'>
                        {inspectorBody}
                    </div>
                </React.Fragment>
        }

        if (this.props.componentHref) {
            return (
                <div ref={this.ref} id='inspector' className={inspectorRiskClass} style={{top: this.state.yPos}}>
                    <div className='close-button' onClick={this.props.closeInspectorClick}>
                        <FontAwesomeIcon icon='xmark' />
                    </div>
                    {inspectorContent}
                </div>
            );
        } else {
            return null;
        }
    }

}

export default withTranslation()(ComponentInspector);
