import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import AdminPageHeader from '../AdminPageHeader';

import { API_HEADERS } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './Performance.css';

class Performance extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            performanceStats: null,
            chartOptions: null
        }

        this.loadStats = this.loadStats.bind(this);
        this.updateChartData = this.updateChartData.bind(this);
    }

    componentDidMount() {
        this.loadStats();
    }

    async loadStats() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                performanceStats: json,
                loading: false
            }, () => {
                this.updateChartData();
            });
        });
    }

    findAverageByStatus(averages, statusToFind) {
        const avgStatus = averages.find((({status}) => status === statusToFind));
        if (avgStatus) {
            return avgStatus.averageMilliseconds;
        } else {
            return 0;
        }
    }

    updateChartData() {
        const {t} = this.props;
        const oneMonthAverages = this.state.performanceStats.oneMonthAverages;
        const threeMonthAverages = this.state.performanceStats.threeMonthAverages;
        const sixMonthAverages = this.state.performanceStats.sixMonthAverages;

        const parsingFileData = [];
        parsingFileData[0] = this.findAverageByStatus(oneMonthAverages, 'PARSING_FILE');
        parsingFileData[1] = this.findAverageByStatus(threeMonthAverages, 'PARSING_FILE');
        parsingFileData[2] = this.findAverageByStatus(sixMonthAverages, 'PARSING_FILE');

        const matchingIdsData = [];
        matchingIdsData[0] = this.findAverageByStatus(oneMonthAverages, 'MATCHING_IDENTIFIERS');
        matchingIdsData[1] = this.findAverageByStatus(threeMonthAverages, 'MATCHING_IDENTIFIERS');
        matchingIdsData[2] = this.findAverageByStatus(sixMonthAverages, 'MATCHING_IDENTIFIERS');

        const aggregatingMatchesData = [];
        aggregatingMatchesData[0] = this.findAverageByStatus(oneMonthAverages, 'AGGREGATING_MATCHES');
        aggregatingMatchesData[1] = this.findAverageByStatus(threeMonthAverages, 'AGGREGATING_MATCHES');
        aggregatingMatchesData[2] = this.findAverageByStatus(sixMonthAverages, 'AGGREGATING_MATCHES');

        const generatingMetadataData = [];
        generatingMetadataData[0] = this.findAverageByStatus(oneMonthAverages, 'GENERATING_META_DATA');
        generatingMetadataData[1] = this.findAverageByStatus(threeMonthAverages, 'GENERATING_META_DATA');
        generatingMetadataData[2] = this.findAverageByStatus(sixMonthAverages, 'GENERATING_META_DATA');

        const finalizingData = [];
        finalizingData[0] = this.findAverageByStatus(oneMonthAverages, 'FINALIZING_RESULTS');
        finalizingData[1] = this.findAverageByStatus(threeMonthAverages, 'FINALIZING_RESULTS');
        finalizingData[2] = this.findAverageByStatus(sixMonthAverages, 'FINALIZING_RESULTS');

        const chartOptions = {
            credits: false,
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Barlow, sans-serif'
                }
            },
            xAxis: {
                categories: [
                    t('admin.performance.chart-timings-group-1m'),
                    t('admin.performance.chart-timings-group-3m'),
                    t('admin.performance.chart-timings-group-6m')
                ]
            },
            yAxis: {
                min: 0,
                title: {
                    text: t('admin.performance.chart-timings-x-description')
                }
            },
            title: {
                text: null
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            colors: ['#d5ddef', '#bcb8ce', '#917898', '#4c394f', '#2e1a1e'],
            series: [{
                name: t('admin.performance.chart-timings-key-finalizing'),
                data: finalizingData
            }, {
                name: t('admin.performance.chart-timings-key-metadata'),
                data: generatingMetadataData
            }, {
                name: t('admin.performance.chart-timings-key-aggregating'),
                data: aggregatingMatchesData
            }, {
                name: t('admin.performance.chart-timings-key-matching'),
                data: matchingIdsData
            }, {
                name: t('admin.performance.chart-timings-key-parsing'),
                data: parsingFileData
            }]
        };

        this.setState({ chartOptions });
    }

    render() {
        const {t} = this.props;
        return(
            this.state.loading ?
            <div id='twopane-content' className='twopane-loading'>
                <FontAwesomeIcon icon='spinner' className='fa-spin'/>
            </div>
            :
            <div id='twopane-content' className='admin-page feedback'>
                <AdminPageHeader
                    name={t('admin.performance.page-title')}
                    description={t('admin.performance.description')}
                    icon='stopwatch' />
                <div className='admin-page-content'>
                    <div className='setting-block stage-timings'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.performance.chart-timings-title')}</div>
                            <div className='setting-description'>{t('admin.performance.chart-timings-description')}</div>
                        </div>
                        {this.state.chartOptions ?
                            <HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} />
                            : null }
                    </div>
                </div>
            </div>
        );
    }

}

export default withCookies(withTranslation()(Performance));
