import React from 'react';
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import '../Results.css'

dayjs.extend(relativeTime);

class ProjectInfoPopover extends React.Component {

    render() {
        const {t} = this.props;

        const scanTime = this.props.lastScan ? dayjs(this.props.lastScan).fromNow() : null;

        return (
            <div className='project-info-popover'>
                <div className='popover-name'>
                    {this.props.projectName}
                    {this.props.fork ? <FontAwesomeIcon icon='code-fork' /> : null}
                </div>
                <div className='popover-scan-info'>
                    <FontAwesomeIcon icon='gear' />
                    {this.props.lastScan ?
                      t('results.sidebar.popover.scanned-on', {scanDate: scanTime})
                    : t('results.sidebar.popover.scanned-never')}
                </div>
                <div className='popover-description'>
                    {this.props.description}
                </div>
            </div>
        );
    }

}

export default withTranslation()(ProjectInfoPopover);
