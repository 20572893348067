import './Feedback.css';

function NpsMeter(props) {
    const percentage = (props.score + 100) / 200;
    const angle = (percentage * 180) - 90;
    const arrowStyle = {
        transform: `translateX(-50%) rotate(${angle}deg)`,
        transition: 'transform 1s'
    }

    return(
        <div className='nps-meter'>
			<div className='nps-arc-container'>
				<div className='nps-arc'>
					<div className='nps-arc-segment-low'></div>
					<div className='nps-arc-segment-med-l'></div>
					<div className='nps-arc-segment-high'></div>
					<div className='nps-arc-segment-med-r'></div>
				</div>
			</div>
			<div className='nps-arrow-container' style={arrowStyle}>
                <div className='nps-arrow'></div>
            </div>
			<div className='nps-score'>{props.score}</div>
		</div>
    );
}

export default NpsMeter;
