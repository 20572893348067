import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import './NotFound.css';
import '../Common/StdButton/StdButton.css';

import notFoundBg from '../../images/404-bg.svg';
import { getTitlebarTitle } from '../../Constants';

class NotFound extends React.Component {

    render() {
        const { t } = this.props;
        return(
            <div id='not-found' style={{backgroundImage: `url(${notFoundBg})`}}>
                <Helmet>
                    <title>{getTitlebarTitle(t('not-found.page-title'), t)}</title>
                </Helmet>
                <h1>{t('not-found.title')}</h1>
                <p>
                    <Trans i18nKey='not-found.text'><br/></Trans>
                </p>
                <Link to='/' className='std-button primary'>
                    {t('not-found.btn')}
                </Link>
            </div>
        );
    }

}

export default withRouter(withTranslation()(NotFound));
