import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import StdButton from '../../../Common/StdButton/StdButton';

import './CustomerAccounts.css'

class CustomerAccountListItem extends React.Component {

    render() {
        const {t} = this.props;

        const privateAllowedIcon = this.props.privateAllowed ? 'circle-check' : 'circle-xmark';
        const privateAllowedString = this.props.privateAllowed ? 'admin.customer-accounts.list-private-yes' : 'admin.customer-accounts.list-private-no';

        return (
            <div className='customer-account'>
               <div className='account-name'>{this.props.customerName}</div>
               <div className='private-allowed'>
                    <FontAwesomeIcon icon={privateAllowedIcon} />
                    {t(privateAllowedString)}
                </div>
                <div className='account-buttons'>
                    <StdButton onClick={this.props.openUpdateModal}><FontAwesomeIcon icon='gear' /></StdButton>
                    {this.props.canUpdate ?
                        <StdButton colorClass='danger' onClick={this.props.openDeleteModal}><FontAwesomeIcon icon='trash' /></StdButton> 
                    : null}
                </div>
            </div>
        );
    }

}

export default withTranslation()(CustomerAccountListItem);
