import React from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StdButton from '../../../Common/StdButton/StdButton';

import './PrivateMode.css'

class PrivateModeListItem extends React.Component {

    render() {
        const {t} = this.props;
        let loginSourceIcon;
        switch (this.props.loginProvider) {
        case 'GITHUB': loginSourceIcon = ['fab', 'github']; break;
        default: loginSourceIcon = 'circle-question'; break;
        }
        return (
            <div className='private-mode-user'>
                    <FontAwesomeIcon className='login-source-icon' icon={loginSourceIcon} />
                    <span>{this.props.login}</span>
                    {
                        this.props.canDelete ? <StdButton colorClass='danger' extraClass='private-mode-delete-button'
                            onClick={this.props.onDeleteFunction}>
                            <FontAwesomeIcon icon='user-slash' />
                            {t('admin.private.button-revoke-access')}
                        </StdButton> : null
                    }
            </div>
        );
    }

}

export default withTranslation()(PrivateModeListItem);
