import React from 'react';
import { withCookies } from 'react-cookie';
import { Trans, withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import AdminPageHeader from '../AdminPageHeader';
import ToggleSwitch from '../../../Common/ToggleSwitch/ToggleSwitch';
import StdButton from '../../../Common/StdButton/StdButton';
import Pager from '../../../Common/Pager/Pager';
import Modal from '../../../Common/Modal/Modal';

import CustomerAccountListItem from './CustomerAccountListItem';
import CustomerAccountUpdateModal from './CustomerAccountUpdateModal';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import '../../../Common/TwoPane/TwoPane.css'
import '../AdminPage.css';
import './CustomerAccounts.css';

class CustomerAccounts extends React.Component {

    constructor() {
        super();
        this.state = {
            canWrite: false,
            newCustomerName: '',
            newCustomerPrivateEnabled: false,
            deleteModalOpen: false,
            updateModalOpen: false,
            subjectAccount: '',
        }
        
        this.loadSettings = this.loadSettings.bind(this);
        this.createCustomerAccount = this.createCustomerAccount.bind(this);
        this.updateCustomerAccount = this.updateCustomerAccount.bind(this);
        this.deleteCustomerAccount = this.deleteCustomerAccount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderCustomerAccounts = this.renderCustomerAccounts.bind(this);
        this.setDeleteModalState = this.setDeleteModalState.bind(this);
        this.setUpdateModalState = this.setUpdateModalState.bind(this);
        
        this.pager = React.createRef();
    }

    async componentDidMount() {
        this.loadSettings();
    }
    
    async loadSettings() {
        fetch(this.props.baseHref, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                canWrite: json._meta.allow.includes('POST')
            });
        });
    }
    
    async createCustomerAccount() {
        const {t} = this.props;
        
        const customerRequest = {
            name: this.state.newCustomerName,
            allowPrivateRepositories: this.state.newCustomerPrivateEnabled
        }
        
        fetch(this.props.baseHref, {
            method: 'POST',
            body: JSON.stringify(customerRequest),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.customer-accounts.created-success'));
            // Reset the pager to first page
            this.pager.current.toFirstPage();
            // Reset form
            this.setState({
                newCustomerName: '',
                newCustomerPrivateEnabled: false
            });
        });
    }
    
    async updateCustomerAccount(href, request) {
        const {t} = this.props;
        
        fetch(href, {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.customer-accounts.updated-success'));
            // Reset the pager to first page
            this.pager.current.toFirstPage();
        });
    }
    
    async deleteCustomerAccount(accountHref) {
        const {t} = this.props;
        
        fetch(accountHref, {
            method: 'DELETE',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.customer-accounts.deleted-success'));
            // Reset the pager to first page
            this.pager.current.toFirstPage();
            this.setDeleteModalState(false, null);
        });
    }
    
    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    renderCustomerAccounts(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<CustomerAccountListItem
                key={i._meta.href}
                customerName={i.name}
                privateAllowed={i.allowPrivateRepositories}
                canUpdate={this.state.canWrite}
                openUpdateModal={() => this.setUpdateModalState(true, i)}
                openDeleteModal={() => this.setDeleteModalState(true, i)} />)
            }
        );
        return listItems;
    }
    
    setDeleteModalState(open, account) {
        this.setState({
            deleteModalOpen: open,
            subjectAccount: account
        })
    }
    
    setUpdateModalState(open, account) {
        this.setState({
            updateModalOpen: open,
            subjectAccount: account
        })
    }

    render() {
        const {t} = this.props;

        const newCustomerForm = (
            <div className='setting-block new-customer'>
                <div className='setting-block-header'>
                    <div className='setting-name'>{t('admin.customer-accounts.form-new-title')}</div>
                    <div className='setting-description'>{t('admin.customer-accounts.form-new-description')}</div>
                </div>
                
                <div className='sub-setting customer-name'>
                    <div className='sub-setting-name'>{t('admin.customer-accounts.form-new-name')}</div>
                    <input className='setting-input-text customer-name'
                                name='newCustomerName'
                                value={this.state.newCustomerName}
                                onChange={this.handleInputChange} />
                </div>
                <div className='sub-setting customer-private'>
                    <div className='sub-setting-name'>{t('admin.customer-accounts.form-new-private')}</div>
                    <ToggleSwitch
                        toggleOnFunction={() => this.setState({newCustomerPrivateEnabled: true})}
                        toggleOffFunction={() => this.setState({newCustomerPrivateEnabled: false})}
                        on={this.state.newCustomerPrivateEnabled} />
                </div>
                <StdButton colorClass='positive' extraClass='customer-create-button' onClick={this.createCustomerAccount}>
                    {t('admin.customer-accounts.button-create')}
                </StdButton>
            </div>
        );

        return(
            <div id='twopane-content' className='admin-page customer-accounts'>
                <AdminPageHeader
                    name={t('admin.customer-accounts.page-title')}
                    description={t('admin.customer-accounts.description')}
                    icon='address-book' />
                <div className='admin-page-content'>
                    {this.state.canWrite ?
                        <React.Fragment>
                            {newCustomerForm}
                            <Modal open={this.state.deleteModalOpen}
                                onClose={() => this.setDeleteModalState(false, null)}
                                title={t('admin.customer-accounts.delete-modal.title')}
                                content={
                                    this.state.subjectAccount ?
                                        <Trans i18nKey='admin.customer-accounts.delete-modal.text'>
                                            <strong>{{accountName: this.state.subjectAccount.name}}</strong>
                                            <br></br>
                                            <br></br>
                                        </Trans>
                                    : null
                                }
                                buttons={[
                                    <StdButton key='delete-button' colorClass='danger' onClick={() => this.deleteCustomerAccount(this.state.subjectAccount._meta.href)}>{t('admin.customer-accounts.delete-modal.button-delete')}</StdButton>,
                                    <StdButton key='close-button' extraClassName='button' onClick={() => this.setDeleteModalState(false, '', '')}>{t('general.cancel')}</StdButton>,
                                ]} />
                        </React.Fragment>
                    : null}
                    <CustomerAccountUpdateModal open={this.state.updateModalOpen} 
                        setUpdateModalState={this.setUpdateModalState}
                        accountHref={this.state.subjectAccount ? this.state.subjectAccount._meta.href : ''}
                        updateCustomerAccount={this.updateCustomerAccount}
                        canWrite={this.state.canWrite} />
                    <div className='setting-block'>
                        <div className='setting-block-header'>
                            <div className='setting-name'>{t('admin.customer-accounts.list-title')}</div>
                            <div className='setting-description'>{t('admin.customer-accounts.list-description')}</div>
                        </div>
                        <Pager className='customer-list'
                            ref={this.pager}
                            initialPage={this.props.baseHref}
                            renderItems={this.renderCustomerAccounts} />
                    </div>
                </div>
            </div>
        )
    }

}

export default withCookies(withTranslation()(CustomerAccounts));
