import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PrivatePage.css';

class PrivatePage extends React.Component {

    render() {
        if (this.props.loading) {
            return(
                <div className='loading-cover'>
                    <FontAwesomeIcon icon='spinner' className='fa-spin'/>
                </div>
            );
        } else if (this.props.authenticated === false) {
            if (this.props.reloadPage) {
                window.location.replace(this.props.redirectLocation);
            } else {
                return (
                    <Redirect to={{
                        pathname: this.props.redirectLocation,
                        state: { referrer: this.props.location.pathname }
                    }} />
                );
            }
        } else {
            return this.props.children;
        }
    }

}

export default withRouter(PrivatePage);
