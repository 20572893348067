import React from 'react';
import { withTranslation } from 'react-i18next';

import UserContext, { UserConsumer } from '../Common/Users/Users';
import { API_HEADERS } from '../../Constants';

import ChatInterface from './ChatInterface/ChatInterface';

class SetupConversation extends React.Component {

    static contextType = UserContext;

    constructor() {
        super();
        this.state = {
            languages: [],
            ciSystems: [],
            ciSetupInstructions: '',
            selectedLanguage: '',
            selectedCi: '',
            languagePlatforms: [],
            languageCiInstructions: '',
            exampleProjects: ''
        }
        this.fetchCiSetupInstructions = this.fetchCiSetupInstructions.bind(this);
        this.fetchLanguagePlatforms = this.fetchLanguagePlatforms.bind(this);
        this.fetchLanguageCiInstructions = this.fetchLanguageCiInstructions.bind(this);
    }

    componentDidMount() {
        // Load the languages dropdown choices
        fetch(this.props.urlDirectory.setupInformation.languagesUrl, {
          headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({languages: json.items});
        });

        // Load the CI system dropdown choices
        fetch(this.props.urlDirectory.setupInformation.integratedSystemsUrl, {
          headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({ciSystems: json.items});
        });
    }

    async fetchCiSetupInstructions(choice) {
        const href = choice._meta.links.find(link => link.rel === 'setup').href;
        fetch(href, {
              headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                selectedCi: choice.name,
                ciSetupInstructions: json.setupMarkdown,
                exampleProjects: json.exampleProjects
            });
        });
    }

    async fetchLanguagePlatforms(choice) {
        const href = choice._meta.links.find(link => link.rel === 'supported-ci').href;
        fetch(href, {
              headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                selectedLanguage: choice.name,
                languagePlatforms: json.items
            });
        });
    }

    async fetchLanguageCiInstructions(choice) {
        const href = choice._meta.links.find(link => link.rel === 'language-ci-setup').href;
        fetch(href, {
              headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            this.setState({
                selectedCi: choice.name,
                languageCiInstructions: json.setupMarkdown,
                exampleProjects: json.exampleProjects
            });
        });
    }

    render() {
        return(<UserConsumer>{(context) => {
            return(
                <ChatInterface conversationTree={this.conversationTree(context)} feedback={this.props.feedback} />
            )
        }}</UserConsumer>);
    }

    conversationTree(context) {
        const { t } = this.props;

        let missingCiResponse = {
            message: context.user ? t('help.chat.missing-ci-apology') : t('help.chat.missing-ci-apology-anonymous')
        }
        let missingLanguageResponse = {
            message: context.user ? t('help.chat.missing-language-apology') : t('help.chat.missing-language-apology-anonymous')
        }
        if (context.user) {
            missingCiResponse.options = [
                {
                    message: t('help.chat.send-feedback'),
                    optionType: "feedback",
                    feedbackTemplate: t('help.chat.feedback-template-ci')
                }
            ];
            missingLanguageResponse.options = [
                {
                    message: t('help.chat.send-feedback'),
                    optionType: "feedback",
                    feedbackTemplate: t('help.chat.feedback-template-language')
                }
            ];
        }

        return {
            message: t('help.chat.setup-welcome'),
            options: [
                {
                    optionType: "branch",
                    message: t('general.yes'),
                    choiceId: "yes",
                    response: {
                        message: t('help.chat.setup-ci-yes'),
                        options: [
                            {
                                optionType: "dropdown",
                                message: t('help.chat.ci-dropdown'),
                                choiceId: 'ci-dropdown',
                                dropdownChoices: this.state.ciSystems,
                                dropdownSelectFunction: this.fetchCiSetupInstructions,
                                response: {
                                    message: this.state.ciSetupInstructions,
                                    exampleLink: {
                                        link: this.state.exampleProjects,
                                        ciName: this.state.selectedCi
                                    }
                                }
                            },
                            {
                                optionType: "branch",
                                message: t('help.chat.ci-not-present'),
                                choiceId: "ci-not-listed",
                                response: missingCiResponse
                            }
                        ]
                    }
                },
                {
                    optionType: "branch",
                    message: t('general.no'),
                    choiceId: "no",
                    response: {
                        message: t('help.chat.setup-ci-no'),
                        options: [
                            {
                                optionType: "dropdown",
                                message: t('help.chat.language-dropdown'),
                                choiceId: 'language-dropdown',
                                dropdownChoices: this.state.languages,
                                dropdownSelectFunction: this.fetchLanguagePlatforms,
                                response: {
                                    message: t('help.chat.language-ci-platforms', {language: this.state.selectedLanguage}),
                                    options: [
                                        {
                                            optionType: "dropdown",
                                            message: t('help.chat.ci-dropdown'),
                                            choiceId: 'language-platform-dropdown',
                                            dropdownChoices: this.state.languagePlatforms,
                                            dropdownSelectFunction: this.fetchLanguageCiInstructions,
                                            response: {
                                                message: this.state.languageCiInstructions,
                                                exampleLink: {
                                                    link: this.state.exampleProjects,
                                                    ciName: this.state.selectedCi
                                                }
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                optionType: "branch",
                                message: t('help.chat.language-not-present'),
                                choiceId: "language-not-listed",
                                response: missingLanguageResponse
                            }
                        ]
                    }
                }
            ]
        };
    }

}

export default withTranslation()(SetupConversation);
