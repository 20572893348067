import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import "@fontsource/barlow";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import TopNavigation from './TopNavigation/TopNavigation';
import Footer from './Footer/Footer';
import Landing from './Landing/Landing';
import UserProfile from './UserProfile/UserProfile';
import WhatsNew from './WhatsNew/WhatsNew';
import Legal from './Legal/Legal';
import Health from './Health/Health';
import Help from './Help/Help';
import Results from './Results/Results';
import Admin from './Admin/Admin';
import NotFound from './NotFound/NotFound';

import { API_HEADERS } from '../Constants';

import { UserProvider } from './Common/Users/Users';

import './App.css';

library.add(fas, faGithub);

function App() {

    const [feedbackRef, setFeedbackRef] = useState('q');
    const [theme, setTheme] = useState(localStorage.getItem('cptTheme') || 'default');
    const [urlDirectory, setUrlDirectory] = useState(null);
    const { t } = useTranslation();

    window.addEventListener('storage', () => {
        const storageTheme = localStorage.getItem('cptTheme') || 'default';
        setTheme(storageTheme);
    })
    
    useEffect(() => {
        fetch('/', {
            headers: API_HEADERS
        })
        .then(results => results.json())
        .then(data => {
            setUrlDirectory(data);
        });
    }, []);

    return (
        urlDirectory ?
            <div className={`App theme-${theme}`}>
                <HelmetProvider>
                    <Helmet>
                        <title>{t('general.product-name-full')}</title>
                    </Helmet>
                    <Toaster
                        toastOptions={{
                            className: 'notify-toast'
                        }} />
                    <UserProvider>
                        <CookiesProvider>
                            <BrowserRouter>
                                <TopNavigation refSetter={setFeedbackRef} feedback={feedbackRef} urlDirectory={urlDirectory}/>
                                <div id="content">
                                    <Switch>
                                        <Route path='/' exact={true}><Landing urlDirectory={urlDirectory} /></Route>
                                        <Route path='/profile'><UserProfile baseHref={urlDirectory.userInfoUrl} /></Route>
                                        <Route path='/whats-new'><WhatsNew baseHref={urlDirectory.serverInformation.changeLogUrl} /></Route>
                                        <Route path='/legal' component={Legal} />
                                        <Route path='/health'><Health baseHref={urlDirectory.serverInformation.healthCheckUrl} /></Route>
                                        <Route path='/help'><Help feedback={feedbackRef} urlDirectory={urlDirectory} /></Route>
                                        <Route path='/github/repos'><Results feedback={feedbackRef} urlDirectory={urlDirectory} /></Route>
                                        <Route path='/blackduck' exact={true}><Admin urlDirectory={urlDirectory} /></Route>
                                        <Route path='/blackduck/configuration'><Admin urlDirectory={urlDirectory} /></Route>
                                        <Route path='*' component={NotFound} />
                                    </Switch>
                                </div>
                                <Footer />
                            </BrowserRouter>
                        </CookiesProvider>
                    </UserProvider>
                </HelmetProvider>
            </div>
        : "Loading"
    );
}

export default App;
