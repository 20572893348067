import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { withCookies } from 'react-cookie';
import toast from 'react-hot-toast';

import { apiHeadersContentXsrf } from '../../../Constants';

import Modal from '../../Common/Modal/Modal';
import StdButton from '../../Common/StdButton/StdButton';

import '../Results.css';

class BulkAutoSetupModal extends React.Component {

    constructor(props) {
        super(props);

        this.runAutoSetup = this.runAutoSetup.bind(this);
    }

    async runAutoSetup() {
        const {t} = this.props;

        return fetch(this.props.bulkSetupHref, {
            method: 'POST',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(result => result.json())
        .then(json => {
            const filedCount = json.configurationCounts['PULL_REQUEST_FILED'];
            const previousCount = json.configurationCounts['PULL_REQUEST_PREVIOUSLY_FILED'];
            const presentCount = json.configurationCounts['CONFIGURATION_ALREADY_PRESENT'];
            const insufficientPermissionCount = json.configurationCounts['INSUFFICIENT_PERMISSIONS'];
            const unsupportedCount = json.configurationCounts['UNSUPPORTED'];

            let messages = [];
            if (filedCount) {
                messages.push(t('results.bulk-auto-setup.toast-open-count', {count: filedCount}));
            }
            if (previousCount) {
                messages.push(t('results.bulk-auto-setup.toast-previous-count', {count: previousCount}));
            }
            if (presentCount) {
                messages.push(t('results.bulk-auto-setup.toast-already-configured-count', {count: presentCount}));
            }
            if (insufficientPermissionCount) {
                messages.push(t('results.bulk-auto-setup.toast-insufficient-count', {count: insufficientPermissionCount}));
            }
            if (unsupportedCount) {
                messages.push(t('results.bulk-auto-setup.toast-unsupported-count', {count: unsupportedCount}));
            }

            if (filedCount || previousCount || presentCount) {
                toast.success(messages.join('\n'), {duration: 5000});
            } else {
                toast.error(messages.join('\n'), {duration: 5000});
                toast.error(t('results.bulk-auto-setup.toast-failure'), {duration: 5000});
            }
            this.props.onClose();
        });
    }

    render() {
        const {t} = this.props;

        const content =
        <React.Fragment>
            <div className='modal-text'>
                <Trans i18nKey='results.bulk-auto-setup.text' values={{groupName:this.props.groupName, sourceProvider:'GitHub'}}>
                    <br/>
                    <br/>
                    <b></b>
                </Trans>
            </div>

        </React.Fragment>;

        return(
            <Modal className='modal-bulk-auto-setup'
                open={this.props.open}
                onClose={this.props.onClose}
                title={t('results.bulk-auto-setup.title')}
                content={content}
                buttons={[
                    <StdButton key='run-button' colorClass='primary' extraClassName='button' onClick={this.runAutoSetup}>{t('results.bulk-auto-setup.button-go')}</StdButton>,
                    <StdButton key='close-button' extraClassName='button' onClick={this.props.onClose}>{t('general.close')}</StdButton>
                ]} />
        );
    }

}

export default withCookies(withTranslation()(BulkAutoSetupModal));
