import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { withTranslation, Trans } from 'react-i18next'
import { withCookies } from 'react-cookie';
import toast from 'react-hot-toast';

import StdButton from '../../Common/StdButton/StdButton';
import SetupConversation from '../../Help/SetupConversation';
import Dropdown from '../../Common/Dropdown/Dropdown';
import DropdownListItem from '../../Common/Dropdown/DropdownListItem';
import ButtonBar from '../../Common/ButtonBar/ButtonBar';
import { API_HEADERS, apiHeadersContentXsrf } from '../../../Constants';
import { getTitlebarTitle } from '../../../Constants';

import ComponentList from './ComponentList';
import ComponentInspector from './ComponentInspector';
import RiskFactorsModal from './RiskFactorsModal';
import RiskBadgeModal from './RiskBadgeModal';
import '../Results.css'

dayjs.extend(relativeTime);

class AnalysisResults extends React.Component {

    constructor() {
        super();
        this.state = {
            loadingResults: true,
            loadingSecurityResults: true,
            results: null,
            securityResults: null,
            resultComparison: null,
            resultsOverflowOpen: false,
            modalRiskFactorsOpen: false,
            modalRiskBadgeOpen: false,
            componentsHref: '',
            selectedComponentHref: '',
            selectedComponentYPos: 0,
            containerMaxHeight: 0,
            viewManualSetup: false,
            awaitResultsTimeoutId: null
        }
        this.onComponentClick = this.onComponentClick.bind(this);
        this.closeInspector = this.closeInspector.bind(this);

        this.loadResults = this.loadResults.bind(this);
        this.loadSecurityResults = this.loadSecurityResults.bind(this);
        this.loadResultComparison = this.loadResultComparison.bind(this);

        this.triggerAutoSetup = this.triggerAutoSetup.bind(this);

        this.setResultsOverflowDropdownState = this.setResultsOverflowDropdownState.bind(this);
    }

    onComponentClick(componentHref, componentYPos, containerMaxHeight) {
        this.setState({
            selectedComponentHref: componentHref,
            selectedComponentYPos: componentYPos,
            containerMaxHeight: containerMaxHeight
        });
    }

    closeInspector() {
        this.setState({
            selectedComponentHref: ''
        });
    }

    async componentDidMount() {
        this.loadResults();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.resultsHref !== this.props.resultsHref ||
            prevProps.pendingSetupHref !== this.props.pendingSetupHref) {
            this.setState({
                loadingResults: true,
                loadingSecurityResults: true,
                results: null,
                securityResults: null,
                resultComparison: null,
                componentsHref: '',
                selectedComponentHref: '',
                selectedComponentYPos: 0,
                containerMaxHeight: 0
            }, () => {
                this.loadResults();
            });
        }
    }

    componentWillUnmount() {
        if (this.state.awaitResultsTimeoutId !== null) {
            clearTimeout(this.state.awaitResultsTimeoutId);
        }
    }

    async loadResults() {
        if (this.props.resultsHref) {
            fetch(this.props.resultsHref, {
                headers: API_HEADERS,
                redirect: 'error'
            })
            .then(response => {
                if (!response.ok) {
                    throw response.statusCode;
                }
                return response;
            })
            .then(result => result.json())
            .then(json => {
                let timeoutId = null;
                if (json.analysisRunning) {
                    timeoutId = setTimeout(this.loadResults, 5000);
                }
                this.setState({
                    loadingResults: false,
                    loadingSecurityResults: true,
                    results: json,
                    componentsHref: json.components,
                    awaitResultsTimeoutId: timeoutId
                }, () => {
                    const securityResultsHref = this.state.results._meta.links
                        .find((l) => l.rel === 'security')
                        .href;
                    this.loadSecurityResults(securityResultsHref);
                    const comparisonLink = this.state.results._meta.links
                        .find((l) => l.rel === 'result-comparison');
                    if (comparisonLink) {
                        // Pull request, load result comparison data
                        this.loadResultComparison(comparisonLink.href);
                    }
                });
            })
            .catch(error => {
                if (error && error.message.includes('Failed to fetch')) {
                    // Redirect from the backend means we need to do our manual redirect on the frontend
                    this.props.notLoggedIn();
                } else {
                    let timeoutId = null;
                    if (this.props.pendingSetupHref) {
                        timeoutId = setTimeout(this.loadResults, 5000);
                    }
                    this.setState({
                        loadingResults: false,
                        loadingSecurityResults: false,
                        results: null,
                        awaitResultsTimeoutId: timeoutId
                    }, this.props.onNoResultsFunction);
                }
            });
        }
    }

    async loadSecurityResults(securityResultsHref) {
        fetch(securityResultsHref, {
            headers: API_HEADERS
        })
        .then(result => {
            if (result.status === 401) {
                throw new Error('Unauthorized');
            } else if (result.status === 404) {
                throw new Error('Not Found');
            } else {
                return result.json();
            }
        })
        .then(json => {
            this.setState({
                loadingSecurityResults: false,
                securityResults: json
            });
        })
        .catch((error) => {
            if (error.message === 'Unauthorized') {
                this.props.notLoggedIn();
            } else if (error.message === 'Not Found') {
                this.setState({
                    loadingSecurityResults: false
                })
            }
        });
    }

    async loadResultComparison(resultComparisonHref) {
        fetch(resultComparisonHref, {
            headers: API_HEADERS
        })
        .then(result => {
            if (result.status === 401) {
                throw new Error('Unauthorized');
            } else if (result.status === 404) {
                throw new Error('Not Found');
            } else {
                return result.json();
            }
        })
        .then(json => {
            this.setState({
                resultComparison: json,
                componentsHref: json.resultingComponents
            });
        })
        .catch((error) => {
            if (error.message === 'Unauthorized') {
                this.props.notLoggedIn();
            }
        });
    }

    setResultsOverflowDropdownState(open) {
        this.setState({
            resultsOverflowOpen: open
        });
    }

    setModalRiskFactorsState(open) {
        this.setState({
            modalRiskFactorsOpen: open
        });
    }

    setModalRiskBadgeState(open) {
        this.setState({
            modalRiskBadgeOpen: open
        });
    }

    async triggerAutoSetup(setupHref) {
        const {t} = this.props;
        return fetch(setupHref, {
            method: 'POST',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(result => {
            if (result.status === 401) {
                throw new Error('Unauthorized');
            } else {
                return result.json();
            }
        })
        .then(json => {
            if (json.status === 'PULL_REQUEST_FILED') {
                this.props.reloadProject();
            } else if (json.status === 'PULL_REQUEST_PREVIOUSLY_FILED') {
                toast.success(t('results.auto-setup-pending-toast'), {
                        duration: 5000, 
                        id: 'setup-error', 
                        iconTheme: {
                            primary: '#bdc708',
                        }
                    });
                this.props.reloadProject();
            } else if (json.status === 'CONFIGURATION_ALREADY_PRESENT') {
                toast.success(t('results.auto-setup-already-configured-toast'), {
                        duration: 5000, 
                        id: 'setup-error', 
                        iconTheme: {
                            primary: '#bdc708',
                        }
                    });
                this.props.reloadProject();
            } else {
                toast.error(
                    <div>
                        {t('results.auto-setup-failure-message')}
                        <button className='manual-setup-button' onClick={() => {
                                this.setState({viewManualSetup: true});
                                toast.dismiss('setup-error');
                            }}>
                            {t('results.auto-setup-failure-link')}
                        </button>
                    </div>
                    , {duration: 5000, id: 'setup-error'});
            }
        })
        .catch((error) => {
            this.props.notLoggedIn();
        });
    }

    render() {
        const {t} = this.props;

        let resultView;
        let compareString;
        let compareIcon;
        if (this.state.results) {
            const resultRisk = this.state.securityResults ? this.state.securityResults.risk : 'UNKNOWN';
            const securityScore = this.state.securityResults ? this.state.securityResults.overallScore : '?';
            const badgeLink = this.state.results._meta.links
                .find((l) => l.rel === 'badge');
            const badgeHref = badgeLink ? badgeLink.href : null;
            let favicon;
            switch(resultRisk) {
            case 'HIGH': favicon = '/favicon-high.svg'; break;
            case 'MEDIUM': favicon = '/favicon-medium.svg'; break;
            case 'LOW': favicon = '/favicon-low.svg'; break;
            case 'NEGLIGIBLE': favicon = '/favicon-clean.svg'; break;
            default: favicon = '/favicon.svg'; break;
            }

            let branchScore = 0;
            if (this.state.resultComparison && this.state.securityResults) {
                branchScore = this.state.resultComparison.branchScore;
                if (!branchScore) {
                    compareString = 'results.comparison.merge-new';
                    compareIcon = 'circle-check';
                } else if (securityScore > branchScore) {
                    compareString = 'results.comparison.merge-good';
                    compareIcon = 'circle-check';
                } else if (securityScore < branchScore) {
                    compareString = 'results.comparison.merge-bad';
                    compareIcon = 'circle-xmark';
                } else {
                    compareString = 'results.comparison.merge-neutral';
                    compareIcon = 'circle-check';
                }
            }

            resultView =
                <div className={`results-header ${resultRisk}`}>
                    <Helmet>
                        <link rel='icon' type='image/svg+xml' href={favicon} />
                    </Helmet>
                    {this.state.securityResults ? 
                        <RiskFactorsModal
                            open={this.state.modalRiskFactorsOpen}
                            onClose={() => this.setModalRiskFactorsState(false)}
                            securityResults={this.state.securityResults} />
                    : null }
                    { badgeHref ?
                        <RiskBadgeModal
                            open={this.state.modalRiskBadgeOpen}
                            onClose={() => this.setModalRiskBadgeState(false)}
                            badgeHref={badgeHref} />
                    : null }
                    <div className='scoring'>
                        {this.state.resultComparison && branchScore ?
                            <div className='score-delta'>
                                {securityScore - branchScore > 0 ? <FontAwesomeIcon icon='plus' /> : null}
                                {`${securityScore - branchScore}`}
                            </div>
                        : `${securityScore}/10` }
                    </div>
                    <div className='result-container-info'>
                        <div className='result-branch-name'>{
                            (this.state.results.sourceBranchName && this.state.results.targetBranchName) ?
                            <span className='pr-targeting-name'>
                                {`${this.state.results.sourceBranchName} (${securityScore}/10)`}
                                <FontAwesomeIcon icon='arrow-right-long' />
                                {`${this.state.results.targetBranchName}${branchScore ? `(${branchScore}/10)` : ''}`}
                            </span>
                            : this.state.results.name
                        }</div>
                        <div className='analysis-status'>
                            {this.state.results.analysisRunning ?
                                <div><FontAwesomeIcon icon='gear' className='fa-spin' /> {t('results.status-analyzing')}</div> :
                                <div><FontAwesomeIcon icon='circle-check' /> {t('results.status-current')}</div>
                            }
                            {this.state.results.analysisLinkTimestamp ?
                                <div className='analysis-time'>{t('results.last-analysis', {analysisTime: dayjs(this.state.results.analysisLinkTimestamp).fromNow()})}</div> :
                                null
                            }
                        </div>
                    </div>
                    {this.state.securityResults || badgeHref ?
                        <div className='results-header-overflow-menu' onClick={() => {this.setResultsOverflowDropdownState(true)}}>
                            <FontAwesomeIcon icon='ellipsis' />
                            <Dropdown className='results-header-overflow' open={this.state.resultsOverflowOpen} handleOutsideClick={() => this.setResultsOverflowDropdownState(false)}>
                                { this.state.securityResults ? 
                                    <DropdownListItem icon='circle-nodes' label={t('results.risk-factors.title')} onClick={() => this.setModalRiskFactorsState(true)}/>
                                : null}
                                { badgeHref ?
                                    <DropdownListItem icon='shield-halved' label={t('results.risk-badge.title')} onClick={() => this.setModalRiskBadgeState(true)} />
                                : null }
                            </Dropdown>
                        </div>
                    : null }
                </div>
        }

        let noResultsAutoSetup =
            <div className='no-results-header'>
                <div className='setup-title'>{t('results.no-results')}</div>
                <div className='setup-text'>{t('results.no-results-text')}</div>
                <div className='setup-buttons'>
                    <StdButton colorClass='primary' extraClass='auto-setup-button'
                        onClick={async () => await this.triggerAutoSetup(this.props.setupHref)}>
                        <FontAwesomeIcon icon='wand-magic-sparkles' />
                        {t('results.auto-setup')}
                    </StdButton>
                    <span>{t('results.setup-or')}</span>
                    <button className='manual-setup-button' onClick={() => this.setState({viewManualSetup: true})}>
                        {t('results.manual-setup')}
                    </button>
                </div>
            </div>
        if (this.props.pendingSetupHref) {
            noResultsAutoSetup =
                <div className='no-results-header'>
                    <div className='setup-title'>{t('results.auto-setup-pending')}</div>
                    <div className='setup-text'>{t('results.auto-setup-pending-text')}</div>
                    <a className='std-button auto-setup-button primary standalone' href={this.props.pendingSetupHref} target='_blank' rel='noreferrer'>
                        {t('results.auto-setup-pending-button')}
                    </a>
                    <div className='setup-buttons'>
                        <span>{t('results.auto-setup-alternative')}</span>
                        <button className='manual-setup-button' onClick={() => this.setState({viewManualSetup: true})}>
                            {t('results.manual-setup')}
                        </button>
                    </div>
                </div>
        } else if (!this.props.setupHref) {
            noResultsAutoSetup =
                <div className='no-results-header'>
                    <div className='setup-title'>{t('results.no-results-not-ours')}</div>
                    <div className='setup-text'>{t('results.no-results-not-ours-text')}</div>
                </div>
        }

        // TODO when we have a link to the associated PR, compare that to pendingSetupHref instead of this workaround
        const isPr = this.props.resultsHref.includes('pull-requests');
        const pendingPrAssociation = isPr && this.props.pendingSetupHref &&
            this.props.pendingSetupHref.split('/').pop() === this.props.resultsHref.split('/').pop();

        return (
            <div id='analysis-view'>
                {this.state.loadingResults || this.state.loadingSecurityResults ?
                    <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                    resultView ?
                    <React.Fragment>
                        <div id='analysis-results'>
                            { pendingPrAssociation ?
                                <a href={this.props.pendingSetupHref} rel='noreferrer' target='_blank' className='pending-pr-association-banner'>
                                    <FontAwesomeIcon icon='code-pull-request' />
                                    <div>{t('results.auto-setup-pr', {sourceProvider: 'GitHub'})}</div>
                                </a>
                            : null}
                            { resultView }
                            {this.state.resultComparison && this.state.securityResults ?
                                <div className='comparison-merge-status'>
                                    <FontAwesomeIcon icon={compareIcon} />
                                    <Trans i18nKey={compareString}><br/></Trans>
                                </div>
                            : null}
                            { this.state.resultComparison ?
                                <ButtonBar
                                    className='relative-state-buttons'
                                    onButtonBarClick={(v) => this.setState({componentsHref: v})}
                                    options={[
                                    {label: t('results.comparison.components-all'), value: this.state.resultComparison.resultingComponents},
                                    {label: t('results.comparison.components-added'), value: this.state.resultComparison.addedComponents, disabled: this.state.resultComparison.addedComponentsCount === 0},
                                    {label: t('results.comparison.components-removed'), value: this.state.resultComparison.removedComponents, disabled: this.state.resultComparison.removedComponentsCount === 0},
                                ]}/>
                            : null }
                            {this.state.componentsHref ?
                                <ComponentList componentsHref={this.state.componentsHref}
                                        onComponentClick={this.onComponentClick}
                                        selectedComponentHref={this.state.selectedComponentHref}
                                        containerRef={this.props.containerRef}
                                        paginatorClickFunction={this.closeInspector} /> :
                                null
                            }
                        </div>
                        <ComponentInspector componentHref={this.state.selectedComponentHref} closeInspectorClick={this.closeInspector} yPos={this.state.selectedComponentYPos} maxYPos={this.state.containerMaxHeight}/>
                    </React.Fragment>
                :
                <div id='no-results'>
                    <Helmet>
                        <title>{getTitlebarTitle(t('results.page-title-setup', {projectName: this.props.projectName}), t)}</title>
                        <link rel='icon' type='image/svg+xml' href='/favicon.svg' />
                    </Helmet>
                    {noResultsAutoSetup}
                    {this.state.viewManualSetup ?
                        <SetupConversation feedback={this.props.feedback} urlDirectory={this.props.urlDirectory} />
                    : null}
                </div> }
            </div>
        );
    }

}

export default withCookies(withTranslation()(AnalysisResults));
