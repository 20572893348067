import React from 'react';
import parse from 'html-react-parser';
import dayjs from 'dayjs';

import './WhatsNew.css'

class ChangelogItem extends React.Component {

    render() {
        return (
            <div className='changelog'>
                <div className='header'>
                    <div className='version-number'>{this.props.version}</div>
                    <div className='changelog-date'>{dayjs(this.props.timestamp).format('MMMM DD, YYYY')}</div>
                </div>
                <div className='changelog-content'>
                    {parse(this.props.content)}
                </div>
            </div>
        );
    }

}

export default ChangelogItem;
