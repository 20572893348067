import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Dropdown.css'

class DropdownListItem extends React.Component {

    render() {
        return (
            <div className='dropdown-list-item' onClick={this.props.onClick}>
                {this.props.icon ?
                    <div className='dropdown-icon'>
                        <FontAwesomeIcon icon={this.props.icon}/>
                    </div>
                : null }
                <span className='dropdown-label'>{this.props.label}</span>
            </div>
        );
    }

}

export default DropdownListItem;
