import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Pager.css';

class Paginator extends React.Component {

    render() {
        const disableFirst = this.props.position.firstPage === undefined;
        const disablePrev = this.props.position.previousPage === undefined;
        const disableNext = this.props.position.nextPage === undefined;
        const disableLast = this.props.position.lastPage === undefined;
        return (
            <div className={`paginator ${this.props.minimal ? 'minimal' : ''}`}>
                {!this.props.minimal ?
                    <button className={`paginator-button ${disableFirst ? 'disabled' : ''}`}
                        onClick={() => this.props.onPaginatorClick(this.props.position.firstPage)}>
                        <FontAwesomeIcon icon='backward-step' />
                    </button>
                    : null
                }
                <button className={`paginator-button larger ${disablePrev ? 'disabled' : ''}`}
                    onClick={() => this.props.onPaginatorClick(this.props.position.previousPage)}>
                    <FontAwesomeIcon icon='caret-left' />
                </button>
                {!this.props.minimal ?
                    <div className='paginator-text'>Page {this.props.position.index + 1} of {this.props.position.lastIndex + 1}</div> :
                    <div className='paginator-text'>{this.props.position.index + 1}/{this.props.position.lastIndex + 1}</div>
                }
                <button className={`paginator-button larger ${disableNext ? 'disabled' : ''}`}
                    onClick={() => this.props.onPaginatorClick(this.props.position.nextPage)}>
                    <FontAwesomeIcon icon='caret-right' />
                </button>
                {!this.props.minimal ?
                    <button className={`paginator-button ${disableLast ? 'disabled' : ''}`}
                        onClick={() => this.props.onPaginatorClick(this.props.position.lastPage)}>
                        <FontAwesomeIcon icon='forward-step' />
                    </button>
                    : null
                }
            </div>
        );
    }

}

export default Paginator;
