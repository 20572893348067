import React from 'react';
import { withCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import Modal from '../../../Common/Modal/Modal';
import ToggleSwitch from '../../../Common/ToggleSwitch/ToggleSwitch';
import StdButton from '../../../Common/StdButton/StdButton';
import Pager from '../../../Common/Pager/Pager';
import toast from 'react-hot-toast';

import LinkedOwnerListItem from './LinkedOwnerListItem';

import { API_HEADERS, apiHeadersContentXsrf } from '../../../../Constants';

import './CustomerAccounts.css'

class CustomerAccountUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            updatedName: '',
            updatedPrivateEnabled: '',
            ownerToLink: '',
            ownersHref: '',
            potentialOwnerHref: ''
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.updateCustomerAccount = this.updateCustomerAccount.bind(this);
        this.linkOwner = this.linkOwner.bind(this);
        this.unlinkOwner = this.unlinkOwner.bind(this);
        this.renderLinkedOwners = this.renderLinkedOwners.bind(this);
        
        this.nameInput = React.createRef();
        this.toLinkInput = React.createRef();
        this.pager = React.createRef();
    }
    
    async componentDidUpdate(prevProps) {
        if (this.props.accountHref !== prevProps.accountHref) {
            this.setState({
                loading: true
            });
            if (this.props.accountHref) {
                fetch(this.props.accountHref, {
                    headers: API_HEADERS
                })
                .then(result => result.json())
                .then(json => {
                    this.setState({
                        loading: false,
                        updatedName: json.name,
                        updatedPrivateEnabled: json.allowPrivateRepositories,
                        ownerToLink: '',
                        ownersHref: json._meta.links.find(l => l.rel === 'owners').href,
                        potentialOwnerHref: json._meta.links.find(l => l.rel === 'potential-owner').href
                    });
                });
            }
        }
    }
    
    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    handleKeyPress(event) {
        if (event.key === 'Enter') {
            if (document.activeElement === this.nameInput.current) {
                this.updateCustomerAccount();
            } else if (document.activeElement === this.toLinkInput.current) {
                this.linkOwner();
            }
        }
    }
    
    async updateCustomerAccount() {        
        const customerRequest = {
            name: this.state.updatedName,
            allowPrivateRepositories: this.state.updatedPrivateEnabled
        }
        
        this.props.updateCustomerAccount(this.props.accountHref, customerRequest);
    }
    
    async linkOwner() {
        const {t} = this.props;
        
        fetch(`${this.state.potentialOwnerHref}?provider=github&name=${this.state.ownerToLink}`, {
            headers: API_HEADERS
        })
        .then(response => {
            if (!response.ok) {
                throw response.statusCode;
            }
            return response;
        })
        .then(result => result.json())
        .then(json => {
            fetch(this.state.ownersHref, {
                method: 'POST',
                body: JSON.stringify(json),
                headers: apiHeadersContentXsrf(this.props.cookies)
            })
            .then(() => {
                toast.success(t('admin.customer-accounts.update-modal.owner-add-success', {ownerName: this.state.ownerToLink}));
                // Reset the pager to first page
                this.pager.current.toFirstPage();
                // Reset form
                this.setState({
                    ownerToLink: ''
                });
            });
        })
        .catch(error => {
            toast.error(t('admin.customer-accounts.update-modal.owner-add-fail', {ownerName: this.state.ownerToLink}));
        });
    }
    
    async unlinkOwner(ownerHref) {
        const {t} = this.props;
        
        fetch(ownerHref, {
            method: 'DELETE',
            headers: apiHeadersContentXsrf(this.props.cookies)
        })
        .then(() => {
            toast.success(t('admin.customer-accounts.update-modal.unlink-success'));
            // Reset the pager to first page
            this.pager.current.toFirstPage();
        });
    }
    
    renderLinkedOwners(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<LinkedOwnerListItem
                key={i._meta.href}
                name={i.displayName}
                deleteOwner={() => this.unlinkOwner(i._meta.href)}
                canUpdate={this.props.canWrite} />)
            }
        );
        return listItems;
    }

    render() {
        const {t} = this.props;

        return (
            <Modal className='update-customer-modal' 
                open={this.props.open}
                onClose={() => this.props.setUpdateModalState(false, null)}
                title={t('admin.customer-accounts.update-modal.title')}
                content={
                    !this.state.loading ? 
                        <React.Fragment>
                            <div className='setting-block customer-meta'>
                                <div className='setting-block-header'>
                                    <div className='setting-name'>{t('admin.customer-accounts.update-modal.meta-title')}</div>
                                    <div className='setting-description'>{t('admin.customer-accounts.update-modal.meta-text')}</div>
                                </div>
                                <div className='sub-setting customer-name'>
                                    <div className='sub-setting-name'>{t('admin.customer-accounts.form-new-name')}</div>
                                    <input className='setting-input-text customer-name'
                                                ref={this.nameInput}
                                                name='updatedName'
                                                value={this.state.updatedName}
                                                onChange={this.handleInputChange}
                                                onKeyPress={this.handleKeyPress}
                                                disabled={!this.props.canWrite} />
                                </div>
                                <div className='sub-setting customer-private'>
                                    <div className='sub-setting-name'>{t('admin.customer-accounts.form-new-private')}</div>
                                    <ToggleSwitch
                                        toggleOnFunction={() => this.setState({updatedPrivateEnabled: true})}
                                        toggleOffFunction={() => this.setState({updatedPrivateEnabled: false})}
                                        on={this.state.updatedPrivateEnabled}
                                        disabled={!this.props.canWrite} />
                                </div>
                                {this.props.canWrite ?
                                    <StdButton colorClass='positive' onClick={() => this.updateCustomerAccount()}>
                                        {t('admin.customer-accounts.update-modal.button-update')}
                                    </StdButton>
                                : null}
                            </div>
                            <div className='setting-block'>
                                <div className='setting-block-header'>
                                    <div className='setting-name'>{t('admin.customer-accounts.owners.list-title')}</div>
                                    <div className='setting-description'>{t('admin.customer-accounts.owners.list-description')}</div>
                                </div>
                                {this.props.canWrite ?
                                    <div className='sub-setting owner-link'>
                                        <input className='setting-input-text new-owner-name'
                                                    name='ownerToLink'
                                                    ref={this.toLinkInput}
                                                    value={this.state.ownerToLink}
                                                    onChange={this.handleInputChange}
                                                    onKeyPress={this.handleKeyPress} />
                                        <StdButton colorClass='positive' onClick={() => this.linkOwner()}>
                                            <FontAwesomeIcon icon='link' />
                                            {t('admin.customer-accounts.update-modal.add-owner')}
                                        </StdButton>
                                    </div>
                                : null}
                                <Pager className='owner-list'
                                    ref={this.pager}
                                    initialPage={`${this.state.ownersHref}?per_page=5`}
                                    renderItems={this.renderLinkedOwners} />
                            </div>
                        </React.Fragment>
                    : <FontAwesomeIcon icon='spinner' className='fa-spin'/>
                }
                buttons={[
                    <StdButton key='close-button' extraClassName='button' onClick={() => this.props.setUpdateModalState(false, null)}>
                        {t('general.close')}
                    </StdButton>,
                ]} />
        );
    }

}

export default withCookies(withTranslation()(CustomerAccountUpdateModal));
