import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import lang_en from './i18n/en.json';

const resources = {
    en: {
        translation: lang_en
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
                   // Hard-coded to en for now, but when we add more languages we will need to include a browser language detector and remove this

        interpolation: {
            escapeValue: false, // Not needed for React as it escapes by default
            skipOnVariables: false // used to allow variables in string interpolation
        }
    });

export default i18n;
