import React from 'react';
import ReactMarkdown from 'react-markdown';

import ducky from '../../../images/ducky.svg';

import './ChatInterface.css';

class ChatBubble extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alpha: 0,
            size: 0.9
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({alpha: 1, size: 1}), 400);
    }

    render() {
        return (
            <div className='chat-message app' style={{opacity: this.state.alpha, transform: `scale(${this.state.size})`}}>
                <div className='chat-avatar' style={{backgroundImage: `url(${ducky})`}}></div>
                <div className='bubble'>
                    <ReactMarkdown>{this.props.message}</ReactMarkdown>
                </div>
            </div>
        )
    }

}

export default ChatBubble;
