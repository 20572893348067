import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import Modal from '../../Common/Modal/Modal';
import ButtonBar from '../../Common/ButtonBar/ButtonBar';
import StdButton from '../../Common/StdButton/StdButton';

import '../Results.css';

class RiskBadgeModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selection: 0
        }

        this.onButtonBarClick = this.onButtonBarClick.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.buttonbarRef = React.createRef();
    }

    closeModal() {
        this.setState({
            selection: 0
        });

        this.buttonbarRef.current.setState({
            selectedValue: 0
        });

        this.props.onClose()
    }

    onButtonBarClick(newValue) {
        this.setState({
            selection: newValue,
        })
    }

    render() {
        const {t} = this.props;

        const snippets = [
            'results.risk-badge.snippet-md',
            'results.risk-badge.snippet-html',
            'results.risk-badge.snippet-rst'
        ]
        const snippet = t(snippets[this.state.selection], {badgeHref: this.props.badgeHref, resultsUrl: window.location})

        const content =
        <React.Fragment>
            <div className='modal-text'>{t('results.risk-badge.description')}</div>
                <img src={this.props.badgeHref} alt={t('results.risk-badge.badge-alt')}/>
            <ButtonBar className='risk-badge-type' ref={this.buttonbarRef} options={[
                {label: t('results.risk-badge.bar-md'), value: 0},
                {label: t('results.risk-badge.bar-html'), value: 1},
                {label: t('results.risk-badge.bar-rst'), value: 2}
            ]} onButtonBarClick={this.onButtonBarClick} />
            <pre>{snippet}</pre>
            <StdButton colorClass='primary' extraClass='copy-btn' onClick={() => {
                navigator.clipboard.writeText(snippet);
                toast.success(t('general.copied'));
            }}>
                <FontAwesomeIcon icon='clipboard' /> {t('general.copy')}
            </StdButton>
        </React.Fragment>;

        return(
            <Modal className='modal-risk-badge'
                open={this.props.open}
                onClose={this.closeModal}
                title={t('results.risk-badge.title')}
                content={content}
                buttons={[
                    <StdButton key='close-button' extraClassName='button' onClick={this.closeModal}>{t('general.close')}</StdButton>
                ]} />
        );
    }

}

export default withTranslation()(RiskBadgeModal);
