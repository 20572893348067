import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ChangelogItem from './ChangelogItem';
import Pager from '../Common/Pager/Pager';
import { API_HEADERS } from '../../Constants';
import { getTitlebarTitle } from '../../Constants';

import './WhatsNew.css'

class WhatsNew extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            latest: null,
            showOlder: false
        };

        this.renderChangelogItems = this.renderChangelogItems.bind(this);
    }

    async componentDidMount() {
        fetch(`${this.props.baseHref}?page=0&per_page=1`, {
            headers: API_HEADERS
        })
        .then(result => result.json())
        .then(json => {
            if (json.position.totalElements > 0) {
                this.setState({
                    latest: json.items[0],
                    loading: false
                });
            }
        });
    }

    renderChangelogItems(items) {
        const listItems = [];
        items.forEach(i => {
            listItems.push(<ChangelogItem
                key={i._meta.href}
                version={i.version}
                timestamp={i.dateReleased}
                content={i.changes} />)
            }
        );
        return listItems;
    }

    render() {
        const {t} = this.props;

        return (
            <div id='whats-new'>
                <Helmet>
                    <title>{getTitlebarTitle(t('changelogs.page-title'), t)}</title>
                </Helmet>
                <div className='page-name-and-description'>
                    <div className='page-name'>
                        <span>{t('changelogs.whats-new')}</span>
                    </div>
                    <div className='page-description'>
                        {t('changelogs.description')}
                    </div>
                </div>
                {this.state.loading ?
                    <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                    <React.Fragment>
                        <ChangelogItem version={this.state.latest.version} timestamp={this.state.latest.dateReleased} content={this.state.latest.changes} />
                        <div className='older-changelogs'>
                            <div className='older-toggle' onClick={() => {this.setState({showOlder: !this.state.showOlder})}}>
                                <FontAwesomeIcon icon={this.state.showOlder ? 'caret-down' : 'caret-right'} />
                                {t('changelogs.older')}
                            </div>
                            {this.state.showOlder ?
                                <Pager className='older-changelog-pager'
                                    skip={1}
                                    initialPage={`${this.props.baseHref}?page=0&per_page=5`}
                                    renderItems={this.renderChangelogItems} />
                            : null}
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }

}

export default withTranslation()(WhatsNew);
