import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { API_HEADERS } from '../../../Constants';
import EmptyListHint from '../EmptyListHint/EmptyListHint';
import Paginator from './Paginator';

import './Pager.css';

class Pager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPageHref: this.props.initialPage,
            loading: true,
            items: null,
            position: null
        }

        this.loadPage = this.loadPage.bind(this);
        this.onPaginatorClick = this.onPaginatorClick.bind(this);
        this.toFirstPage = this.toFirstPage.bind(this);
    }

    async componentDidMount() {
        this.loadPage();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.initialPage !== this.props.initialPage) {
            this.setState({
                currentPageHref: this.props.initialPage,
                loading: true
            }, () => this.loadPage())
        }
    }

    async loadPage() {
        // Fetch a page of date
        fetch(this.state.currentPageHref, {
            headers: API_HEADERS,
            redirect: 'error'
        })
        .then(result => {
            if (result.status !== 200) {
                throw new Error(result.status);
            } else {
                return result.json();
            }
        })
        .then(json => {
            this.setState({
                items: json.items,
                position: json.position,
                loading: false
            });
        })
        .catch(error => {
            if (this.props.handleError) {
                this.props.handleError();
            }
        });
    }

    onPaginatorClick(pageUrl) {
        if (this.props.paginatorClickFunction) {
            this.props.paginatorClickFunction();
        }
        this.setState({
            currentPageHref: pageUrl,
            loading: true
        }, () => this.loadPage());
    }

    toFirstPage() {
        if (this.state.position.firstPage) {
            this.setState({
                currentPageHref: this.state.position.firstPage
            }, () => {
                this.loadPage();
            });
        } else {
            this.loadPage();
        }
    }

    reloadCurrentPage() {
        this.loadPage();
    }

    render() {
        let itemsToRender = [];
        if (Array.isArray(this.state.items)) {
            itemsToRender = this.state.items;
            if (this.props.skip && this.state.position.index === 0) {
                itemsToRender = itemsToRender.slice(this.props.skip);
            }
        }
        return (
            <div className={`pager ${this.props.className || ''}`}>
                <div className={`pager-items ${this.state.loading ? 'loading' : ''}`}>
                {
                    this.state.loading ?
                        <FontAwesomeIcon icon='spinner' className='fa-spin spinner'/> :
                        (Array.isArray(itemsToRender) && itemsToRender.length) ?
                            this.props.renderItems(itemsToRender)
                        : <EmptyListHint />
                }
                </div>
                {this.state.position && this.state.position.lastIndex !== 0 ?
                    <Paginator position={this.state.position} onPaginatorClick={this.onPaginatorClick} minimal={this.props.minimalPaginator} />
                    : null
                }
            </div>
        )
    }

}

export default Pager;
