import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './StdButton.css';

class StdButton extends React.Component {

    constructor(props) {
        super (props);
        this.state = {
            working: false,
            done: false
        }

        this.doOnClick = this.doOnClick.bind(this);
    }

    // To render the spinner during progress, props.onClick must be an async function that returns a promise
    async doOnClick() {
        this.setState({
            working: true
        }, async () => {
            const promise = this.props.onClick();
            if (promise) {
                promise.then(() => {
                    this.setState({
                        working: false
                    })
                });
            } else {
                this.setState({
                    working: false
                })
            }
        });
    }

    render() {
        const workingClass = this.state.working ? 'working' : '';
        return(
            <button className={`std-button ${this.props.colorClass} ${this.props.extraClass} ${workingClass}`}
                    disabled={this.props.disabled || this.state.working}
                    onClick={this.doOnClick}>
                {this.state.working ?
                    <FontAwesomeIcon icon='spinner' className='working-spinner fa-spin' /> : null
                }
                <div className='button-children'>
                    {this.props.children}
                </div>
            </button>
        )
    }

}

export default StdButton;
